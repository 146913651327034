import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { getBatchFormFromLocalStorage, resetBatchFormFromLocalStorage } from '../../helpers/formToLocalStorage';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { t } from '../../../../../../service/localization/i18n';

const UnsavedChangesForBatchDialog = () => {
  const { localState, setLocalState, reset, setValue, setInitialStateForNewForm } = useFormContext();

  const onHide = () => {
    // Set initial state
    setInitialStateForNewForm();
    // Reset batch form from local storage
    resetBatchFormFromLocalStorage();
    // Close dialog
    setLocalState((prevState) => ({
      ...prevState,
      unsavedChangesForBatchDialog: false
    }));
  };

  const onAccept = () => {
    const batchFormState = getBatchFormFromLocalStorage();
    reset(batchFormState.formState);
    setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true }); // Hack to set form to dirty
    setLocalState({
      ...batchFormState.localState,
      unsavedChangesForBatchDialog: false
    });
  };

  const footer = () => {
    return (
      <>
        <Button id={elementIDs.dialogClose} outlined label={t('No')} onClick={onHide} autoFocus />
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onAccept} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.unsavedChangesForBatchDialog}
      header="Warning!"
      visible={localState.unsavedChangesForBatchDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={footer()}
      onHide={onHide}
    >
      <div className="text-lg">You have unsaved changes from a previous batch. Do you want to restore them?</div>
    </Dialog>
  );
};

export default UnsavedChangesForBatchDialog;
