import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { checkIfStringIsDate } from '../../../utils/checkIfStringIsDate';
import { t } from '../../../../service/localization/i18n';
import classes from './EligibilityMessage.module.scss';
import moment from 'moment';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { eligibilityMessageColor } from '../commonElements/helpers/eligibilityMessageColor';
import { Divider } from 'primereact/divider';

const EligibilityMessage = ({
  eligibility,
  iconStyle,
  iconSize = 'large',
  iconClassName,
  showIcon = false,
  showMessageColor = true,
  showInRowExpantion = false,
  showFullCheckOnDate = true
}) => {
  const [shownoteDialog, setShownoteDialog] = useState(false);

  const dosClass = moment(eligibility.CHECK_ON_DATE).diff(new Date(), 'days') === 0 ? classes.mspEligibilityStatusIcon_success : 'p-error';

  const thumbIconClasses = cx(
    classes.mspEligibilityStatusIcon,
    isEmpty(eligibility)
      ? 'pi pi-exclamation-circle'
      : eligibility.ELIG_ON_DOS === 'YES'
      ? 'pi pi-thumbs-up'
      : eligibility.ELIG_ON_DOS === 'NO'
      ? 'pi pi-thumbs-down'
      : '',
    isEmpty(eligibility)
      ? classes.mspEligibilityStatusIcon_disabled
      : eligibility.ELIG_ON_DOS === 'YES'
      ? classes.mspEligibilityStatusIcon_success
      : eligibility.ELIG_ON_DOS === 'NO'
      ? 'p-error'
      : ''
  );

  const eyeExam = (
    <>
      {checkIfStringIsDate(eligibility.DATE_OF_LAST_EYE_EXAM) ? (
        <div className="mb-1">{`${t('Date_of_last_eye_exam')}: ${eligibility.DATE_OF_LAST_EYE_EXAM}`}</div>
      ) : (
        <div className="mb-1">{eligibility.DATE_OF_LAST_EYE_EXAM}</div>
      )}
    </>
  );

  const footer = (
    <div>
      <Button label={t('Close')} onClick={() => setShownoteDialog(false)} />
    </div>
  );

  return (
    <>
      {/* {eligibility?.ErrorMessage && (
        <div className="p-error" >
          {eligibility?.ErrorMessage}
        </div>
      )} */}

      {!isEmpty(eligibility) && !eligibility?.ErrorMessage && (
        <div className="flex">
          {eligibility.error && <div>{eligibility.error}</div>}

          {showIcon && (
            <div className={cx('py-2', classes.thumbIconWrap)}>
              <i
                className={cx(thumbIconClasses, iconClassName ? iconClassName : '', iconSize === 'large' ? classes.largeIcon : '')}
                style={iconStyle ? iconStyle : {}}
              />
            </div>
          )}

          {!eligibility.error && (
            <div
              className={cx(
                'my-0 p-0',
                showInRowExpantion ? '' : 'p-message p-component p-message-enter-done',
                showMessageColor ? eligibilityMessageColor(eligibility.ELIG_ON_DOS, false) : '',
                showIcon ? 'col-11' : 'col-12'
              )}
            >
              <div>
                {/* First section */}
                <div>
                  {eligibility.ELIG_ON_DOS === 'YES' && (
                    <div className="mb-1">
                      {`Active MSP coverage on `}
                      <span className={!showMessageColor ? dosClass : ''}>
                        {showFullCheckOnDate ? eligibility.CHECK_ON_DATE : eligibility.CHECK_ON_DATE_TEXT}
                      </span>
                    </div>
                  )}

                  {eligibility.ELIG_ON_DOS === 'NO' && (
                    <div className="mb-1">
                      {`Inactive MSP coverage on `}
                      <span className={!showMessageColor ? dosClass : ''}>
                        {showFullCheckOnDate ? eligibility.CHECK_ON_DATE : eligibility.CHECK_ON_DATE_TEXT}
                      </span>
                    </div>
                  )}

                  {eligibility.ELIG_ON_DOS === '' && (
                    <div className="mb-1">
                      {`Undetermined MSP coverage on `}
                      <span className={!showMessageColor ? dosClass : ''}>
                        {showFullCheckOnDate ? eligibility.CHECK_ON_DATE : eligibility.CHECK_ON_DATE_TEXT}
                      </span>
                    </div>
                  )}

                  {eligibility.COVERAGE_END_DT && (
                    <div className="mb-1">{String.format(t('Coverage_ened_on'), eligibility.COVERAGE_END_DT, eligibility.COVERAGE_END_REASON)}</div>
                  )}

                  {eligibility.DATE_OF_LAST_EYE_EXAM && eyeExam}

                  <Divider className="my-3" />
                </div>

                {/* Second section */}
                <div>
                  <div className="font-bold">{`${t('Premium_assistance_status')}:`}</div>

                  {eligibility.SERVICES_PAID ? (
                    <div className={eligibility.SERVICES_PAID === 10 ? 'p-error' : ''}>
                      {String.format(t('MSP_visits_paid_to_date'), eligibility.SERVICES_PAID)}
                    </div>
                  ) : (
                    <div className={eligibility.PATIENT_VISIT_CHARGE?.toUpperCase() === 'THIS IS NOT AN INSURED BENEFIT' ? 'p-error' : ''}>
                      {eligibility.PATIENT_VISIT_CHARGE}
                    </div>
                  )}

                  {/* Visit(s) in process: - Hide until Vladimir returns VisitsInProcess: */}
                  {/* <div>
                              Visit(s) in process:
                            </div> */}

                  {/* Estimated balance until December 31: - Hide until Vladimir returns the variable or provides you with algorithm to calculate the value. */}
                  {/* <div>
                              Estimated balance until December 31:
                            </div> */}
                </div>

                {/* Third section */}
                {eligibility.PatientRestriction && (
                  <div>
                    <Divider className="my-3" />

                    {eligibility.PatientRestriction?.includes('NO RESTRICTION') ? t('No_patient_restrictions') : eligibility.PatientRestriction}
                  </div>
                )}

                {/* Note button */}
                {/* hide until Vladimir returns this value */}
                {/* <div className="flex justify-content-start mt-2">
                            <Button
                              label={t("Note")}
                              className={cx("p-button-link px-0", classes.noteButton)}
                              onClick={() => setShownoteDialog(true)}
                            />
                          </div> */}

                <Dialog
                  header="Header"
                  visible={shownoteDialog}
                  onHide={() => setShownoteDialog(false)}
                  breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
                  style={{ width: '30vw' }}
                  footer={footer}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </Dialog>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EligibilityMessage;
