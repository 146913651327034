import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { logout } from '../../../modules/auth/actions/auth.actions.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { stringFormat } from '../../../modules/utils/stringFormat';
import { t } from '../../../service/localization/i18n';

const SessionIdle = () => {
  const dispatch = useDispatch();
  const [remaining, setRemaining] = useState(0);
  const [open, setOpen] = useState(false);
  const { isMobile } = useSelector((state) => state.core.window);
  const isDevelopment = window.location.hostname === 'localhost';

  // Calculate timeout based on device type
  const timeout = isDevelopment ? 10 * 60 * 60 * 1000 : isMobile ? 3 * 60 * 60 * 1000 : 15 * 60 * 1000; // 3 hours for mobile or 15 minute for desktop

  // Adjust promptBeforeIdle to be less than timeout
  const promptBeforeIdle = 59 * 1000; // 60 seconds

  const timeoutMinutes = timeout / 60 / 1000;
  const promptBeforeIdleMinutes = Math.round(promptBeforeIdle / 60 / 1000);

  const onIdle = () => {
    setOpen(false);
    dispatch(logout());
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    key: timeout
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <Dialog
        id={elementIDs.sessionIdleDialog}
        header={t('Inactive_session')}
        visible={open}
        breakpoints={{ '768px': '95vw' }}
        style={{ width: '25vw' }}
        modal
        baseZIndex={1000000}
        onHide={handleStillHere}
      >
        <h6>{stringFormat(t('Logging_out_in_x_seconds'), remaining)}</h6>
        <p>{stringFormat(t('Inactive_session_text'), timeoutMinutes, promptBeforeIdleMinutes)}</p>
        <div className="flex justify-content-center gap-2">
          <Button className="w-full" label={t('Stay_connected')} onClick={handleStillHere} />
          <Button className="w-full" label={t('Logout_now')} onClick={() => dispatch(logout())} outlined />
        </div>
      </Dialog>
    </>
  );
};

export default SessionIdle;

// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useIdleTimer } from 'react-idle-timer';

// import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';

// import { logout } from '../../../modules/auth/actions/auth.actions.creators';
// import { elementIDs } from '../../../modules/config/elementIDsConfig';
// import { stringFormat } from '../../../modules/utils/stringFormat';
// import { t } from '../../../service/localization/i18n';

// const SessionIdle = () => {
//   const dispatch = useDispatch();
//   const [remaining, setRemaining] = useState(0);
//   const [open, setOpen] = useState(false);
//   const { isMobile } = useSelector((state) => state.core.window);

//   // Detect if running on localhost or in development mode
//   const isDevelopment = window.location.hostname === 'localhost';
//   const devTimeout = 10 * 60 * 60 * 1000; // 10 hr
//   const mobileTimeout = 2 * 60 * 60 * 1000; // 2 hr
//   const desktopTimeout = 15 * 60 * 1000; // 15 min

//   // Calculate timeout based on device type, or disable if in development
//   const timeout = isDevelopment ? devTimeout : isMobile ? mobileTimeout : desktopTimeout;

//   // Adjust promptBeforeIdle to be less than timeout, only if timeout is finite
//   const promptBeforeIdle = 59 * 1000; // 59 seconds

//   const timeoutMinutes = timeout / 60 / 1000;
//   const promptBeforeIdleMinutes = Math.round(promptBeforeIdle / 60 / 1000);

//   const onIdle = () => {
//     if (!isDevelopment) {
//       setOpen(false);
//       dispatch(logout());
//     }
//   };

//   const onActive = () => {
//     if (!isDevelopment) {
//       setOpen(false);
//     }
//   };

//   const onPrompt = () => {
//     if (!isDevelopment) {
//       setOpen(true);
//     }
//   };

//   const idleTimer = useIdleTimer({
//     onIdle,
//     onActive,
//     onPrompt,
//     timeout,
//     promptBeforeIdle,
//     throttle: 500,
//     key: timeout
//   });

//   const { getRemainingTime, activate } = idleTimer;

//   useEffect(() => {
//     if (!isDevelopment) {
//       const interval = setInterval(() => {
//         setRemaining(Math.ceil(getRemainingTime() / 1000));
//       }, 500);

//       return () => {
//         clearInterval(interval);
//       };
//     }
//   }, [getRemainingTime, isDevelopment]);

//   const handleStillHere = () => {
//     if (!isDevelopment) {
//       activate();
//     }
//   };

//   return (
//     <>
//       {!isDevelopment && (
//         <Dialog
//           id={elementIDs.sessionIdleDialog}
//           header={t('Inactive_session')}
//           visible={open}
//           breakpoints={{ '768px': '95vw' }}
//           style={{ width: '25vw' }}
//           modal
//           baseZIndex={1000000}
//           onHide={handleStillHere}
//         >
//           <h6>{stringFormat(t('Logging_out_in_x_seconds'), remaining)}</h6>
//           <p>{stringFormat(t('Inactive_session_text'), timeoutMinutes, promptBeforeIdleMinutes)}</p>
//           <div className="flex justify-content-center gap-2">
//             <Button className="w-full" label={t('Stay_connected')} onClick={handleStillHere} />
//             <Button className="w-full" label={t('Logout_now')} onClick={() => dispatch(logout())} outlined />
//           </div>
//         </Dialog>
//       )}
//     </>
//   );
// };

// export default SessionIdle;
