import React from 'react';

const CustomButton = ({
  label,          
  name,           
  isSelected = false, 
  onClick,        
  style = {},      
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: isSelected ? '2px solid #007ad9' : '1px solid #ccc',
        borderRadius: '8px',
        padding: '15px',
        cursor: 'pointer',
        flex: 1,
        textAlign: 'center',
        backgroundColor: isSelected ? '#e0f7ff' : '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease-in-out',
        ...style, 
      }}
      onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)')}
      onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)')}
      onFocus={(e) => (e.currentTarget.style.outline = '2px solid #007ad9')}
      onBlur={(e) => (e.currentTarget.style.outline = 'none')}
    >
      {/* Render the descriptive label */}
      <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>{label}</p>
      {/* Render the actual name */}
      <p style={{ margin: 0 }}>{name}</p>
    </div>
  );
};

export default CustomButton;
