import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import RowClaimHistory from '../RowClaimHistory';
import PaymenstDialog from '../PaymenstDialog';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';
import CommonConfirmDialog from '../../../common/components/CommonConfirmDialog';
import DebitRequestDialog from '../../../../components/Dialogs/DebitRequestDialog/DebitRequestDialog';
import BatchRecordsWarnDialogForOnePatient from '../../../common/components/Dialogs/BatchRecordsWarnDialogForOnePatient';
import NotesCounter from './NotesCounter';
import AddClaimNoteDialog from '../AddClaimNoteDialog';
import PatientBillingHistoryDialog from '../../../../components/Widgets/PatientBillingHistory/PatientBillingHistoryDialog';
import SimilarClaimsListDialog from '../../../../components/Widgets/SimilarClaimsList/SimilarClaimsListDialog';

import { getClaimList, deleteClaim, getClaimInfo } from '../../actions/claims.action.creators';
import { rowActionsMenuItems, onArchiveClick, onArchiveEClaim } from '../../helpers/rowActions';
import { confirmDialogNames } from '../../helpers/confirmDialogNames';
import { isEmpty } from 'lodash';
import { elementIDs } from '../../../config/elementIDsConfig';
import { patientFullName } from '../../../utils/patientFullName';
import { setRequestHistory } from '../../helpers/setRequestHistory';

import cx from 'classnames';
import { t } from '../../../../service/localization/i18n';

const ClaimMenu = ({ claimRecord, setClaimRecord, closeMenuIcon = true, notesCounter = true }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menu = useRef(null);
  const { isMobile, isMobileOnly, isLandscape } = useSelector((state) => state.core.window);

  const { PatientGuid } = useSelector((state) => state.patients.patient_details);

  const { isFetchingClaims, filterByUserForInvoicesViewPanel, pageSize, page, filters, sortby } = useSelector((state) => state.claims);
  const { readonly } = useSelector((state) => state.core);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupHeader, setHeader] = useState('');
  const [claim_history, setClaimHistory] = useState([]);
  const [claimRecordForPayments, setClaimRecordForPayments] = useState({});
  const [claimDataForAction, setClaimDataForAction] = useState({});
  const [deleteClaimConfirm, setDeleteClaimConfirm] = useState(false);
  const [showDebitConfirm, setShowDebitConfirm] = useState(false);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
  const [showArchiveEClaimConfirm, setShowArchiveEClaimConfirm] = useState(false);
  const [updatePatientDialog, setUpdatePatientDialog] = useState(false);
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);
  const [showAddClaimNoteDialog, setShowAddClaimNoteDialog] = useState(false);
  const [showBillingHistoryDialog, setShowBillingHistoryDialog] = useState(false);
  const [showSimilarClaimsDialog, setShowSimilarClaimsDialog] = useState(false);

  const setConfirmDialog = (data, name) => {
    setClaimDataForAction(data);
    // eslint-disable-next-line default-case
    switch (name) {
      case confirmDialogNames.delete:
        setDeleteClaimConfirm(true);
        break;

      case confirmDialogNames.debit:
        setShowDebitConfirm(true);
        break;

      case confirmDialogNames.archive:
        setShowArchiveConfirm(true);
        break;

      case confirmDialogNames.archiveEClaim:
        setShowArchiveEClaimConfirm(true);
        break;

      case confirmDialogNames.addClaimNote:
        setShowAddClaimNoteDialog(true);
        break;

      case confirmDialogNames.updatePatientProfile:
        setUpdatePatientDialog(true);
        break;

      case confirmDialogNames.viewPayments:
        onPayment();
        break;

      case confirmDialogNames.billingHistory:
        setShowBillingHistoryDialog(true);
        break;

      case confirmDialogNames.similarClaims:
        setShowSimilarClaimsDialog(true);
        break;
    }
  };

  const actionButtonsParams = {
    isMobile,
    history,
    readonly,
    showOnPatientDetailsScreen: false,
    isFetching: isFetchingClaims,
    hideViewButton: true,
    showOnViewPanel: true,
    patientGuid: PatientGuid,
    setConfirmDialog,
    setShowDebitConfirm,
    dispatch
  };

  const onPayment = () => {
    setShowPaymentsDialog(true);

    if (isEmpty(claimRecordForPayments) || claimRecordForPayments?.InvoiceGuid !== claimRecord?.InvoiceGuid) {
      dispatch(
        getClaimInfo(claimRecord?.InvoiceGuid, (responseData) => {
          setClaimRecordForPayments(responseData);
        })
      );
    }
  };

  const onHideDeleteConfirm = () => {
    setClaimDataForAction({});
    setDeleteClaimConfirm(false);
  };

  const onAcceptDeleteClaim = () => {
    dispatch(
      deleteClaim({
        invoiceGuid: claimDataForAction?.InvoiceGuid,
        patientGuid: PatientGuid,
        showOnDetailsView: true
      })
    );
  };

  const onClose = () => {
    history.goBack();
  };

  const onDeletePayment = () => {
    const _callback = (list) => {
      const current = list?.find((i) => i.InvoiceGuid === claimRecord?.InvoiceGuid);
      setClaimRecord?.(current);
    };

    const userId = filterByUserForInvoicesViewPanel ? PatientGuid : 'undefined';
    dispatch(
      getClaimList(
        {
          page,
          pageSize: pageSize,
          filter: JSON.stringify(filters),
          query: `pid=${userId}&sortby=${sortby}&q=${JSON.stringify(filters)}`
        },
        false,
        false,
        (claimsList) => _callback(claimsList)
      )
    );
  };

  const requestHistory = () => {
    setRequestHistory(claimRecord, setClaimHistory, setPopupVisible, setHeader, dispatch, false);
  };

  const patientForBillingHistory = {
    PatientGuid: claimDataForAction?.PatientGuid,
    FirstName: claimDataForAction?.FirstName,
    LastName: claimDataForAction?.LastName,
    MidName: claimDataForAction?.MidName,
    BirthDay: claimDataForAction?.BirthDay,
    label: patientFullName(claimDataForAction)
  };

  const patientForSimilarClaims = {
    PatientGuid: claimDataForAction?.PatientGuid,
    FirstName: claimDataForAction?.FirstName,
    LastName: claimDataForAction?.LastName,
    MidName: claimDataForAction?.MidName,
    BirthDay: claimDataForAction?.BirthDay,
    label: patientFullName(claimDataForAction)
  };

  const footer = <Button label="Close" icon="pi pi-check" onClick={() => setPopupVisible(false)} />;

  return (
    <div className="flex gap-2 align-items-center">
      {claimRecord?.TotalNotes && notesCounter ? <NotesCounter totalNotes={claimRecord?.TotalNotes} invoiceGuid={claimRecord?.InvoiceGuid} /> : null}
      {claimRecord?.RemDiff >= 1 && (
        <Button
          className="p-button-rounded p-button-text"
          icon="pi pi-exclamation-circle"
          tooltip={t('Paid_with_adjustment')}
          tooltipOptions={{ position: 'top' }}
          onClick={requestHistory}
        />
      )}
      <Button
        className={cx('p-button-text p-button-rounded', isMobileOnly ? 'mobileRoundedButtonSize' : '')}
        icon="pi pi-ellipsis-v"
        aria-controls="popup_menu"
        aria-haspopup
        tooltipOptions={{ position: 'top' }}
        onClick={(event) => menu.current.toggle(event)}
      />
      <Menu
        id="popup_menu"
        ref={menu}
        popup
        model={rowActionsMenuItems({
          ...actionButtonsParams,
          data: claimRecord
        })}
      />
      {closeMenuIcon && <Button id={elementIDs.close} className="p-button-text p-button-rounded" icon="pi pi-times" onClick={onClose} />}

      <AddClaimNoteDialog
        rowData={claimDataForAction}
        visible={showAddClaimNoteDialog}
        onHide={() => {
          setShowAddClaimNoteDialog(false);
          setClaimDataForAction({});
        }}
      />

      <PaymenstDialog
        data={claimRecordForPayments}
        paymentCount={claimRecord?.PaymentCount}
        showPaymentsDialog={showPaymentsDialog}
        setShowPaymentsDialog={setShowPaymentsDialog}
        onDeletePayment={onDeletePayment}
      />

      <Dialog
        className={isMobileOnly && !isLandscape ? 'mt-6 historyDialog' : 'mt-5'}
        contentClassName={isMobileOnly && !isLandscape ? 'pb-0' : ''}
        modal
        header={popupHeader}
        footer={footer}
        visible={popupVisible}
        onHide={() => setPopupVisible(false)}
      >
        {RowClaimHistory(claim_history, isMobileOnly)}
      </Dialog>

      <DeleteConfirmation
        visible={deleteClaimConfirm}
        header={t('Delete_Claim')}
        message={String.format(t('Are_you_sure_you_want_to_delete_invoice'), claimDataForAction.RecordNo, claimDataForAction.FullName)}
        accept={onAcceptDeleteClaim}
        reject={onHideDeleteConfirm}
        onHide={onHideDeleteConfirm}
      />

      <DebitRequestDialog claimRecord={claimRecord} showDebitConfirm={showDebitConfirm} setShowDebitConfirm={setShowDebitConfirm} />

      <CommonConfirmDialog
        visible={showArchiveConfirm}
        bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claimDataForAction.RecordNo, claimDataForAction.FullName)}
        accept={() => {
          onArchiveClick(claimDataForAction, dispatch, PatientGuid, true);
          setShowArchiveConfirm(false);
        }}
        reject={() => setShowArchiveConfirm(false)}
        onHide={() => setShowArchiveConfirm(false)}
      />

      <CommonConfirmDialog
        visible={showArchiveEClaimConfirm}
        header={t('Archive_eClaim')}
        bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claimDataForAction?.RecordNo, claimDataForAction?.FullName)}
        accept={() => {
          onArchiveEClaim({
            eClaim: claimDataForAction,
            patientGuid: claimDataForAction?.PatientGuid,
            showOnViewPanel: true
          });
          setShowArchiveEClaimConfirm(false);
        }}
        reject={() => setShowArchiveEClaimConfirm(false)}
        onHide={() => setShowArchiveEClaimConfirm(false)}
      />

      <BatchRecordsWarnDialogForOnePatient
        data={claimDataForAction}
        visible={updatePatientDialog}
        showOnInvoicesPage={true}
        onHide={() => setUpdatePatientDialog(false)}
      />

      <PatientBillingHistoryDialog
        visible={showBillingHistoryDialog}
        initPatient={patientForBillingHistory}
        patientsList={[patientForBillingHistory]}
        onHide={() => setShowBillingHistoryDialog(false)}
      />

      <SimilarClaimsListDialog
        claim={claimDataForAction}
        visible={showSimilarClaimsDialog}
        initPatient={patientForSimilarClaims}
        patientsList={[patientForSimilarClaims]}
        onHide={() => setShowSimilarClaimsDialog(false)}
      />
    </div>
  );
};

export default ClaimMenu;
