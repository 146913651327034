/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';

import Context from '../../../../Context';
import PatientSearch from './PatientPicker';
import PrivateDatePicker from './PrivateDatePicker';
import ClaimInputField from './ClaimInputField';
import DatePicker from './DatePicker';
import HeaderPanel from './HeaderPanel';
import HeaderInfo from './HeaderInfo';
import BillToAddress from './BillToAddress';
import Catalogs from './Catalogs/Catalogs';
import ReviewPanel from './ReviewPanel';
import ReviewPanelActionButtons from './actionButtons/ReviewPanelActionButtons';
import PayToAddress from './PayToAddress';

import { getCodesForCatalog } from '../../../../service/Lookup';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { formatMostResentService } from './claimDetailsHelpers/formatMostResentService';
import { inputs } from '../PrivateDetails/claimDetailsHelpers/inputs';
import { catalogsRolodex } from './Catalogs/helpers/catalogsRolodex';
import { commonInputs } from '../../../config/commonInputsConfig';
import { debounce, isEmpty } from 'lodash';
import { onCodeDelete, onCodeSelect } from './Catalogs/helpers/codeAction';
import { getClinicServices } from '../../actions/claims.action.creators';
import { defaultGuid, multipleDoctorGuid, NA_DoctorGuid } from '../../../config/defaultValuesConfig';
import { isCodeRecentlyUsed } from '../../../utils/isCodeRecentlyUsed';
import { trimLeftZeros } from '../../../utils/trimLeftZeros';
import { mostRecent } from './Catalogs/helpers/catalogsRolodex';
import { elementIDs } from '../../../config/elementIDsConfig';
import { getRepresentative } from '../../../patients/actions/patients.action.creators';
import { t } from '../../../../service/localization/i18n';
import classes from './EditPanel.module.scss';
import cx from 'classnames';

const EditPanel = () => {
  const dispatch = useDispatch();
  const {
    isNew,
    errors,
    privateRecord,
    setShowCatalogsSidebar,
    setFocusFieldParams,
    setCatalogIndex,
    catalogMostRecentCodes,
    showSidebarWithCatalogs,
    setShowSidebarWithCatalogs,
    showCatalogsSidebar,
    catalogIndex,
    user,
    setCatalogsTableData,
    focusFieldParams,
    setIsLoadingTableData,
    searchValue,
    setSearchValue,
    mobileContent,
    setMobileContent,
    categoryValue,
    setMenuModel,
    setRepresentative,
    setGettingRepresentative,
    representative
  } = useContext(Context);
  const { isMobile, isMobileOnly, clientWidth } = useSelector((state) => state.core.window);
  const catalogCategories = useSelector((state) => state.claims.catalogCategories.privateServices);
  const { isFetchingInvoicesMostRecentCodes } = useSelector((state) => state.claims);

  const [showSearch, setShowSearch] = useState(false);
  const [showBillToAddressDialog, setShowBillToAddressDialog] = useState(false);
  const [showPayToAddressDialog, setShowPayToAddressDialog] = useState(false);

  const searchRef = useRef(null);
  const sideBarRef = useRef(null);
  const declinedMessage = useRef(null);
  const title = isNew ? t('Create_private_invoice') : `${t('Edit_private_invoice')} #${trimLeftZeros(privateRecord?.ClaimNumber)}`;
  const declinedStatus = privateRecord.Status === 3 || privateRecord.Status === 6; // [KS] CMO-992 - Rename buttons on Edit of the Declined and Archived Claim screen

  useEffect(() => {
    // [KS] CMO-992 - Rename buttons on Edit of the Declined and Archived Claim screen
    declinedStatus &&
      declinedMessage.current &&
      declinedMessage.current.show([
        {
          severity: 'warn',
          sticky: true,
          content: <HeaderInfo showStatus={false} declinedStatus={declinedStatus} />
        }
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set focus to search input
  useEffect(() => {
    showSearch && searchRef?.current?.focus();
  }, [showSearch]);

  const onQuickPickClick = (index) => {
    setCatalogIndex(index);
    setFocusFieldParams(catalogsRolodex()[index]);
    setShowCatalogsSidebar(true);
  };

  const isCodeSelected = (code) => {
    if (!isEmpty(privateRecord)) {
      const values = Array.isArray(privateRecord[inputs().privateService.name])
        ? privateRecord[inputs().privateService.name]
        : privateRecord[inputs().privateService.name]?.split(',');
      return values?.find((i) => i?.toUpperCase() === code?.toUpperCase());
    }
  };

  const onSelectCode = (data) => {
    const focusField = mostRecent().find((i) => i.codeType === inputs().privateService.codeType);

    !!isCodeSelected(data.value, focusField.name)
      ? onCodeDelete(data, focusField, privateRecord, dispatch)
      : onCodeSelect({ data, focusField, record: privateRecord, dispatch });
  };

  const desktopCatalogsSidebarHeader = () => (
    <div className="flex justify-content-between align-items-center col-12 p-0">
      <div className="col-3 p-0">
        <h5 className={cx('mb-0')}>{t('Service_Product.3')}</h5>
      </div>

      {/* SEARCH */}
      <div className="col-6 p-0 flex justify-content-center align-items-center">
        {/* <div className={cx("w-full", classes.desktopSearchInput)}>
          {catalogSearch}
        </div> */}
      </div>

      <div className="flex col-2 justify-content-end p-0">
        <Button
          id={elementIDs.dialogClose}
          className="p-button-text"
          label={t('Close')}
          icon="pi pi-times"
          onClick={() => setShowSidebarWithCatalogs(false)}
        />
      </div>
    </div>
  );

  const onCloseSidebar = () => {
    setShowSearch(false);
    setShowCatalogsSidebar(false);
    searchValue && setSearchValue('');
    mobileContent === 'table' && setMobileContent('menu');
  };

  const onClearInput = () => {
    if (searchValue?.length) {
      setSearchValue('');
      searchRef?.current?.focus();
      onSearch('');
    } else {
      setShowSearch(false);
    }
  };

  const onSearchClose = () => {
    if (showSearch) onClearInput();
    if (!showSearch) {
      setShowSearch(true);
      if (catalogIndex !== 1) {
        const focusField = catalogsRolodex(privateRecord, isNew)?.find((i) => i.name === inputs().privateService.name);
        setCatalogIndex(1);
        setFocusFieldParams(focusField);
      }
    }
  };

  const onSearch = (query = '') => {
    isMobile && setMobileContent('menu');

    // If search value is NOT empty
    if (query.length) {
      const practitionerGuid =
        privateRecord?.DoctorGuid === NA_DoctorGuid || privateRecord?.DoctorGuid === multipleDoctorGuid
          ? privateRecord?.DCNGuid
          : privateRecord?.DoctorGuid;
      getClinicServices({ practitionerGuid, query, pageSize: 500 }).then((response) => {
        const currentCategories = catalogCategories?.tabs[0]?.categories;

        if (response?.length) {
          const menuItems = currentCategories?.filter((i) => response.some((x) => x.category === i.value));
          setMenuModel(menuItems);
          getCurrentCategoryCodes(categoryValue, query);
        } else {
          const recentCategory = currentCategories?.find((i) => i.value.indexOf('_recent'));
          setCatalogsTableData({ serviceList: [] });
          setMenuModel([recentCategory]);
        }
      });
    }

    // If search value is empty
    if (!query.length) {
      getCurrentCategoryCodes(categoryValue, query);
    }
  };

  const getCurrentCategoryCodes = (category, query) => {
    setIsLoadingTableData(true);
    getCodesForCatalog({ catalog: category, query, dcnguid: user?.details?.DCNGuid }).then((response) => {
      if (response?.status === 200) {
        setIsLoadingTableData(false);
        setCatalogsTableData(response?.data);
      } else {
        setIsLoadingTableData(false);
        dispatch(setToastMessage({ type: 'error', message: response?.message }));
      }
    });
  };

  const searchOnChange = (value) => {
    setSearchValue(value);
    debouncedRequest(value);
  };

  const debouncedRequest = useCallback(
    debounce((value) => onSearch(value), 1000),
    []
  );

  const onEnterClick = (e) => {
    e.key === 'Enter' && onSearch(searchValue);
  };

  const mobileCatalogsHeader = () => (
    <div className="flex align-items-center col-12 p-0">
      <div className="col-2 p-0 flex justify-content-start align-items-center">
        <Button
          id={elementIDs.closeSidebarButton}
          className={cx('p-button-rounded p-button-text')}
          icon="pi pi-arrow-left"
          onClick={onCloseSidebar}
        />
      </div>

      <div className={cx('col-8 p-0 flex justify-content-center align-items-center', classes.headerLableRoot)}>
        <h5 className={cx('m-0')}>{focusFieldParams?.label}</h5>

        {/* SEARCH */}
        {/* <div className={showSearch ? classes.searchInputWrapActive : classes.searchInputWrapHidden}>
          {catalogSearch}
        </div> */}
      </div>

      {/* SEARCH */}
      {/* <div className={cx("col-2 p-0 flex justify-content-end align-items-center")} >
        <Button
          className={cx("p-button-rounded p-button-text")}
          icon={showSearch ? "pi pi-times" : "pi pi-search"}
          onClick={onSearchClose}
        />
      </div> */}
    </div>
  );

  const viewMore = () => {
    onQuickPickClick(1);
    !isMobile && setShowSidebarWithCatalogs(true);
  };

  const catalogSearch = (
    <span
      className={cx(
        'w-full p-input-icon-left',
        searchValue?.length ? 'p-input-icon-left' : '',
        searchValue?.length && !isMobile ? 'p-input-icon-right' : ''
      )}
    >
      <i className="pi pi-search" onClick={onSearch} />
      <InputText
        ref={searchRef}
        className={classes.searchInput}
        value={searchValue}
        placeholder={t('Search')}
        autoFocus={!isMobile}
        onChange={(e) => searchOnChange(e.target.value)}
        onKeyPress={onEnterClick}
      />
      {searchValue?.length && !isMobile ? <i className="pi pi-times" onClick={onClearInput} /> : null}
    </span>
  );

  const quickPickLabelQuestionIcon = (
    <>
      <Tooltip target=".quickPick-question-icon" position="top" />
      <i
        className="quickPick-question-icon pi pi-question-circle ml-3 pointer"
        data-pr-tooltip={t('Click_on_service_product_to_add_to_the_invoice')}
      />
    </>
  );

  const payToEditButton = (
    <Button
      id={elementIDs.payToEditButton}
      className="p-button-text p-button-rounded p-0 ml-4"
      icon="pi pi-pencil"
      disabled={!privateRecord[inputs().payTo.name]}
      onClick={() => setShowPayToAddressDialog(true)}
    />
  );

  const billToEditButton = (
    <Button
      id={elementIDs.billToEditButton}
      className="p-button-text p-button-rounded p-0 ml-4"
      icon="pi pi-pencil"
      disabled={!privateRecord[inputs().billTo.name] || !privateRecord[inputs().billTo.name]?.length}
      onClick={async () => {
        setShowBillToAddressDialog(true);

        const patient = Array.isArray(privateRecord[commonInputs.patients.descriptionName])
          ? privateRecord[commonInputs.patients.descriptionName]?.[0]
          : privateRecord[commonInputs.patients.descriptionName];
        const representativeGuid = patient.RepresentativeGuid;

        // Get representative info
        if (representativeGuid && representativeGuid !== representative?.PatientGuid && representativeGuid !== defaultGuid) {
          setGettingRepresentative(true);
          const result = await getRepresentative(representativeGuid);
          setRepresentative(result);
        }
        setGettingRepresentative(false);
      }}
    />
  );

  const patientInut = () => {
    // Create new invoice
    if (isNew)
      return (
        <ClaimInputField label={commonInputs.patients.label} required style={{ height: '100px' }}>
          <PatientSearch />
        </ClaimInputField>
      );

    // Edit invoice
    return (
      <ClaimInputField label={t('Patient.1')}>
        <InputText
          id={elementIDs.disabledPatientInput}
          className="flex font-bold w-full"
          value={privateRecord?.Patients?.length && privateRecord?.Patients[0]?.label}
          disabled={true}
        />
        {/* <div
        id={elementIDs.disabledPatientInput}
        className='flex align-items-center font-bold'
        style={{ height: '38px' }}
      >
        {privateRecord?.Patient?.length && privateRecord?.Patient[0]?.label}
      </div> */}
      </ClaimInputField>
    );
  };

  return (
    <div className="flex justify-content-center">
      <div className={classes.rootContainer}>
        <div className={cx('card mt-3 p-mb-8', isMobile ? classes.contentWrap : '')}>
          <div className={isMobileOnly ? 'justify-content-center' : ''}>
            <h2 id={elementIDs.cardTitle}>{title}</h2>
          </div>

          {isMobile && (
            <div className="flex justify-content-end align-items-center">
              <Button
                id={elementIDs.quickPickButton}
                className="p-button-text py-0 px-1 mb-1"
                label={t('Most_Recent')}
                onClick={() => onQuickPickClick(0)}
              />
            </div>
          )}

          {/* Form */}
          <div className={cx('grid', isMobile ? '' : 'my-3')}>
            <div className="col-12 sm:col-6 xl:col-3 py-0 px-2">
              {patientInut()}

              <HeaderPanel />
            </div>

            <div className="col-12 sm:col-6 xl:col-3 py-0 px-2 xl:pr-4">
              <ClaimInputField label={commonInputs.serviceDate.label} required={true}>
                <DatePicker
                  id={elementIDs.serviceDate}
                  initialDate={privateRecord[commonInputs.serviceDate.name]}
                  required={true}
                  setDateToLocalStorage={false}
                  errors={errors[commonInputs.serviceDate.name]}
                  dateField={commonInputs.serviceDate.name}
                />
              </ClaimInputField>

              <PrivateDatePicker />
            </div>

            <div className="col-12 xl:col-6 py-0 xl:pl-4">
              <div className="grid">
                <div className="col-12 lg:col-6 py-0 px-2 mt-2">
                  <ClaimInputField label={inputs().payToAddress.label} labelIcon={payToEditButton}>
                    <PayToAddress showAddressDialog={showPayToAddressDialog} setShowAddressDialog={setShowPayToAddressDialog} />
                  </ClaimInputField>
                </div>

                <div className="col-12 lg:col-6 py-0 mt-2">
                  <ClaimInputField label={inputs().billToAddress.label} labelIcon={billToEditButton}>
                    <BillToAddress showAddressDialog={showBillToAddressDialog} setShowAddressDialog={setShowBillToAddressDialog} />
                  </ClaimInputField>
                </div>

                <div className={cx('col-12 py-0 px-2', classes.servicesRecentCodesWrap)}>
                  <ClaimInputField label={t('Most_Recent')} required labelIcon={quickPickLabelQuestionIcon}>
                    {isFetchingInvoicesMostRecentCodes ? (
                      <>
                        <Skeleton className="mb-3" height="25px" />
                        <Skeleton className="mb-3" height="25px" />
                        <Skeleton className="mb-3" height="25px" />
                      </>
                    ) : (
                      <div id={elementIDs.quickPickServiceItemsContainer} className="p-ml--2">
                        {catalogMostRecentCodes.feecodes_pp?.map((code, index) => {
                          const formatted = formatMostResentService(code); // CMO-1767 - New/Edit Invoice->Quick Pick->add duration and time to the services' name

                          return (
                            <Button
                              id={`${elementIDs.quickPickCodeItem}_${index}`}
                              className={cx(
                                'p-button-rounded mx-1 mb-1 py-1 px-2',
                                !!isCodeSelected(formatted?.value)
                                  ? ''
                                  : isCodeRecentlyUsed(formatted?.order)
                                  ? 'p-button-outlined'
                                  : 'p-button-text',
                                formatted?.isWeekend ? 'p-button-danger' : ''
                              )}
                              key={`${formatted?.value}_${index}`}
                              label={formatted?.text}
                              // tooltip={formatted?.unit ? `${formatted?.duration} ${formatted?.unit}` : ""}
                              tooltipOptions={{ position: 'top' }}
                              onClick={() => onSelectCode(formatted)}
                            />
                          );
                        })}

                        <Button
                          id={elementIDs.openCatalog}
                          className="p-button-rounded p-button-text p-button-info mx-1 mb-1 py-1 px-2"
                          label={t('Open_Catalog')}
                          iconPos="right"
                          onClick={viewMore}
                        />
                      </div>
                    )}
                  </ClaimInputField>
                </div>
              </div>
            </div>
          </div>

          {/* Services data table */}
          <ReviewPanel />

          {/* Action buttons */}
          <div className={classes.actionButtonsRoot}>
            <div className="flex justify-content-between align-items-center py-3">
              <ReviewPanelActionButtons />

              {!(isMobile && clientWidth < 1024) && <small>{`*${t('Click_on_the_value_to_edit')}`}</small>}
            </div>
          </div>
        </div>

        {!isMobile && (
          <Sidebar
            className="p-sidebar-lg"
            visible={showSidebarWithCatalogs}
            position="right"
            blockScroll
            baseZIndex={999999}
            icons={desktopCatalogsSidebarHeader()}
            showCloseIcon={false}
            onHide={() => setShowSidebarWithCatalogs(false)}
          >
            <div id={elementIDs.sidebarCatalogs} className="catalogsRoot">
              <Catalogs />
            </div>
          </Sidebar>
        )}

        {isMobile && (
          <Sidebar
            className={cx(
              'w-full',
              isMobile && catalogIndex === 0 ? 'quickPickCatalogSidebarMobile' : 'catalogsSidebar',
              isMobileOnly ? 'catalogsSidebarMobile' : ''
            )}
            ref={sideBarRef}
            position="right"
            blockScroll
            showCloseIcon={false}
            visible={showCatalogsSidebar}
            icons={mobileCatalogsHeader()}
            baseZIndex={999999}
            onHide={() => setShowCatalogsSidebar(false)}
          >
            <div className={isMobileOnly ? 'createInvoiceCatalogsRootMobile' : 'catalogsRoot'}>
              <Catalogs />
            </div>
          </Sidebar>
        )}
      </div>
    </div>
  );
};

export default EditPanel;
