import React from 'react';
import { t } from '../../../service/localization/i18n';
import { formatZipCode } from '../../utils/formatZipCode';

const AddressLayout = ({ data, additionalAddressData, className }) => {
  // direction - "column" or "row"
  const { AddressLine, City, Province, Zip } = data || {};

  // Format city and province (e.g., "Toronto, ON")
  const formattedCityProvince = City?.trim() && Province?.trim() ? `${City}, ${Province}` : Province?.trim() || '';

  // Render a message when no data is provided
  if (!data && !additionalAddressData) {
    return <>{t('No_address')}</>;
  }

  return (
    <div className={className || 'address-layout'}>
      <div className={`flex flex-column ${className || ''}`}>
        {additionalAddressData && <div className="additional-address-data">{additionalAddressData}</div>}
        {AddressLine && <div className="address-line">{AddressLine}</div>}
        {formattedCityProvince && <div className="city-province">{formattedCityProvince}</div>}
        {Zip && <div className="postal-code">{formatZipCode(Zip)}</div>}
      </div>
    </div>
  );
};

export default AddressLayout;
