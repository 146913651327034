import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CommonCard from '../../../common/components/CommonCard';
import CreateTeleplan from './CreateTeleplan';
import CreatePrivateInvoice from './CreatePrivateInvoice';
import CreateWSBCReport from './CreateWSBCReport';
import CreatePatient from './CreatePatient';
import CheckMSPCoverage from './CheckMSPCoverage';
import { Button } from 'primereact/button';
import GlobalSearch from '../GlobalSearch';
import { routes } from '../../../../routes/routes';



const BillingCard = () => {

  const isMobileOnly = useSelector((state) => state.core.window.isMobileOnly);
  const history = useHistory();

  const [showSearch, setShowSearch] = useState(false); 

  const toggleSearch = () => {
    setShowSearch((prevState) => !prevState); 
  };

  const handleNewPatientClick = () => {
    history.push({
      pathname: `${routes.createPatient.path}/new`,
    });
  };

  const handleLabelClick = () => {
    history.push({
      pathname: `${routes.createPatient.path}/new`,
      state: { activateAutofill: true },
    });
  };

  const mobileButtons = (
    isMobileOnly ? (
      <div className="flex gap-2">
        {!showSearch ? (
          <>
            <Button
              icon="pi pi-search"
              rounded
              text
              onClick={toggleSearch} // Show the search bar
            />

            <Button
              label="New Patient"
              icon="pi pi-plus"
              outlined
              className="w-max"
              onClick={handleNewPatientClick} 
            />

            <Button
              label="Label"
              icon="pi pi-camera"
              className="w-max"
              onClick={handleLabelClick}
            />
          </>
        ) : (
          <div className="flex w-full align-items-center gap-2">
            <Button
              icon="pi pi-arrow-left"
              rounded
              outlined
              onClick={toggleSearch} // Return to buttons
            />
            <GlobalSearch
              name="patient"
              placeholder="Search patient"
              style = {{maxWidth: '180px'}}
            />
          </div>
        )}
      </div>
    ) : null
  );

  return (
    <CommonCard title={isMobileOnly ? "Billing" : "Do some billing"} headerActionButton={mobileButtons} >
      <div className="grid">
        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <CreateTeleplan />
            <CreatePrivateInvoice />
          </div>
        </div>

        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <CreatePatient />
            <CreateWSBCReport />
            <CheckMSPCoverage />
          </div>
        </div>
      </div>
    </CommonCard>
  );
};

export default BillingCard;
