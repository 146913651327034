import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { t } from '../../../../service/localization/i18n';

const Facility = () => {
  const name = 'Facility';
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('FacilityName')}>
          <InputText value={field.value} onChange={(e) => field.onChange(e.target.value)} />
        </InputWrapper>
      )}
    />
  );
};

export default Facility;
