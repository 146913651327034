import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import SuccessPage from '../../../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { getRecentCodes } from '../../../../../../service/Lookup';
import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';
import { calculateGroupTotal } from '../../helpers/calculateGroupTotal';
import { inputs } from '../../helpers/inputs';
import { routes } from '../../../../../../routes/routes';
import { getDefaultValues, invoiceTypes } from '../../helpers/defaultValues';
import { getCodesByType, getRecentCodesList, setClaimFilters } from '../../../../actions/claims.action.creators';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { claimsDefaultFilters } from '../../../../../config/defaultValuesConfig';
import { addNewTeleplan } from '../../../../helpers/addNewInvoice';
import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { useGroupPickerInputs } from '../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { useGroupTemplate } from '../../views/TeleplanGroup/hooks/useGroupTemplate';
import { isSupplementary } from '../../../../../config/specialitiesConfig';
import { getMostRecentCodes } from '../../helpers/getMostRecentCodes';
import { weeksForMW } from '../../config/defaultValues';
import { steps } from '../../helpers/steps';
import { t } from '../../../../../../service/localization/i18n';
import { map } from 'lodash';
import moment from 'moment';
import {
  resetBatchFormFromLocalStorage,
  resetFirstVisitFormFromLocalStorage,
  resetTeleplanFormFromLocalStorage
} from '../../helpers/formToLocalStorage';

const Success = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reset, watch, getValues, localState, firstVisit, setLocalState, isNew, pos, isGroup, declinedStatus, resetForm, resetFormState } =
    useFormContext();
  const { showSaveTemplateButton } = useGroupTemplate();
  const { calculateTotalForGroupPicker } = useGroupPickerInputs();

  const [totalClaims, setTotalClaims] = useState();
  const ref = useRef(null);

  const patients = watch(inputs.patient.name);
  const invoiceType = watch(inputs.payor.name);
  const speciality = Number(watch(inputs.speciality.name));
  const saveAsDraft = watch(inputs.saveAsDraft.name);
  const isSubmitted = watch(inputs.sendNow.name);
  const isErrorMessage = isSubmitted && localState.createdRecordsList?.some((i) => Number(i.Status) === 0);

  useEffect(() => {
    // Scroll to the top
    window[`scrollTo`]({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isGroup && !firstVisit) {
      // Reset local storage for Teleplan form
      return resetTeleplanFormFromLocalStorage();
    }

    if (firstVisit) {
      return resetFirstVisitFormFromLocalStorage();
    }

    if (isGroup) {
      // Reset local storage for Batch form
      return resetBatchFormFromLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (firstVisit) {
      return setTotalClaims(localState.records?.length);
    }

    if (isGroup) {
      return setTotalClaims(localState.groupRecords?.length);
    }

    return setTotalClaims(patients?.length * localState.records?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMostRecentCodesForAllPatients = async (patientGuids) => {
    const promises = patientGuids.map((patientGuid) => getRecentCodesList(patientGuid));
    // const promises = patientGuids.map((patientGuid) => getRecentCodes(patientGuid));
    const results = await Promise.all(promises);
    return results;
  };

  const resetLocalStateForSamePatient = () => {
    // Reset local states
    setLocalState((prevState) => ({
      ...prevState,
      practitionerPrefs: prevState.practitionerPrefs,
      step: steps.form
    }));
  };

  const patientsFullName = (patient) => {
    return patientFullNameWithAge(patient);
  };

  const successMessageLayout = (message) => {
    return (
      <div>
        {message}
        <span className="ml-2">{t('for')}</span>

        {patients?.length === 1 && <span className="ml-2">{`${patients?.length === 1 ? patientsFullName(patients[0]) : ''}`}</span>}
      </div>
    );
  };

  const successMessage = () => {
    if (isErrorMessage) return t('Claim_was_not_submitted');

    if (!isNew) {
      const message = String.format(
        t('You_submitted_record_for'),
        watch(inputs.sendNow.name) ? 'submited' : 'saved',
        1,
        currencyFormat(watch(inputs.feeTotal.name))
      );

      return successMessageLayout(message);
    }

    if (invoiceType === invoiceTypes.wsbc && speciality === 14) {
      return `${t('You_submitted_record_for_Chiro')} ${patientsFullName(patients[0])} - ${currencyFormat(calculateTotal())}`;
    }

    // Edit Archived, Declined, Not Paid claims
    if (!isNew && declinedStatus) {
      const message = String.format(
        t('You_submitted_record_for'),
        watch(inputs.sendNow.name) ? 'submited' : 'saved',
        1,
        currencyFormat(watch(inputs.feeTotal.name))
      );

      return successMessageLayout(message);
    }

    if (saveAsDraft) return String.format(t('You_have_created_x_drafts'), totalClaims > 0 ? totalClaims : 1);

    if (!saveAsDraft) {
      const message = String.format(
        t('You_submitted_record_for'),
        watch(inputs.sendNow.name) ? 'submited' : 'saved',
        totalClaims,
        currencyFormat(calculateTotal())
      );

      return successMessageLayout(message);
    }
  };

  const calculateTotal = () => {
    if (isGroup) return calculateTotalForGroupPicker();
    return calculateGroupTotal(localState.records);
  };

  const billSameTeleplan = (isGroupView = false) => {
    const currentFormState = watch();
    const patientGuid = patients[0]?.PatientGuid;
    const initValues = isGroupView ? formStateForGroup(currentFormState) : formStateForSamePatient(currentFormState);
    // Reset form state
    resetFormState({ initValues: { ...initValues, [inputs.status.name]: 0 }, isGroupView, keepDirty: true });
    !isGroupView && getMostRecentCodes(currentFormState, { patientGuid, setLocalState }); // VER-209 - Bill Same patient->fee codes from previous claim is not circled
    // Reset local states and save current practitioner prefs
    // VER-211 - Bill same patient->wrong default screen
    setLocalState((prevState) => ({
      ...prevState,
      catalogIndex: localStateInitValues.catalogIndex,
      activeSubTabIndex: localStateInitValues.activeSubTabIndex
    }));
  };

  const billSameTeleplanPatient = () => {
    resetLocalStateForSamePatient();

    if (!isNew || pos || isGroup || firstVisit) {
      history.replace(`${routes.newClaimRecord.path}`);
    }

    billSameTeleplan();
  };

  const billSameTeleplanPatientPOS = () => {
    resetLocalStateForSamePatient();

    if (!isNew) {
      history.replace(`${routes.newClaimRecord.path}`);
    }

    if (!pos && !isGroup) {
      history.replace(`${routes.teleplanPOS.path}`);
    }

    billSameTeleplan();
  };

  const billSameTeleplanPatientGroup = () => {
    const currentPatients = watch(inputs.patient.name);
    const listOfPatients = map(currentPatients, (i) => ({ PatientGuid: i.PatientGuid, RecentCodes: [] }));
    const patientGuids = map(listOfPatients, (i) => i.PatientGuid);

    // CMO-2568 - Teleplan group - missing resent codes for each row in the dx group picker
    getMostRecentCodesForAllPatients(patientGuids).then((recentCodes) => {
      const listOfPatientsWithCodes = map(listOfPatients, (i) => {
        return {
          PatientGuid: i.PatientGuid,
          RecentCodes: recentCodes?.find((x) => x.patientGuid === i.PatientGuid)
        };
      });

      setLocalState((prevState) => ({ ...prevState, listOfPatients: listOfPatientsWithCodes }));
    });

    if (isGroup) {
      setLocalState((prevState) => {
        return {
          ...localStateInitValues,
          practitionerPrefs: prevState.practitionerPrefs,
          listOfPatients: prevState.listOfPatients,
          groupRecords: prevState.groupRecords,
          step: steps.form // Go to first step
        };
      });

      billSameTeleplan(true);
    }

    if (!isGroup) {
      const formattedInvoice = formStateForGroup(watch());
      const feeCodes = formattedInvoice[inputs.feeCodes.codeType];

      const recordsWithSamePatients = map(currentPatients, (i) => {
        return {
          ...formattedInvoice,
          [inputs.patient.name]: [i],
          [inputs.startTime.name]: '',
          [inputs.endTime.name]: '',
          [inputs.duration.name]: 0,
          [inputs.units.name]: 1
        };
      });

      setLocalState((prevState) => ({
        ...localStateInitValues,
        groupRecords: feeCodes?.length ? recordsWithSamePatients : [],
        practitionerPrefs: prevState.practitionerPrefs,
        listOfPatients,
        step: steps.form
      }));

      billSameTeleplan(true);

      history.replace(`${routes.teleplanGroup.path}`);
    }
  };

  const formStateForGroup = (invoice) => {
    const initStateForGroup = getDefaultValues({
      currentPractitionerPrefs: localState.practitionerPrefs,
      firstVisit,
      isGroup: true,
      speciality,
      isNew
    });

    return {
      ...initStateForGroup,
      // CMO-2877 - Teleplan - batch same patient - practitioner input is empty
      [inputs.practitioner.name]: invoice[inputs.practitioner.name],
      [inputs.practitionerNumber.name]: invoice[inputs.practitionerNumber.name],
      [inputs.payeeNumber.name]: invoice[inputs.payeeNumber.name],
      [inputs.speciality.name]: speciality,

      [inputs.patient.name]: patients,
      [inputs.feeCodes.name]: invoice[inputs.feeCodes.name],
      [inputs.feeCodes.codeDescription]: invoice[inputs.feeCodes.codeDescription],
      [inputs.feeCodes.codeType]: invoice[inputs.feeCodes.codeType]
    };
  };

  const formStateForSamePatient = (invoice) => {
    const currentStartTime = invoice[inputs.startTime.name];
    const currentEndTime = invoice[inputs.endTime.name];
    const speciality = invoice[inputs.speciality.name];
    const invoiceType = invoice[inputs.payor.name];
    const _isSupplementary = isSupplementary(speciality, invoiceType);

    // Do not recet fee code
    if (_isSupplementary) {
      return {
        ...invoice,
        [inputs.comment.name]: ''
      };
    }

    // CMO-2798 - 1st visit -> time is carried over from previous patient
    if (currentEndTime) {
      const serviceDate = invoice[inputs.serviceDate.name];
      const formattedServiceDate = Array.isArray(serviceDate)
        ? serviceDate.map((i) => moment(i).format('MMM-DD')).join(', ')
        : moment(serviceDate).format('MMM-DD');
      const message = String.format(t('Previous_claim_from'), currentStartTime, currentEndTime, formattedServiceDate);
      dispatch(setToastMessage({ type: 'warn', message, lifeTime: 999999999 }));
    }

    return {
      ...invoice,
      [inputs.feeCodes.name]: [],
      [inputs.feeCodes.codeDescription]: [],
      [inputs.feeCodes.codeType]: [],

      [inputs.startTime.name]: currentEndTime || '', // CMO-2798 - 1st visit -> time is carried over from previous patient
      [inputs.endTime.name]: '',
      [inputs.comment.name]: ''
    };
  };

  const billOtherTeleplanPatient = () => {
    if (!isNew || pos || isGroup || firstVisit) {
      history.replace(`${routes.newClaimRecord.path}`);
    }

    resetForm();
  };

  const billOtherTeleplanPatientPOS = () => {
    if (!isNew || (!pos && !isGroup)) {
      history.replace(`${routes.teleplanPOS.path}`);
    }

    resetForm();
  };

  const billOtherTeleplanGoupPatient = () => {
    if (!isNew || !isGroup) {
      history.replace(`${routes.teleplanGroup.path}`);
    }

    resetForm();
  };

  const successMessageAditionalAction = () => {
    return (
      <Button className="p-button-link py-0" label={`${patients?.length} ${t('Patient.3').toLowerCase()}`} onClick={(e) => ref.current.toggle(e)} />
    );
  };

  const onSuccessMessageClick = () => {
    const recordNumters = localState.createdRecordsList?.map((i) => trimLeftZeros(i.RecordNo))?.join();
    dispatch(setClaimFilters({ ...claimsDefaultFilters, RecordNo: recordNumters }));
    history.replace(routes.invoices.path);
  };

  const onFirstVisit = () => {
    if (!firstVisit) {
      addNewTeleplan({ history, replaceRoute: true, firstVisit: true });
    }

    resetForm({ isFirstVisit: true });
  };

  const handleOpenPatientProfile = (PatientGuid) => {
    dispatch(setClaimFilters(claimsDefaultFilters));
    return history.replace(`${routes.patientDetails.path}/${PatientGuid}`);
  };

  const saveGroupLayout = () => {
    if (showSaveTemplateButton) {
      return (
        <div className="pb-4">
          <Button
            className="p-button-text"
            label={t('Save_group_for_future_billing')}
            onClick={() => setLocalState((prevState) => ({ ...prevState, createGroupTemplateDialog: true }))}
          />
        </div>
      );
    }

    return null;
  };

  const onCreate36020 = async (patient) => {
    setLocalState((prevState) => ({ ...prevState, loadingSpinner: true }));
    const feeCode36020Info = await getCodesByType({ codeType: inputs.feeCodes.codeType, query: '36020', speciality });
    setLocalState((prevState) => ({ ...prevState, loadingSpinner: false }));

    if (feeCode36020Info) {
      // Add 14 weeks to service date for 36010
      const serviceDate = getValues(inputs.serviceDate.name);
      const updatedServiceDateForMW = moment(serviceDate).add(weeksForMW, 'weeks');

      const initValues = {
        [inputs.practitioner.name]: getValues(inputs.practitioner.name),
        [inputs.practitionerNumber.name]: getValues(inputs.practitionerNumber.name),
        [inputs.payeeNumber.name]: getValues(inputs.payeeNumber.name),
        [inputs.speciality.name]: 0,

        [inputs.patient.name]: [patient],
        [inputs.serviceDate.name]: updatedServiceDateForMW,
        [inputs.feeCodes.name]: [feeCode36020Info?.[0]?.value],
        [inputs.feeCodes.codeDescription]: [feeCode36020Info?.[0]?.text],
        [inputs.feeCodes.codeType]: [feeCode36020Info[0]],
        [inputs.icd9.name]: getValues(inputs.icd9.name),
        [inputs.icd9.codeDescription]: getValues(inputs.icd9.codeDescription),
        [inputs.icd9.codeType]: getValues(inputs.icd9.codeType)
      };

      reset({ keepValues: true });

      if (!isNew || pos || isGroup || firstVisit) {
        history.replace(`${routes.newClaimRecord.path}`);
      }

      // Reset form state
      resetFormState({ initValues, isGroupView: isGroup, keepDirty: true });

      setLocalState((prevState) => ({
        ...prevState,
        step: steps.form,
        practitionerPrefs: prevState.practitionerPrefs,
        catalogIndex: localStateInitValues.catalogIndex,
        activeSubTabIndex: localStateInitValues.activeSubTabIndex
      }));

      dispatch(setToastMessage({ type: 'info', message: t('The_date_updated_based_on_36010') }));
    }
  };

  const actionButtonsForMidwife = () => {
    const feeCodes = getValues(inputs.feeCodes.codeType);
    const feeCodesOnly = feeCodes?.map((i) => i.value);
    const is36010Selected = feeCodesOnly?.includes('36010');

    if (speciality === 80 && is36010Selected) {
      return (
        <div className="flex flex-column align-items-center pt-6 gap-1">
          <div className="text-lg font-bold p-2">{`Create 36020 for: `}</div>
          {patients?.map((i) => {
            const patientName = patientFullNameWithAge(i);
            return (
              <Button
                className="p-button-text p-button-rounded"
                label={patientName}
                disabled={localState.loadingSpinner}
                onClick={() => onCreate36020(i)}
              />
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="pb-6">
      <SuccessPage
        isError={isErrorMessage}
        successMessage={successMessage()}
        onSuccessMessageClick={onSuccessMessageClick}
        successMessageAditionalAction={patients?.length > 1 ? successMessageAditionalAction() : null}
      >
        {saveGroupLayout()}

        <SuccessActionButtons
          patientsList={patients}
          billSameTeleplanPatient={billSameTeleplanPatient}
          billSameTeleplanPatientPOS={billSameTeleplanPatientPOS}
          billSameTeleplanPatientGroup={billSameTeleplanPatientGroup}
          billOtherTeleplanPatient={billOtherTeleplanPatient}
          billOtherTeleplanPatientPOS={billOtherTeleplanPatientPOS}
          billOtherTeleplanGoupPatient={billOtherTeleplanGoupPatient}
          firstVisit={onFirstVisit}
        />

        {actionButtonsForMidwife()}
      </SuccessPage>

      <OverlayPanel ref={ref} id="overlay_panel">
        <ul className="m-0 pl-0">
          {patients?.map((i, index) => {
            return (
              <li className="mb-2 pointer" key={i?.PatientGuid} onClick={() => handleOpenPatientProfile(i.PatientGuid)}>
                {`${index + 1}. ${i.LastName}, ${i.FirstName} ${i.MidName}`}
              </li>
            );
          })}
        </ul>
      </OverlayPanel>
    </div>
  );
};

export default Success;
