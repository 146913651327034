import React, { useMemo } from 'react';
import RepresentativeForm from '../../../../components/Widgets/Representative/index';
import { createRepresentative, editRepresentative } from '../../actions/patients.action.creators';

const Representative = ({ representative, onClose, onSuccessCreate, onSuccessEdit }) => {
  const initialState = useMemo(() => {
    if (representative?.RepresentativeGuid) {
      return {
        ...representative,
        formState: {
          isNew: false
        }
      };
    } else return undefined;
  }, [representative]);

  const onSubmit = async (data) => {
    if (data.formState.isNew) {
      // Create new representative
      delete data.PatientGuid;
      delete data.RepresentativeGuid;
      delete data.formState;
      const newRepresentative = await createRepresentative(data);
      onSuccessCreate?.(newRepresentative);
    } else {
      // Edit existing representative
      delete data.formState;
      const updatedRepresentative = await editRepresentative(data);
      onSuccessEdit?.(updatedRepresentative);
    }

    onClose();
  };

  return <RepresentativeForm initialState={initialState} onSubmit={onSubmit} onClose={onClose} />;
};

export default Representative;
