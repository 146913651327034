import React from 'react';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

export const ToggleCatalogsButtonDesktop = ({ showCatalogs, toggleCatalogs }) => {
  return (
    <Button
      id={showCatalogs.desktop ? elementIDs.closeCatalogsButton : elementIDs.openCatalogsButton}
      style={{ minWidth: '120px' }}
      className="p-button-text w-max toggleCatalogsDesktopButton"
      label={showCatalogs.desktop ? t('Close_Catalog') : t('Open_Catalog')}
      type="button"
      onClick={() => toggleCatalogs('desktop')}
    />
  );
};

const ToggleCatalogsButtonMobile = ({ toggleCatalogs }) => {
  return (
    <Button
      id={elementIDs.openCatalogsButton}
      className="p-button-text w-max toggleCatalogsMobileButton"
      label="Quick pick"
      type="button"
      onClick={() => toggleCatalogs('mobile')}
    />
  );
};

export const ToggleCatalogs = ({ showCatalogs, toggleCatalogs, hideToggleCatalogsButton }) => {
  return (
    <div className="flex align-items-center justify-content-end">
      {!hideToggleCatalogsButton && <ToggleCatalogsButtonDesktop showCatalogs={showCatalogs} toggleCatalogs={toggleCatalogs} />}
      <ToggleCatalogsButtonMobile toggleCatalogs={toggleCatalogs} />
    </div>
  );
};
