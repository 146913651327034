import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import FeeCodeBodyTemplate from '../templates/FeeCodeBodyTemplate';
import TimeBodyTemplate from '../templates/TimeBodyTemplate';
import DurationBodyTemplate from '../templates/DurationBodyTemplate';
import UnitsTemplate from '../templates/UnitsTemplate';
import UnitsCalculationBodyTemplate from '../templates/UnitsCalculationBodyTemplate';
import FeeBodyTemplate from '../templates/FeeBodyTemplate';
import PercentTemplate from '../templates/PercentTemplate';
import BilledTemplate from '../templates/BilledTemplate';
import DxBodyTemplate from '../templates/DxBodyTemplate';
import MobileRowActionButtons from './MobileRowActionButtons';
import RowNumberBodyTemplate from '../templates/RowNumberBodyTemplate';
import RowExpansionTemplate from '../templates/RowExpansionTemplate';
import RowGroupHeaderTemplate from '../templates/RowGroupHeaderTemplate';
import StartTimeEditor from '../templates/StartTimeEditor';
import EndTimeEditor from '../templates/EndTimeEditor';
import UnitsEditor from '../templates/UnitsEditor';
import FeeEditor from '../templates/FeeEditor';
import DxCodeEditor from '../templates/DxCodeEditor';
import PercentEditor from '../templates/PercentEditor';
import { Message } from 'primereact/message';

import { showUnitsCalculationText } from '../../../helpers/isUnitsCalculationText';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { t } from '../../../../../../../service/localization/i18n';
import { get } from 'lodash';
import cx from 'classnames';

const RecordView = ({ rowData, rowIndex }) => {
  const { localState, isGroup } = useFormContext();
  const records = localState.records;
  const isUnitsCalculationText = showUnitsCalculationText(records);

  return (
    <div className="card flex flex-column gap-3 text-xl m-0">
      <RowActions rowData={rowData} rowIndex={rowIndex} />
      <DuplicateAlert rowData={rowData} />
      <RecordAlert rowData={rowData} />
      <RecordWarning rowData={rowData} />

      <RowView label={<CodeLabel rowData={rowData} />} body={<FeeCodeBodyTemplate rowData={rowData} hideTooltip />} />

      <EditableRowView
        label={t('From')}
        rowData={rowData}
        rowIndex={rowIndex}
        field="service_start"
        Template={TimeBodyTemplate}
        Editor={StartTimeEditor}
        updateCallback="onStartTimeEditorChange"
      />
      <EditableRowView
        label={t('To')}
        rowData={rowData}
        rowIndex={rowIndex}
        field="service_finish"
        Template={TimeBodyTemplate}
        Editor={EndTimeEditor}
        updateCallback="onEndTimeEditorChange"
      />
      <RowView label={t('Duration')} body={<DurationBodyTemplate rowData={rowData} />} />
      <EditableRowView
        label={t('Units')}
        rowData={rowData}
        rowIndex={rowIndex}
        field="units"
        Template={UnitsTemplate}
        Editor={UnitsEditor}
        updateCallback="onUnitsEditorChange"
      />
      {isUnitsCalculationText && <RowView label={t('Units Calculation')} body={<UnitsCalculationBodyTemplate rowData={rowData} />} />}
      <EditableRowView
        label={t('Fee')}
        rowData={rowData}
        rowIndex={rowIndex}
        field="fee"
        Template={FeeBodyTemplate}
        Editor={FeeEditor}
        updateCallback="onFeeEditorChange"
      />
      <EditableRowView
        label={t('Percent')}
        rowData={rowData}
        rowIndex={rowIndex}
        field="percent"
        Template={PercentTemplate}
        Editor={PercentEditor}
        updateCallback="onPercentEditorChange"
      />
      <RowView label={t('Billed')} body={<BilledTemplate rowData={rowData} style={{ marginRight: '0px' }} />} />
      {!isGroup && (
        <EditableRowView
          label={t('Dx')}
          rowData={rowData}
          rowIndex={rowIndex}
          field="icd9"
          Template={DxBodyTemplate}
          Editor={DxCodeEditor}
          updateCallback="handleDxChange"
          tooltip={
            <>
              {rowData.icd9?.split(',').map((code, index) => (
                <div key={index}>
                  {code}: {rowData.icd9_text?.split('|')[index]}
                </div>
              ))}
            </>
          }
        />
      )}
      <RowExpansionTemplate data={rowData} />
    </div>
  );
};

export default RecordView;

const EditableRowView = ({ label, rowData, rowIndex, field, Template, Editor, updateCallback, tooltip }) => {
  const [editing, setEditing] = useState(false);
  const updateMethods = useUpdateRecords();
  const handleEdit = () => setEditing(true);
  const handleBlur = () => setEditing(false);

  return (
    <div className="flex justify-content-between align-items-center gap-3" style={{ height: '16px' }}>
      <div className="flex align-items-center gap-3">
        <span>{label}</span>
        {tooltip && (
          <>
            <Tooltip target={`.tooltip_${field}`} position="right">
              {tooltip}
            </Tooltip>
            <i className={cx('pi pi-info-circle', `tooltip_${field}`)} />
          </>
        )}
      </div>

      {editing ? (
        <Editor
          options={{
            rowData,
            rowIndex,
            field,
            value: rowData[field],
            editorCallback: (value, options, requiredInputs) => updateMethods[updateCallback](value, options, requiredInputs)
          }}
          onBlur={handleBlur}
          inputClassName="h-2rem"
        />
      ) : (
        <span onClick={handleEdit}>
          <Template rowData={rowData} field={field} hideTooltip />
        </span>
      )}
    </div>
  );
};

const RowView = ({ label, body }) => (
  <div className="flex justify-content-between align-items-center gap-3">
    <span>{label}</span>
    <span>{body}</span>
  </div>
);

const RowActions = ({ rowData, rowIndex }) => (
  <div className="flex align-items-center justify-content-between gap-2 w-full">
    <div className="flex gap-1 min-w-4rem">
      # <RowNumberBodyTemplate rowData={rowData} rowIndex={rowIndex} />
    </div>
    <RowGroupHeaderTemplate rowData={rowData} />
    <MobileRowActionButtons rowOptions={{ rowData, rowIndex }} />
  </div>
);

const CodeLabel = ({ rowData }) => (
  <div className="flex align-items-center gap-3">
    <span className="font-bold">{t('Code')}</span>
    <Tooltip target={`.tooltip_fee_code_${rowData.fee_code}`} position="right">
      {`${rowData.fee_code}: ${rowData.fee_code_text}`}
    </Tooltip>
    <i className={cx('pi pi-info-circle', `tooltip_fee_code_${rowData.fee_code}`)} />
  </div>
);

const DuplicateAlert = ({ rowData }) => {
  const { localState } = useFormContext();
  const isDuplicate = get(localState.recordsRequiredInputs, rowData.id, {})?.isDuplicate;
  if (!isDuplicate) return null;
  return <Message severity="warn" content={t('To_demonstrate_that_the_second_claim_with_the_same_fee_code_is_eligible')} />;
};

const RecordAlert = ({ rowData }) => {
  const alert = rowData.alert;
  if (!alert) return null;
  return <Message severity="error" content={alert} />;
};

const RecordWarning = ({ rowData }) => {
  const warning = rowData.warning;
  if (!warning) return null;
  return <Message severity="warn" content={warning} />;
};
