export const localStorageKeys = {
  themeKey: 'theme',
  username: 'username',
  patientsPageSize: 'patients-page-size',
  invoicesPageSize: 'invoices-page-size',
  invoicesFormSplitter: 'invoices-form-splitter',
  invoicesCatalogCategories: 'invoices-catalog-categories',
  serviseDate: 'servise-date',
  displayReferredByNames: 'display-referred-by-names', // CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick
  doNotShowEmergencyServiceDialog: 'do-not-show-smergency-service-dialog',
  doNotShowApplyToAllDxDialog: 'do-not-show-apply-to-all-dx-dialog',
  doNotShowApplyToAllReferralDialog: 'do-not-show-apply-to-all-referral-dialog',
  doNotShowDeleteGroupRecordConfirm: 'do-not-show-delete-group-record-confirm',
  doNotShowEClaimCompleteSetupDialog: 'do-not-show-eclaim-complete-setup-dialog',
  batchArrowsReorder: 'batch-arrows-reorder',
  batchDragDropReorder: 'batch-drag-drop-reorder',
  batchFormState: 'batch-form-state',
  teleplanFormState: 'teleplan-form-state',
  firstVisitFormState: 'first-visit-form-state'
};
