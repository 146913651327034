import React, { useContext } from 'react';
import Context from '../../../../Context';
import AddressLayout from '../../../common/components/AddressLayout';
import { inputs } from './claimDetailsHelpers/inputs';
import { elementIDs } from '../../../config/elementIDsConfig';
import { Dialog } from 'primereact/dialog';
import EditBillTo from './EditBillToAddress';

const BillToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const { privateRecord } = useContext(Context);

  const additionalAddressData = () => {
    const billToValue = privateRecord[inputs().billTo.name];
    const fullName = billToValue?.length ? `${billToValue[0]?.LastName}, ${billToValue[0]?.FirstName}` : null;
    return fullName;
  };

  const onHideDialog = () => {
    setShowAddressDialog(false);
  };

  return (
    <div id={elementIDs.billToAddressWrap}>
      <div className="flex align-items-center">
        <AddressLayout
          direction="row"
          data={privateRecord[inputs().billTo.name] ? privateRecord[inputs().billTo.name][0] : ''}
          additionalAddressData={additionalAddressData()}
        />
      </div>

      <Dialog
        className="w-full"
        contentClassName="pb-0"
        header="Edit payer"
        visible={showAddressDialog}
        style={{ maxWidth: '530px' }}
        onHide={onHideDialog}
      >
        <EditBillTo onClose={onHideDialog} />
      </Dialog>
    </div>
  );
};

export default BillToAddress;
