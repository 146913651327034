import CryptoJS from 'crypto-js';
import { store } from '../../store';

// Encrypt a key with a unique secret using AES
export const encryptKey = (key) => {
  const user = store.getState().user.details;
  const secret = `${key}-${user.UserGuid}-${user.DCNGuid}`;
  return secret;
  // const iv = CryptoJS.enc.Utf8.parse(secret.slice(0, 16)); // IV: 16 bytes
  // const encrypted = CryptoJS.AES.encrypt(key, CryptoJS.enc.Utf8.parse(secret), { iv: iv });
  // return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

const generateKey = () => {
  const user = store.getState().user.details;
  // Derive a 256-bit key using userGuid and dcnGuid
  return CryptoJS.SHA256(user.UserGuid + user.DCNGuid).toString(CryptoJS.enc.Hex);
};

// Function to encrypt data
export const encryptData = (data) => {
  const key = generateKey();
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

// Function to decrypt data
export const decryptData = (encryptedData) => {
  const key = generateKey();
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
