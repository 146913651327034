import React, { useEffect, useRef } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';

const FeeEditor = ({ options, onBlur, inputClassName }) => {
  const { onFeeEditorChange } = useUpdateRecords();
  const wrapperRef = useRef(null); // Reference to the outer wrapper

  useEffect(() => {
    // Access the actual input DOM element
    const inputElement = wrapperRef.current?.querySelector('input');

    if (inputElement) {
      inputElement.focus(); // Set focus on the input field
      const valueLength = inputElement.value.length; // Get the length of the input value
      inputElement.setSelectionRange(valueLength, valueLength); // Move cursor to the end
    }
  }, []);

  return (
    <div className="relative" ref={wrapperRef}>
      <InputNumber
        inputStyle={{ width: 'inherit' }}
        style={{ width: '10rem' }}
        inputClassName={inputClassName}
        className="flex"
        type="text"
        value={options.rowData.fee}
        mode="currency"
        currency="USD"
        locale="en-US"
        onChange={(e) => onFeeEditorChange(e.value, options)}
        onBlur={(e) => onBlur?.(e)}
      />
    </div>
  );
};

export default FeeEditor;
