import moment from 'moment';
import { getRemittanceUrl } from '../../config';

const getObf = (privacy) => (privacy ? '1' : 'false');

const remittanceLink = (DCNGuid, remittanceGuid, privacy) => {
  const remittanceReportURL = getRemittanceUrl();
  const obf = getObf(privacy);
  return `${remittanceReportURL}/show.aspx?rt=r&DCNGuid=${DCNGuid}&id=${remittanceGuid}&obf=${obf}`;
};

const remittanceLinkFilter = (DCNGuid, remittanceGuid, num, type, privacy) => {
  const remittanceReportURL = getRemittanceUrl();
  const obf = getObf(privacy);
  return `${remittanceReportURL}/show.aspx?rt=r&DCNGuid=${DCNGuid}&id=${remittanceGuid}&${type}=${num}&obf=${obf}`; //need to fix
};

const remittanceLinkByType = (DCNGuid, remittanceGuid, privacy) => {
  const remittanceReportURL = getRemittanceUrl();
  const obf = getObf(privacy);
  return `${remittanceReportURL}/show.aspx?rt=rbt&DCNGuid=${DCNGuid}&id=${remittanceGuid}&obf=${obf}`;
};

export const getRemittanceLink = (data) => {
  if (data?.ClaimType === 'E') {
    return getEOBLink(data);
  } else {
    return remittanceLink(data?.DCNGuid, data?.RemittanceGuid);
  }
};

export const getRemittanceLinkWithRow = (data, privacy) => {
  if (data?.ClaimType === 'E') {
    return getEOBLink(data);
  } else {
    return remittanceLink(data?.DCNGuid, data?.RowGuid, privacy);
  }
};

export const getRemittanceLinkByType = (data, privacy) => {
  if (data?.ClaimType === 'E') {
    return getEOBLink(data);
  } else {
    return remittanceLinkByType(data?.DCNGuid, data?.RowGuid, privacy);
  }
};

export const getRemittanceLinkFilter = (data, num, type, privacy) => {
  if (data?.ClaimType === 'E') {
    return getEOBLink(data);
  } else {
    return remittanceLinkFilter(data?.DCNGuid, data?.RowGuid, num, type, privacy);
  }
};

export const getEOBLink = (eClaim) => {
  const DCNGuid = eClaim?.DCNGuid;
  const claimGuid = eClaim?.ClaimGuid;
  const timestamp = moment().valueOf();
  // const legacyUrl = eClaim?.legacy_domain_url;
  const legacyUrl = getRemittanceUrl();
  if (!legacyUrl) return '';
  // Create a URL object to parse the provided URL
  const urlObject = new URL(legacyUrl);
  // Extract the base URL without any path
  const hostUrl = `${urlObject.protocol}//${urlObject.host}`;

  return `${hostUrl}/tecform.aspx?id=${claimGuid}&pf=pred4c&did=${DCNGuid}&ts=${timestamp}`;
};
