import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { BS_TOLL_FREE, VANCOUVER_PHONE, VICTORIA_1, VICTORIA_2 } from '../../../../config';
import { findDifferenceForEligibility } from './helpers/findDifferenceForEligibility';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import { isEmpty } from 'lodash';
import moment from 'moment';

const MismatchDialog = ({ isNew, header, showMismatchDialog, setShowMismatchDialog, patient_details, eligibility, updateFormikState }) => {
  const getMonth = moment(patient_details?.BirthDay).month() + 1;
  const month = getMonth > 9 ? getMonth : `0${getMonth}`;
  const phn = patient_details.PHN ? `${patient_details.PHN} #,` : '';

  const labels = {
    FirstName: 'First Name',
    LastName: 'Last Name',
    MidName: 'Middle Name',
    Sex: 'Sex'
  };

  const differences = findDifferenceForEligibility(eligibility, patient_details);
  const isDifferences = !isEmpty(differences);

  const footer = (
    <>
      {isDifferences ? (
        <div>
          <Button id={elementIDs.ignore} type="button" outlined label={t('Ignore')} autoFocus onClick={() => setShowMismatchDialog(false)} />
          <Button
            id={elementIDs.useEligibilityDemographics}
            type="button"
            label={t('Use_eligibility_demographics')}
            onClick={() => {
              updateFormikState && updateFormikState(eligibility);
              setShowMismatchDialog(false);
            }}
          />
        </div>
      ) : (
        <Button id={elementIDs.dialogClose} type="button" label={t('Close')} onClick={() => setShowMismatchDialog(false)} />
      )}
    </>
  );

  return (
    <Dialog
      id={elementIDs.nameMismatchDialog}
      header={header ? header : `${t('MSP_Spelling_Report')}: ${isDifferences ? t('Name_Mismatch') : t('Match_Found')}`}
      footer={footer}
      visible={showMismatchDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      modal
      baseZIndex={1000000}
      onHide={() => setShowMismatchDialog(false)}
    >
      <div id={elementIDs.nameMismatchDialogRootContent} className="mt-2">
        {isDifferences ? (
          <div className="mb-3">
            <div className="my-1">{t('MSP_eligibility_report_seems_to_contain_different_patients_demographics')}</div>
            <div className="mt-3 mb-1">
              {`${t('Eligibility_report')}: `}
              <span className="font-bold">
                {Object.entries(differences).map(([key, value], index) => (
                  <span key={index}>
                    {`${labels[key]}: ${value.eligibilityValue}`}
                    {index < Object.entries(differences).length - 1 && ', '}
                  </span>
                ))}
              </span>
            </div>
            <div className="mb-3">
              {`${t('Your_records')}: `}
              <span className="font-bold">
                {Object.entries(differences).map(([key, value], index) => (
                  <span key={index}>
                    {`${labels[key]}: ${value.patientValue}`}
                    {index < Object.entries(differences).length - 1 && ', '}
                  </span>
                ))}
              </span>
            </div>
            <div className="my-1">{`${t('Eligibility_report_might_contain_obsolete_demographics_data')}:`}</div>
          </div>
        ) : (
          <>
            {' '}
            <div className="mb-3">The name entered matches the eligibility report. You'll see the eligibility report after saving the changes.</div>
            <div className="mb-3">{t('Wrong_spelling_can_cause_declined_claims')}</div>
          </>
        )}

        <div>
          <div className="mb-1">
            {`${t('Vancouver')}: `}
            <a href={`tel:${VANCOUVER_PHONE}`}>{VANCOUVER_PHONE}</a>
          </div>

          <div className="mb-1">
            {`${t('Victoria')} `}
            <a href={`tel:${VICTORIA_1}`}>{VICTORIA_1}</a>
            {` ${t('or')} `}
            <a href={`tel:${VICTORIA_2}`}>{VICTORIA_2}</a>
          </div>

          <div className="mb-1">
            {`${t('BC_toll_free')}: `}
            <a href={`tel:${BS_TOLL_FREE}`}>{BS_TOLL_FREE}</a>
          </div>

          {isNew ? (
            <div>{String.format(t('Enter_PHN_MM_YYYY'), '1', '4', '1')}</div>
          ) : (
            <div>{`${'Enter'}: 1, 4, ${phn} ${month}#, ${moment(patient_details.BirthDay).year()}#, 1`}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default MismatchDialog;
