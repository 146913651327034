import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { get } from 'lodash';

const MobileRowActionButtons = ({ rowOptions }) => {
  const menu = useRef(null);
  const { rowData, rowIndex } = rowOptions;
  const { localState } = useFormContext();
  const { handleDeleteRecord, handleDuplicateRecord } = useUpdateRecords();
  const isDuplicate = get(localState.recordsRequiredInputs, rowData.id, {})?.isDuplicate;

  const toggleMenu = (event) => {
    menu.current?.toggle(event);
  };

  const handleClick = (e) => {
    toggleMenu(e);
  };

  const menuItems = [
    ...(isDuplicate
      ? []
      : [
          {
            id: 'duplicate_record',
            icon: 'pi pi-copy',
            label: 'Duplicate',
            command: () => handleDuplicateRecord(rowData, rowIndex)
          }
        ]),

    {
      id: 'delete_record',
      icon: 'pi pi-trash',
      label: 'Delete',
      command: () => handleDeleteRecord(rowData)
    }
  ];

  return (
    <>
      <TieredMenu className="w-10rem" data-testid="tiered-menu" ref={menu} model={menuItems} popup />
      <Button rounded text icon="pi pi-ellipsis-v" onClick={handleClick} />
    </>
  );
};

export default MobileRowActionButtons;
