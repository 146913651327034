import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../Inputs';
import { Dropdown } from 'primereact/dropdown';
import { t } from '../../../../service/localization/i18n';

const ContactType = () => {
  const name = 'ContactType';
  const { watch, control, setValue, clearErrors } = useFormContext();
  const isPayerPatient = watch('formState.isPayerPatient');

  const options = [
    { label: t('Person'), value: 1 },
    { label: t('Company'), value: 2 }
  ];

  const onChange = (value, field) => {
    field.onChange(value);
    setValue('CompanyName', '');
    setValue('Comment', '');
    clearErrors(['CompanyName', 'Comment', 'FirstName', 'LastName']);
  };

  if (isPayerPatient) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Contact_Type')}>
          <Dropdown value={field.value} options={options} autoFocus={!watch('Payer')} onChange={(e) => onChange(e.value, field)} />
        </InputWrapper>
      )}
    />
  );
};

export default ContactType;
