import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Representative from '../../../../../components/Widgets/Representative/index';
import Context from '../../../../../Context';
import Payer from './Payer';

import { editPatient, editRepresentative } from '../../../../patients/actions/patients.action.creators';
import { onBillToUpdate } from '../claimDetailsHelpers/onClaimUpdate';
import { updatePrivateRecord } from '../../../actions/claims.action.creators';
import { representativeStatus } from '../../../../config/defaultValuesConfig';
import moment from 'moment';

const EditBillTo = ({ onClose }) => {
  const dispatch = useDispatch();
  const { setRepresentative, gettingRepresentative, privateRecord, representative } = useContext(Context);
  const patient = Array.isArray(privateRecord.Patients) ? privateRecord.Patients[0] : privateRecord.Patients;
  const billTo = Array.isArray(privateRecord.BillTo) ? privateRecord.BillTo[0] : privateRecord.BillTo;
  const payer = billTo?.PatientGuid === representative?.PatientGuid ? 'Representative' : 'Patient';
  const isPatient = payer === 'Patient';
  const initPatient = isPatient ? patient : representative;

  if (gettingRepresentative)
    return (
      <div className="h-30rem flex justify-content-center align-items-center">
        <div className="flex gap-4 align-items-center">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
          <div className="text-3xl">Loading...</div>
        </div>
      </div>
    );

  const initialState = {
    Payer: payer,
    ...initPatient,
    formState: {
      patient,
      representative,
      isPayerPatient: isPatient,
      isRepresentativeExist: !!representative
    }
  };

  const onSubmit = async (data) => {
    const payer = data.Payer;
    delete data.formState;
    delete data.Payer;

    const dataForSubmit = {
      ...data,
      BirthDay: moment(data.BirthDay).format('YYYY-MM-DD')
    };

    if (payer === 'Patient') {
      dispatch(
        editPatient(dataForSubmit, (updatedPatient) => {
          onBillToUpdate([updatedPatient], dispatch);
          dispatch(updatePrivateRecord({ Patients: { ...patient, ...updatedPatient } }));
          onClose();
        })
      );
    }

    if (payer === 'Representative') {
      const response = await editRepresentative({ ...dataForSubmit, Status: representativeStatus });
      if (response) {
        onBillToUpdate([response], dispatch);
        setRepresentative(response);
        onClose();
      }
    }
  };

  return <Representative initialState={initialState} onSubmit={onSubmit} onClose={onClose} payerInput={<Payer />} />;
};

export default EditBillTo;
