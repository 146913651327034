import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import cx from 'classnames';

const FeeCodeBodyTemplate = ({ rowData, hideTooltip }) => {
  return (
    <>
      <Tooltip target={`.invoiceRecordsFeeTooltip_${rowData.fee_code}`} position="top">
        {`${rowData.fee_code}: ${rowData.fee_code_text}`}
      </Tooltip>
      <span className={cx('font-bold', !hideTooltip && `invoiceRecordsFeeTooltip_${rowData.fee_code}`)}>{rowData.fee_code}</span>
    </>
  );
};

export default FeeCodeBodyTemplate;
