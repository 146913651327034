import { addNewPrivate, addNewTeleplan, addEClaim, addNewWSBCR } from '../../../modules/claims/helpers/addNewInvoice';

export const handleBillSameTeleplanPatient = (history, patientsList) => () =>
  addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: false });

export const handleBillSameTeleplanPatientPOS = (history, patientsList) => () =>
  addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: true });

export const handleBillSameTeleplanPatientGroup = (history, patientsList) => () =>
  addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, group: true });

export const handleBillSamePrivatePatient = (history, patientsList) => () =>
  addNewPrivate({ history, patient_details: patientsList[0], replaceRoute: true });

export const handleBillSameEClaim = (history, patientsList) => () =>
  addEClaim({ history, patientGuid: patientsList[0]?.PatientGuid, replaceRoute: true });

export const handleWSBCReportWithSamePatient = (history, patientsList) => () =>
  addNewWSBCR({ history, patient_details: patientsList[0], replaceRoute: true });
