export const findDifferenceForEligibility = (eligibility, patient) => {
  const differences = {};

  const mappings = {
    FirstName: 'FIRST_NAME',
    LastName: 'LAST_NAME',
    MidName: 'MID_NAME',
    Sex: 'GENDER'
  };

  // Normalize EligibilitySex to match short forms
  const normalizeSex = (sex) => {
    if (sex?.toUpperCase() === 'MALE') return 'M';
    if (sex?.toUpperCase() === 'FEMALE') return 'F';
    return sex?.trim(); // Return as-is for other cases
  };

  const getFullSex = (sex) => {
    if (sex === 'M') return 'MALE';
    if (sex === 'F') return 'FEMALE';
    return sex;
  };

  for (const [patientKey, eligibilityKey] of Object.entries(mappings)) {
    let patientValue = patient[patientKey]?.trim();
    let eligibilityValue = eligibility[eligibilityKey]?.trim();

    // Normalize `Sex` values before comparison
    if (patientKey === 'Sex') {
      const normalizedPatientSex = normalizeSex(patientValue);
      const normalizedEligibilitySex = normalizeSex(eligibilityValue);

      if (normalizedPatientSex && normalizedEligibilitySex && normalizedPatientSex !== normalizedEligibilitySex) {
        differences[patientKey] = {
          patientValue: getFullSex(normalizedPatientSex),
          eligibilityValue: getFullSex(normalizedEligibilitySex)
        };
      }
    } else {
      // Compare other values (ignore empty or undefined values)
      if (patientValue && eligibilityValue && patientValue !== eligibilityValue) {
        differences[patientKey] = {
          patientValue,
          eligibilityValue
        };
      }
    }
  }

  return differences;
};
