import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import ContactType from './Inputs/ContactType';
import FirstName from './Inputs/FirstName';
import LastName from './Inputs/LastName';
import Relationship from './Inputs/Relationship';
import StreetAddress from './Inputs/StreetAddress';
import City from './Inputs/City';
import Province from './Inputs/Province';
import ZipCode from './Inputs/ZipCode';
import Facility from './Inputs/Facility';
import Email from './Inputs/Email';
import EmailType from './Inputs/EmailType';
import Phone from './Inputs/Phone';
import PhoneType from './Inputs/PhoneType';
import AditionalInfo from './Inputs/AditionalInfo';
import CompanyName from './Inputs/CompanyName';
import ActionButtons from './ActionButtons/ActionButtons';

import { representativeStatus } from '../../../modules/config/defaultValuesConfig';

// interface {
//   onSubmit: (formData) => Promise<void> | void;
//   onClose: () => Promise<void> | void;
//   payerInput?: React.ReactNode
// }

const Representative = ({ initialState, onSubmit, onClose, payerInput }) => {
  const clinic = useSelector((state) => state.clinic.details);

  const defaultValues = {
    DCNGuid: clinic.DCNGuid,
    PatientGuid: '',
    RepresentativeGuid: '',
    BirthDay: new Date(),
    Relationship: '',
    CompanyName: '',
    ContactType: 1, // 1 - Person, 2 - Company
    FirstName: '',
    LastName: '',
    AddressLine: '',
    City: '',
    Province: 'BC',
    Zip: '',
    Facility: '',
    Email: '',
    EmailType: 'P',
    Phone: '',
    PhoneType: 'C',
    Comment: '',
    Status: representativeStatus,
    formState: {
      isNew: true
    }
  };

  const methods = useForm({
    defaultValues: initialState ? { ...defaultValues, ...initialState } : defaultValues
  });

  return (
    <FormProvider {...methods}>
      <form id="representative-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-column gap-4">
          <div className="flex flex-column">
            {payerInput && payerInput}

            <ContactType />

            <div className="flex flex-column md:flex-row gap-3">
              <FirstName />
              <LastName />
            </div>

            <Relationship />
            <AditionalInfo />
            <CompanyName />

            <div className="flex flex-column md:flex-row gap-3">
              <StreetAddress />
              <City />
            </div>

            <div className="flex flex-column md:flex-row gap-3">
              <Province />
              <ZipCode />
            </div>

            <Facility />

            <div className="flex flex-column md:flex-row gap-3">
              <Email />
              <EmailType />
            </div>

            <div className="flex flex-column md:flex-row gap-3">
              <Phone />
              <PhoneType />
            </div>
          </div>
        </div>

        <ActionButtons onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default Representative;
