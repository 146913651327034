import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';

import { useSettings } from '../../../hooks/useSettings';
import { usePreferences } from '../../../../../hooks/usePreferences';
import { prefsCodes } from '../../../../../../../../config/prefsCodesConfig';
import { t } from '../../../../../../../../../service/localization/i18n';
import { groupBy } from 'lodash';

const ConsecutiveTime = () => {
  const { setLocalState } = useFormContext();
  const { isConsecutiveTime } = useSettings();
  const { onSavePrefs } = usePreferences();

  const onChange = (e) => {
    const value = e.value ? '1' : '0';

    const preferences = [{ value, label: prefsCodes.consecutiveTime }];

    setLocalState((prevState) => {
      let content = prevState.practitionerPrefs.content || [];
      const groupedContent = groupBy(content, 'label');

      // Iterate through the preferences array
      preferences.forEach((preference) => {
        // If the label doesn't exist in groupedContent, add the preference directly
        if (!groupedContent[preference.label]) {
          content.push(preference);
        } else {
          // Replace items with the existing label
          content = content.map((i) => (i.label === preference.label ? preference : i));
        }
      });

      return {
        ...prevState,
        practitionerPrefs: {
          ...prevState.practitionerPrefs,
          content
        }
      };
    });

    onSavePrefs(preferences);
  };

  return (
    <div className="flex align-items-center justify-content-between">
      <label htmlFor="consecutiveTime">
        {t('Consecutive_time')}
        <Tooltip target=".consecutiveTimeTooltip" position="top">
          {t('Switch_on_to_enter_consecutive_claims')}
        </Tooltip>
        <i className="pi pi-question-circle consecutiveTimeTooltip ml-3" />
      </label>
      <InputSwitch inputId="consecutiveTime" checked={isConsecutiveTime} onChange={onChange} />
    </div>
  );
};

export default ConsecutiveTime;
