import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import cx from 'classnames';

const CommonCard = ({
  title,
  titleClassName,
  children,
  rootClassName,
  iconTooltip,
  footerButtonLable,
  footerButtonOnClick,
  footerContent,
  headerActionButton,
  headerContentLeft,
  showFooter = false,
  isFetching = false, //[KS] - if the data table is the content for CommonCard, then it is imperative to specify the isFetching props, to avoid problems with infinite data load loop (check CMO-987)
  showTitleIcon = false
}) => {
  const { isMobileOnly } = useSelector((state) => state.core.window);

  return (
    <div className={cx('col-12 p-0', rootClassName ? rootClassName : '')}>
      <div className={cx('card p-fluid p-4 m-0')}>
        {/* Header */}
        <div className={titleClassName || 'flex align-items-center justify-content-between pb-4'}>
          {headerContentLeft && <div className={isMobileOnly ? '' : 'ml-2'}>{headerContentLeft}</div>}

          <div className="flex p-0 align-items-center">
            <Tooltip target=".checkMSPQuestion" position="top" />
            <div className="text-xl font-bold">{title}</div>
            {showTitleIcon && (
              <i className="pi pi-question-circle cursor-pointer ml-3 checkMSPQuestion" data-pr-tooltip={iconTooltip ? iconTooltip : ''} />
            )}
          </div>

          {headerActionButton && <div>{headerActionButton}</div>}
        </div>

        {/* Body */}
        <div>{children}</div>

        {/* Footer */}
        {showFooter && (
          <div className={cx('flex justify-content-end p-3')}>
            <Button
              className="p-button-text"
              disabled={isFetching}
              label={footerButtonLable ? footerButtonLable : ''}
              style={{ width: 'max-content' }}
              onClick={footerButtonOnClick ? footerButtonOnClick : undefined}
            />
          </div>
        )}

        {footerContent?.() && <div className={cx('flex justify-content-end p-3')}>{footerContent()}</div>}
      </div>
    </div>
  );
};

export default CommonCard;