import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import HighlightSearchQuery from '../../Misc/HighlightSearchQuery/HighlightSearchQuery';

import { searchForCodes } from '../../../modules/claims/actions/claims.action.creators';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { removeAllSpecialCharacters } from '../../../modules/regex/regex';
import { chain } from 'lodash';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

import './CodePicker.scss';

// interface IPatients {
//   name: string,
//   value: { value: string, label: string }[],
//   onChange: () => void,
//   searchParams: {
//      getCodesFor: "teleplan" | "private" | "eclaim" | "appt" // required param to define which request should be done
//      practitionerGuid?: string // Need for services "getClinicServices"
//      catalogType?: "tec" | "pbc" // Need for eClaims "getEClaimsServices"
//      codeType?: "feecodes" | "icd9" | "aoi" | "noi" | "referral", // Need for teleplan codes "getCodesByType"
//      type?: "MSP" | "WSBC" | "ICBC" | "S" | "SP" | "D" // Need for teleplan codes (getCodesByType) or for services (getClinicServices)
//      query?: string, // Search value
//      speciality?: string
//      page?: number,
//      pageSize?: number,
//      sortby?: string
//   },

//   maxEntry?: number,
//   id?: string,
//   inputId?: string,
//   className?: string,
//   placeholder?: string,
//   catalogsButtonTooltip?: string,
//   disabled?: boolean,
//   groupLabel?: boolean,
//   isInvalid?: boolean,
//   showCatalogsButton?: boolean,
//   itemTemplate?: (code) => string, // Template of a list item
//   selectedItemTemplate?: (code) => string, // Template of a selected item
//   selectedItemKey?: string, // Key to get property from item object and display this value as selected
//   onBlur?: () => void,
//   onFocus?: () => void,
//   toggleCatalogs?: () => void,
//   selectedItemTemplateClass?: (string) => string,
// };

const CodePicker = React.forwardRef(({ groupLabel = true, ...props }, ref) => {
  const name = props.name;
  const inputId = props.inputId || `${name}_input`;
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);
  const disabled = props.disabled;
  const speciality = props.speciality || '0';
  const placeholder = props.maxEntry === props.value?.length || disabled ? ' ' : props.placeholder || t('Search_for_codes');

  // className for cypress tests
  const classNameForTests = cx('block', props.isInvalid ? 'p-invalid' : '', props.className ? props.className : '');
  const classNameForLive = cx('block', props.isInvalid ? 'p-invalid' : '', props.className ? props.className : '', {
    // code_picker_autocomplete_single_code:
    //   (props.maxEntry === 1 || props.value?.length === props.maxEntry) && (props.value?.length === 1 || props.value?.length === props.maxEntry)
  });
  const className = process.env.REACT_APP_CYPRESS ? classNameForTests : classNameForLive;

  const getSuggestions = async (query) => {
    setQuery(query);

    const results = await searchForCodes({
      practitionerGuid: props.searchParams?.practitionerGuid,
      catalogType: props.searchParams?.catalogType,
      getCodesFor: props.searchParams?.getCodesFor,
      codeType: props.searchParams?.codeType,
      type: props.searchParams?.type,
      query,
      speciality
    });

    if (results) getSuggestionsResponse(results);
  };

  const getSuggestionsResponse = (response) => {
    setIsSuggestionsFound(response.length > 0);

    if (!groupLabel) return setSuggestions([...response]);

    const grouped = chain([...response])
      .groupBy('category_label')
      .value();
    const itemsList = Object.keys(grouped).map((i) => ({ label: i, code: i, items: grouped[i] }));
    setSuggestions(itemsList);
  };

  const formatCodeItem = (code) => {
    const label = props?.itemTemplate ? props.itemTemplate(code) : code.label;
    return <HighlightSearchQuery query={query} text={label} />;
  };

  const selectedItemTemplate = (code) => {
    const formattedCode = code?.value?.replaceAll(removeAllSpecialCharacters, '');
    const id = `${inputId}_${code.value?.replaceAll('.', '')}`;
    const className = cx(
      props.selectedItemTemplateClass ? props.selectedItemTemplateClass(code.value?.trim()) : '',
      `codeAutocompleteTooltip_${formattedCode}`
    );

    const selectedItemKey = props?.selectedItemKey || 'value';
    const itemTemplate = props?.selectedItemTemplate ? props.selectedItemTemplate(code) : code[selectedItemKey];

    return (
      <>
        <Tooltip target={`.codeAutocompleteTooltip_${formattedCode}`} position="top">
          {code.text || code.label}
        </Tooltip>
        <div id={id} className={className} style={{ color: 'black' }}>
          {itemTemplate || code?.[selectedItemKey]}
        </div>
      </>
    );
  };

  const groupedItemTemplate = (item) => {
    return (
      <div className="p-d-flex p-ai-center country-item">
        <div className="font-bold text-lg text-color">{item.label?.toUpperCase()}</div>
      </div>
    );
  };

  const onChange = (e) => {
    !isSuggestionsFound && setIsSuggestionsFound(true);
    props.onChange(e);
  };

  // const onKeyPress = (e) => {
  //   if (e.key === 'Enter' && suggestions?.length && groupLabel) {
  //     props.onChange({ value: [suggestions[0]?.items[0]] });
  //   }
  // };

  return (
    <div className={props.showCatalogsButton ? 'code_picker_autocomplete_with_catalogs_button' : ''}>
      <AutoComplete
        id={props.id || name}
        inputId={inputId}
        inputRef={ref}
        type="text"
        className={className}
        inputClassName="w-full"
        style={props.style ? props.style : {}}
        panelStyle={{ maxWidth: '200px' }}
        name={name}
        value={props.value}
        suggestions={suggestions}
        placeholder={placeholder}
        forceSelection={!props.value?.length}
        disabled={disabled}
        delay={1000}
        field="label"
        optionGroupLabel={groupLabel ? 'label' : ''}
        optionGroupChildren={groupLabel ? 'items' : ''}
        optionGroupTemplate={groupedItemTemplate}
        // dropdown
        multiple
        autoHighlight
        scrollHeight={dropdownScrollHeight()}
        itemTemplate={formatCodeItem}
        selectedItemTemplate={selectedItemTemplate}
        completeMethod={(e) => getSuggestions(e.query)}
        onChange={onChange}
        onBlur={props.onBlur}
        onFocus={() => (props.onFocus ? props.onFocus(name) : undefined)}
        onKeyDown={(e) => e.stopPropagation()} // VER-599 - Batch->autocomplete fields->allow the user to use keyboard to select from the dropdown
        // onKeyPress={onKeyPress}
      />

      {props.showCatalogsButton && (
        <Button
          id="openCatalogsAutocompleteButton"
          className="p-button-text"
          type="button"
          icon="pi pi-search pointer"
          disabled={disabled}
          tooltip={props.catalogsButtonTooltip}
          tooltipOptions={{ position: 'top', showDelay: 750 }}
          onClick={props.toggleCatalogs}
        />
      )}

      {!isSuggestionsFound && !props.value?.length && <span className="p-error text-sm">{t('No_results_found')}</span>}
    </div>
  );
});

export default CodePicker;
