import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../Inputs';

import { email_types } from '../../../../modules/common/contants';
import { t } from '../../../../service/localization/i18n';

const EmailType = () => {
  const name = 'EmailType';
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const options = email_types.map((type) => ({ name: type.label, code: type.value }));
        const value = options.find((type) => type.code === field.value);
        return (
          <InputWrapper name={name} label={t('Email Type')}>
            <Dropdown value={value} optionLabel="name" options={options} onChange={(e) => field.onChange(e.value.code)} />
          </InputWrapper>
        );
      }}
    />
  );
};

export default EmailType;
