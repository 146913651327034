import React from 'react';
import { useSelector } from 'react-redux';
import AddressLayout from '../../../common/components/AddressLayout';
import CommonAddressForm from '../../../common/components/AddressForm/CommonAddressForm';
import { t } from '../../../../service/localization/i18n';

const PrivateAddress = ({
  value,
  label,
  showInDialog = false,
  onSave,
  additionalInputs,
  additionalAddressData,
  showAddressDialog,
  setShowAddressDialog
}) => {
  const { clinicEditing, practitionerEditing } = useSelector((state) => state.clinic);
  const { isSavingPatientDetails } = useSelector((state) => state.patients);

  return (
    <>
      <div className="flex align-items-center">
        <AddressLayout data={value} additionalAddressData={additionalAddressData} />
      </div>

      <CommonAddressForm
        header={`${t('Edit')} ${label}`}
        visible={showAddressDialog}
        showInDialog={showInDialog}
        formikInitValues={value}
        isFetching={clinicEditing || practitionerEditing || isSavingPatientDetails}
        additionalInputs={additionalInputs}
        onSave={onSave}
        onClose={() => setShowAddressDialog(false)}
      />
    </>
  );
};

export default PrivateAddress;
