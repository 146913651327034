import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { InputWrapper } from '../../../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

import { useValidate } from '../hooks/useValidate';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { t } from '../../../../../service/localization/i18n';

const UsernameStep = () => {
  const name = 'username';
  const secureFieldName = 'secure';
  const { onSignIn, usernameValidation } = useValidate();
  const { control, formState, signInState, clearErrors, watch, setValue } = useFormContext();
  const errorMessage = formState.errors?.username?.message;
  const secureChecked = watch(secureFieldName, true);
  const inputRef = useRef(null);

  useEffect(() => {
    const savedUsername = localStorage.getItem(localStorageKeys.username);
    if (savedUsername) {
      setValue('username', savedUsername);

      // Focus and select the input text
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select();
        }
      }, 0);
    }
  }, [setValue]);

  const onChange = (e, field) => {
    field.onChange(e);
    errorMessage && clearErrors(name);
  };

  return (
    // <div>Tests</div>
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: (value) => usernameValidation(value) }}
        render={({ field }) => (
          <InputWrapper className="h-auto" name={name} label="Username" errorMessage={errorMessage}>
            <InputText
              type="text"
              id={elementIDs.usernameInput}
              placeholder="Username"
              // autoFocus
              className={errorMessage ? 'p-invalid' : ''}
              {...field}
              ref={inputRef}
              onInput={(e) => onChange(e, field)}
            />
          </InputWrapper>
        )}
      />

      {!secureChecked && <Tooltip target=".disabled-button" position="top" />}

      <span className="disabled-button" data-pr-tooltip={`Please, check 'Confirm secure connection'`}>
        <Button
          id={elementIDs.logInButton}
          type="button"
          className="h-3rem mt-3"
          label={t('Next')}
          loading={signInState.isSubmitting}
          onClick={onSignIn}
          disabled={!secureChecked}
        />
      </span>

      <div className="flex justify-content-center align-items-center mt-4">
        <Controller
          name={secureFieldName}
          control={control}
          defaultValue={true}
          render={({ field }) => (
            <>
              <Checkbox
                id="secureConnectionCheckbox"
                inputId="secure"
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <label htmlFor="secure" className="ml-2">
                {t('ConfirmSecurityConnection')}
              </label>
            </>
          )}
        />
      </div>
      <div className="font-medium text-500 m-0 mt-4">{secureChecked ? t('ConfirmSecurityConnectionText') : t('UnconfirmSecurityConnectionText')}</div>
    </>
  );
};

export default UsernameStep;
