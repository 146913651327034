import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../../../components/Inputs';
import { Dropdown } from 'primereact/dropdown';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { onBillToUpdate } from '../claimDetailsHelpers/onClaimUpdate';
import { representativeStatus } from '../../../../config/defaultValuesConfig';
import { t } from '../../../../../service/localization/i18n';

const Payer = () => {
  const name = 'Payer';
  const dispatch = useDispatch();
  const { control, getValues, setValue } = useFormContext();
  const isRepresentativeExist = getValues('formState.isRepresentativeExist');

  const options = ['Patient', ...(isRepresentativeExist ? ['Representative'] : [])];

  const onChange = (value, field) => {
    const formState = { ...getValues('formState'), isPayerPatient: value === 'Patient' };
    const updateValues = value === 'Patient' ? formState.patient : formState.representative;

    // Update form values without resetting defaults
    Object.entries(updateValues).forEach(([key, val]) => {
      setValue(key, val, { shouldDirty: true });
    });

    if (value === 'Representative') {
      setValue('Status', representativeStatus, { shouldDirty: true });
    }

    setValue('formState', formState, { shouldDirty: true });
    setValue(name, value, { shouldDirty: true });

    const billToData = value === 'Patient' ? [formState.patient] : [formState.representative];
    onBillToUpdate(billToData, dispatch);

    field.onChange(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Payer')}>
          <Dropdown
            autoFocus
            value={field.value}
            scrollHeight={dropdownScrollHeight()}
            options={options}
            onChange={(e) => onChange(e.value, field)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default Payer;
