import actions from './patients.action.types';
import axios from 'axios';
import qs from 'qs';
import { getApiBase, setAuthHeader } from '../../../config';
import { setEligibilityError, setToastMessage } from '../../core/actions/core.action.creators';
import { formatPatientDetails } from '../helpers/formatPatientDetails';
import { getAge } from '../../utils/getAge';
import { decodeText } from '../../utils/decodeText';
import * as responseActions from './responseActions';
import { t } from '../../../service/localization/i18n';
import { store } from '../../../store';
import { getTotalUnreadNotes } from '../../notes/actions/actions';

const dispatchError = (type, error) => {
  return {
    type,
    results: error
  };
};

const extractPhoneTypes = (x) => {
  var obj = /([chbo])/.exec(x.Phone);
  if (x.Phone && obj && obj[0]) {
    x.PhoneType = x.Phone.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  obj = /([chbo])/.exec(x.Phone2);
  if (x.Phone2 && obj && obj[0]) {
    x.PhoneType2 = x.Phone2.substr(obj.index, 3).replace('(', '').replace(')', '');
  }
  obj = /([chbo])/.exec(x.Phone3);
  if (x.Phone3 && obj && obj[0]) {
    x.PhoneType3 = x.Phone3.substr(obj.index, 3).replace('(', '').replace(')', '');
  }

  return x;
};

const formatPatientsList = (results) => {
  const formattedPhoneTypes = results.data.patientList.map((x) => {
    return extractPhoneTypes(x);
  });

  const updatedList = formattedPhoneTypes?.map((item, index) => {
    return formatPatientDetails(item, index);
  });

  const _filters = JSON.parse(results.data.query);
  delete _filters.review;

  return {
    ...results.data,
    first: (results.data.page - 1) * results.data.pageSize + 1,
    filters: _filters,
    patientList: updatedList
  };
};

const formatNotes = (results) => {
  // decode Note here
  const formatted = results.data.notesList.map((x) => {
    x.Comments = decodeText(x.Comments);
    return x;
  });

  return {
    type: actions.FETCH_PATIENT_NOTES_SUCCESS,
    results: {
      data: {
        PatientGuid: results.data.PatientGuid,
        notesList: formatted,
        page: results.data.page,
        pageSize: results.data.pageSize,
        totalRecords: results.data.totalRecords,
        totalPages: results.data.totalPages
      }
    }
  };
};

const formatDetails = (details_type, results) => {
  return {
    type: details_type,
    results: results
  };
};

export const getPatientsWithMissingData = (callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.IS_FETCHING_PATIENTS_WITH_MISSING_DATA, results: true });

  axios
    .get(`${getApiBase()}/patients/${DCNGuid}`)
    .then((results) => {
      dispatch({ type: actions.IS_FETCHING_PATIENTS_WITH_MISSING_DATA, results: false });
      const formattedPhoneTypes = results.data.map((x) => {
        return extractPhoneTypes(x);
      });
      const updatedList = formattedPhoneTypes?.map((item, index) => {
        return formatPatientDetails(item, index);
      });
      callback && callback(updatedList);
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_PATIENTS_WITH_MISSING_DATA, results: false });
      return dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatients =
  ({ filters, sortBy, page, pageSize }) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState?.user?.details?.DCNGuid;
    const newFilters = JSON.stringify(filters || currentState.patients.filters);
    const currentPageSize = pageSize ? pageSize : currentState.patients.pageSize;
    const sortby = sortBy || currentState.patients.sortby;
    const currentPage = () => {
      if (page >= 0) {
        return page + 1;
      } else {
        if (currentState.patients.page === 0) return 1;
        return currentState.patients.page;
      }
    };

    const query = (sortby ? `&sortby=${sortby}` : '') + `&q=${newFilters}`;
    const url = `${getApiBase()}/patients/${DCNGuid}/${currentPage()}/${currentPageSize}/?${query}`;

    dispatch({ type: actions.IS_FETCHING_PATIENTS, results: true });
    axios
      .get(url)
      .then((results) => {
        dispatch({ type: actions.IS_FETCHING_PATIENTS, results: false });
        return dispatch({
          type: actions.GET_PATIENTS,
          results: formatPatientsList(results)
        });
      })
      .catch((reason) => {
        dispatch({ type: actions.IS_FETCHING_PATIENTS, results: false });
        return dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
      });
  };

export const deletePatient =
  (patientGuid, confirm = false, callback, getPatientsList = true) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState?.user?.details?.DCNGuid;

    dispatch({ type: actions.IS_DELETING_PATIENT, results: true });

    axios
      .delete(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}/?&confirm=${confirm}`)
      .then((results) => {
        dispatch({ type: actions.IS_DELETING_PATIENT, results: false });
        callback && callback(results);
        if ((confirm || results?.data?.ResponseCode === 204) && getPatientsList) {
          dispatch(getPatients({}));
          dispatch(setToastMessage({ type: 'success', message: t('Patient_deleted_successfully') }));
        }
      })
      .catch((reason) => {
        dispatch({ type: actions.IS_DELETING_PATIENT, results: false });
        dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
      });
  };

export const setPatientFilters = (value, filterKey) => (dispatch, state) => {
  const currentState = state();
  let combined = filterKey ? { ...currentState.patients.filters, [filterKey]: value } : value;
  dispatch({
    type: actions.UPDATE_FILTERS,
    results: combined
  });
};

export const setPatientIdForHighlightInTable = (patient) => {
  return {
    type: actions.SET_PATIENT_ID_FOR_HIGHTLIGHT_IN_TABLE,
    results: patient
  };
};

export const setPatientInfo = (patient) => {
  return {
    type: actions.SET_PATIENT_INFO,
    results: patient
  };
};

export const updatePHN = (data, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.IS_UPDATING_PHN, results: true });

  axios
    .put(`${getApiBase()}/patients/${DCNGuid}/${data?.PatientGuid}/phn`, data)
    .then(() => {
      dispatch({ type: actions.IS_UPDATING_PHN, results: false });
      callback && callback();
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_UPDATING_PHN, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const getPatientInfo =
  ({ id, callback, showLoader = true }) =>
  (dispatch, state) => {
    // Mock data for tests
    if (process.env.REACT_APP_CYPRESS) {
      return responseActions.getPatientInfoResponseActionForTests(id, callback);
    }

    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState?.user?.details?.DCNGuid;

    showLoader && dispatch({ type: actions.IS_FETCHING_PATIENTS, results: true });

    axios
      .get(`${getApiBase()}/patients/${DCNGuid}/${id}`)
      .then((results) => {
        showLoader && dispatch({ type: actions.IS_FETCHING_PATIENTS, results: false });
        return responseActions.getPatientInfoResponseAction(results, callback);
      })
      .catch((reason) => {
        showLoader && dispatch({ type: actions.IS_FETCHING_PATIENTS, results: false });
        dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
        callback?.(null, true);
      });
  };

export const getPatient = (patientGuid) => {
  if (process.env.REACT_APP_CYPRESS) {
    const mockPatient = responseActions.getPatientInfoForTests(patientGuid);
    return Promise.resolve(mockPatient);
  }

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}`)
    .then((responce) => {
      return formatPatientDetails(responce.data);
    })
    .catch((reason) => {
      console.log('!!!getPatient', reason);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getRepresentative = (patientGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}`)
    .then((responce) => {
      return formatPatientDetails(responce.data);
    })
    .catch((reason) => {
      console.log('!!!getRepresentative', reason);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const createRepresentative = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/patients/${DCNGuid}/new`, payload)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!createRepresentative', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const editRepresentative = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/patients/${DCNGuid}/${payload.PatientGuid}`, payload)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!editRepresentative', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const resetCurrent = () => (dispatch) => {
  return dispatch(formatDetails(actions.FETCH_PATIENT_DETAILS_SUCCESS, { data: null }));
};

export const setCurrent = (details) => (dispatch) => {
  return dispatch(formatDetails(actions.FETCH_PATIENT_DETAILS_SUCCESS, { data: details }));
};

export const createPatient = (patient, callback) => (dispatch, state) => {
  if (!patient.BirthDay) return dispatch(setToastMessage({ type: 'error', message: 'Birth date is required' })); // WIN-503 - Investigate FE bug in Prod: BirthDay:null for new patient

  if (process.env.REACT_APP_CYPRESS) return responseActions.createPatientResponseActionForTests(patient.PatientGuid, callback);

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : '';
  patient.FirstName = patient.FirstName.toUpperCase();
  patient.LastName = patient.LastName.toUpperCase();
  patient.PatientGuid = patient.PatientGuid || DCNGuid;
  patient.PHN = patient.PHN?.replaceAll(' ', '').replaceAll('_', '');

  dispatch({ type: actions.IS_CREATING_PATIENT, results: true });

  axios
    .post(`${getApiBase()}/patients/${DCNGuid}/new`, patient)
    .then((results) => {
      dispatch({ type: actions.IS_CREATING_PATIENT, results: false });
      responseActions.createPatientResponseAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_CREATING_PATIENT, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const setEligibility = (data) => {
  return {
    type: actions.SET_ELIGIBILITY,
    results: data
  };
};

export const getEligibility = (data, callback, isAutofillProcess = false, setShowImage, formik) => (dispatch, state) => {
  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.getEligibilityActionForTests(data, callback);
  }

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : '';
  const type = data.eligibilityOnly
    ? actions.CHECK_ELIGIBILITY_ONLY
    : data.nameAndEligibility
    ? actions.CHECK_NAME_AND_ELIGIBILITY
    : actions.IS_FETCHING_PATIENT_ELIGIBILITY;
  const dataForRequest = data;
  dataForRequest.PHN = dataForRequest.PHN?.replaceAll(' ', '').replaceAll('_', '');
  delete dataForRequest.eligibilityOnly;
  delete dataForRequest.nameAndEligibility;

  dispatch({ type, results: true });

  axios
    .post(`${getApiBase()}/patients/${DCNGuid}/eligibility`, dataForRequest)
    .then((results) => {
      dispatch({ type, results: false });
      if(isAutofillProcess) {
        return responseActions.getEligibilityPHNActionAutofill(results.data, setShowImage, formik, callback);
      }
      else {
        return responseActions.getEligibilityPHNAction(results.data, callback);
      }
    })
    .catch((reason) => {
      const originalError = reason?.response?.data?.ExceptionMessage || null;

      // Dispatch the eligibility error with the captured message
      dispatch(setEligibilityError({error: originalError}));

      dispatch({ type, results: false });
      // Check if it's an autofill process
      if (isAutofillProcess) {
        setShowImage(true);
        if(formik) {
        formik.handleSubmit();
        }
        return dispatch(
          setErrorMessageAutofill({
            type: 'error',
            message: 'Name not recognized. Please enter it manually.',
            originalMSPErrorMessage: reason?.response?.data?.ExceptionMessage || 'An unexpected error occurred.',
          })
        );
      }
      // Otherwise, use the specific error message
      return dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage || 'An unexpected error occurred.',
        })
      );
    });
};

export const setErrorMessageAutofill = ({ severity = 'warn', message = '', originalMSPErrorMessage='' }) => {
  return {
    type: actions.SET_ERROR_MESSAGE_AUTOFILL,
    results: { severity, message, originalMSPErrorMessage }
  };
};

export const setShowNewPatientSuccessPage = (value) => {
  return {
    type: actions.SHOW_NEW_PATIENT_SUCCESS_PAGE,
    results: value
  };
};

export const editPatient =
  (patient, callback = false) =>
  (dispatch, state) => {
    if (!patient.BirthDay) return dispatch(setToastMessage({ type: 'error', message: 'Birth date is required' })); // WIN-503 - Investigate FE bug in Prod: BirthDay:null for new patient

    const payloadData = {
      ...patient,
      AddressLine: patient.AddressLine?.trim(),
      FullAddress: patient.FullAddress?.trim(),
      FullName: patient.FullName?.trim()
    };

    if (process.env.REACT_APP_CYPRESS) return responseActions.createPatientResponseActionForTests(payloadData.PatientGuid, callback);

    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : '';
    payloadData.PHN = payloadData.PHN?.replaceAll(' ', '').replaceAll('_', '');

    dispatch({ type: actions.SAVING_PATIENT_DETAILS, results: true });

    axios
      .put(`${getApiBase()}/patients/${DCNGuid}/${payloadData.PatientGuid}`, payloadData)
      .then((results) => {
        dispatch({ type: actions.SAVING_PATIENT_DETAILS, results: false });
        const formattedData = {
          ...results.data,
          Age: getAge(results?.data.BirthDay),
          FullAddress: `${results?.data.AddressLine} ${results?.data.City} ${results?.data.Province} ${results?.data.Zip}`
        };
        callback && callback(formattedData);
        return dispatch(formatDetails(actions.EDIT_PATIENT_SUCCESS, formattedData));
      })
      .catch((reason) => {
        dispatch({ type: actions.SAVING_PATIENT_DETAILS, results: false });
        return dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
      });
  };

export const setDefaultPatientsTableValues = () => {
  return {
    type: actions.SET_PATIENTS_DEFAULT_TABLE_VALUES
  };
};

export const setupAutoComplete = (autocomplete_params) => (dispatch, state) => {
  setAuthHeader(state);
  const newState = {
    type: actions.SETUP_PATIENTS_AUTOSUGGEST,
    results: autocomplete_params
  };
  return dispatch(newState);
};

export const verifyAccessWord = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${payload.patientGuid}/paw?word=${payload.AccessWord}`)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!verifyAccessWord', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getAllPatientNotes = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, freetext = '', query = {} } = payload;
  const queryParams = new URLSearchParams();
  query && queryParams.set('q', JSON.stringify(query));

  const baseUrl = `${getApiBase()}/notes/${DCNGuid}/patients/${page}/${pageSize}`;
  const url = new URL(baseUrl);
  url.search = queryParams.toString();

  if (freetext) {
    const queryParams = new URLSearchParams(url.search);
    queryParams.set('q', JSON.stringify({ freetext: freetext }));
    url.search = queryParams.toString();
  }

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getAllPatientNotes', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getPatientNoteDetails = (id) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .get(`${getApiBase()}/notes/${DCNGuid}/patients/${id}`)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getPatientNoteDetails', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getPatientNotes = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, patientGuid, freetext = '' } = payload;

  const baseUrl = `${getApiBase()}/notes/${DCNGuid}/patients/${patientGuid}/${page}/${pageSize}`;
  const url = new URL(baseUrl);

  if (freetext) {
    const queryParams = new URLSearchParams(url.search);
    queryParams.set('q', JSON.stringify({ freetext: freetext }));
    url.search = queryParams.toString();
  }

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getPatientNotes', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const createPatientNote = (note) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/notes/${DCNGuid}/patients`, note)
    .then((results) => {
      getTotalUnreadNotes();
      return results.data;
    })
    .catch((error) => {
      console.log('!!!createPatientNote', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const editPatientNote = (note) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/notes/${DCNGuid}/patients/${note.NoteGuid}`, note)
    .then((results) => {
      getTotalUnreadNotes();
      return results.data;
    })
    .catch((error) => {
      console.log('!!!editPatientNote', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const deletePatientNote = (noteGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .delete(`${getApiBase()}/notes/${DCNGuid}/${noteGuid}`)
    .then(() => {
      getTotalUnreadNotes();
      return true;
    })
    .catch((error) => {
      console.log('!!!deletePatientNote', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const setActiveTab = (tabindex) => (dispatch, state) => {
  return dispatch({
    type: actions.SET_ACTIVE_PATIENT_TAB,
    results: tabindex
  });
};

// [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
export const validatePHN = (value, callback) => (dispatch, state) => {
  setAuthHeader(state);
  if (value?.length > 0) {
    const phn = value?.replaceAll(' ', '')?.replaceAll('_', '');

    // Mock data for tests
    if (process.env.REACT_APP_CYPRESS) {
      return responseActions.validatePHNActionForTests(phn, callback);
    }

    dispatch({ type: actions.IS_FETCHING_PHN_VALIDATION, results: true });

    axios
      .get(`${getApiBase()}/validate/phn/${phn}`)
      .then((results) => {
        dispatch({ type: actions.IS_FETCHING_PHN_VALIDATION, results: false });
        responseActions.validatePHNAction(results.data.result, callback);
      })
      .catch((reason) => {
        callback(true);
        dispatch({ type: actions.IS_FETCHING_PHN_VALIDATION, results: false });
        return dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
      });
  } else {
    callback(false);
  }
};

export const setFile = (file) => ({
  type: actions.SET_UPLOADED_FILE,
  payload: file,
});

export const duplicatePatientCheck = (patientData, callback) => (dispatch, state) => {
  patientData.PHN = patientData.PHN?.replaceAll(' ', '')?.replaceAll('_', '');

  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.duplicatePatientCheckctionForTests(patientData, callback);
  }

  setAuthHeader(state);
  const DCNGuid = state()?.user?.details?.DCNGuid;

  dispatch({ type: actions.PATIENTS_DUPLICATE_CHECKING, results: true });

  axios
    .post(`${getApiBase()}/patients/${DCNGuid}/check`, patientData)
    .then((results) => {
      dispatch({ type: actions.PATIENTS_DUPLICATE_CHECKING, results: false });
      return responseActions.duplicatePatientCheckAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch({ type: actions.PATIENTS_DUPLICATE_CHECKING, results: false });
      return dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const setActiveTabIndexForPatientForm = (index) => (dispatch) => {
  return dispatch({
    type: actions.PATIENT_FORM_ACTIVE_TAB_INDEX,
    results: index
  });
};

const downloadImageFromUrl = async (imageUrl) => {
  try {
    // Fetch the image as a Blob
    const response = await fetch(imageUrl);
    if (!response.ok) throw new Error('Failed to fetch image');
    const blob = await response.blob();

    // Create a temporary link element
    // const downloadUrl = URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = downloadUrl;
    // link.download = 'downloaded_image.jpg'; // Set a default filename

    // // Append the link to the document, click it, and remove it
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // // Release the Blob URL
    // URL.revokeObjectURL(downloadUrl);
    return blob;
  } catch (error) {
    console.error('Error downloading the image:', error);
  }
};

export const uploadImage = async (DCNGuid, image, sessionGuid) => {
  try {

    const imageFile = await downloadImageFromUrl(image);
    
    // Create a FormData object to hold the binary image file
    const formData = new FormData();
    formData.append('file', imageFile); // Assumes `imageFile` is a binary file (Blob or File)

    // Construct the URL with path and query parameters
    const url = `${getApiBase()}/images/${DCNGuid}?SessionId=${sessionGuid}`;

    // Make the request
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      console.log('Image successfully sent to the API');
      console.log('Response data:', response.data); // Handle or log the response as needed
      return response.data;
    } else {
      console.error('Failed to upload image:', response.statusText);
      return null;
    }
  } catch (uploadError) {
    console.error('Error during image upload:', uploadError);
    return null;
  }
};

