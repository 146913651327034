import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidateGoupPicker } from '../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../hooks/useGroupPickerInputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';
import { steps } from '../../../helpers/steps';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';
import { setBatchFormToLocalStorage } from '../../../helpers/formToLocalStorage';

const GroupReferralPickerActionButtons = () => {
  const history = useHistory();
  const { isReferralValid, isCommentStepRequired } = useValidateGoupPicker();
  const { localState, setLocalState, getValues } = useFormContext();
  const { onRefToByChange } = useGroupPickerInputs();
  const isRequiredComment = isCommentStepRequired();

  const onBack = () => {
    // Commented because of VER-241
    // if (isReferralValid()) {
    //   // Go to dx step
    //   setLocalState(prevState => ({ ...prevState, step: steps.groupDxPicker }));
    // };

    // VER-241 - Batch -> allow user to go back from referral page if the mandatory referrals are missing
    setLocalState((prevState) => ({ ...prevState, step: steps.groupDxPicker }));
    // Due to VER-241 we need to reset all RefToBy values to "N" if referral code is missing for this row
    localState?.groupRecords?.forEach((record, index) => {
      const referral = record?.[inputs.referral.codeType];
      const refToBy = record?.[inputs.refToBy.name];
      if (!referral?.length && refToBy !== 'N') {
        onRefToByChange('N', { rowData: record, rowIndex: index });
      }
    });
  };

  const onNext = () => {
    if (isReferralValid()) {
      // Go to preview step
      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step: steps.groupPreview, prevStep: prevState.step };
        // Save the updated form state to the local storage
        // setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  const onAddNote = () => {
    if (isReferralValid()) {
      // Go to comments step
      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step: steps.groupComments, prevStep: prevState.step };
        // Save the updated form state to the local storage
        // setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  return (
    <div className="flex gap-3">
      {!isRequiredComment && (
        <Button
          id={elementIDs.review}
          type="button"
          className="recordsDataTableActionButtons"
          label={t('Finish')}
          disabled={!localState.groupRecords?.length}
          onClick={onNext}
        />
      )}

      <Button
        id={elementIDs.next}
        type="button"
        className={cx('recordsDataTableActionButtons', isRequiredComment ? '' : 'p-button-outlined')}
        label={t('Add_Comments')}
        disabled={!localState.groupRecords?.length}
        onClick={onAddNote}
      />

      <Button id={elementIDs.back} type="button" className="p-button-outlined recordsDataTableActionButtons" label={t('Back')} onClick={onBack} />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default GroupReferralPickerActionButtons;
