import React, { useEffect } from 'react';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { TabPanel, TabView } from 'primereact/tabview';

import Chips from '../Modules/Chips/Chips';
import Categories from '../Modules/Categories/Categories';
import CatalogsSearch from '../Modules/Search/CatalogsSearch';
import CatalogsDataScroller from '../Modules/DataTable/CatalogsDataScroller';

import { useCatalogsContext } from '../hooks/useCatalogsContext';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { commonInputs } from '../../../modules/config/commonInputsConfig';
import { useCatalogContentSizeMobile } from '../hooks/useCatalogContentSizeMobile';
import { useCategories } from '../hooks/useCategories';
import { useTabs } from '../hooks/useTabs';
import { useActions } from '../hooks/useActions';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

const SidebarHeader = () => {
  const { onCloseMobileCatalogs } = useActions();
  const { catalogsState, setCatalogsState, searchRef } = useCatalogsContext();

  useEffect(() => {
    setCatalogsState((prevState) => ({ ...prevState, showSearch: true }));
    searchRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClick = () => {
    if (!catalogsState.showSearch) {
      setCatalogsState((prevState) => ({ ...prevState, showSearch: true }));
      searchRef?.current?.focus();
      return;
    }

    setCatalogsState((prevState) => ({ ...prevState, showSearch: false }));
  };

  return (
    <div className="flex align-items-center justify-content-between w-full gap-2">
      <Button id={elementIDs.closeSidebarButton} className="p-button-rounded p-button-text" icon="pi pi-arrow-left" onClick={onCloseMobileCatalogs} />

      <>
        <h5 id={elementIDs.mobileCatalogsTitle} className="m-0">
          {catalogsState.activeTab?.label}
        </h5>

        {catalogsState.activeTab?.codeType !== commonInputs.mostRecent.codeType && (
          <div className={catalogsState.showSearch ? 'searchInputWrapActive' : 'searchInputWrapHidden'}>
            <CatalogsSearch />
          </div>
        )}
      </>

      {catalogsState.activeTab?.hideSearchForMobileView ? (
        <div className="w-2rem"></div>
      ) : (
        <Button
          id={elementIDs.mobileCatalogsSearchIcon}
          className="p-button-rounded p-button-text"
          icon={catalogsState.showSearch ? 'pi pi-times' : 'pi pi-search'}
          onClick={onSearchClick}
        />
      )}
    </div>
  );
};

const CodeCatalogs = () => {
  const { onSubTabChange } = useTabs();
  const { setInitCategoriesList } = useCategories();
  const { onDataScrollerBackButtonClick } = useActions();
  const { catalogsState, setCatalogsState } = useCatalogsContext();
  const activeIndex = catalogsState.activeSubTabIndex;
  const currentCategory = catalogsState.currentCategory;
  const sizes = useCatalogContentSizeMobile();

  //set menu model
  useEffect(() => {
    setInitCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const onHome = () => {
    return;
  };

  // Custom tab layout
  if (catalogsState.activeTab?.tabLayout) {
    return (
      <div id={`${catalogsState.activeTab?.name}_mobileCatalogContent`} className="h-full">
        <div className="flex justify-content-between align-items-center" style={{ minHeight: sizes.invoicesCatalogsChips }}>
          <div className="flex mt-2 w-full" style={{ overflowX: 'auto' }}>
            <Chips />
          </div>
        </div>

        {catalogsState.activeTab?.tabLayout({ catalogsState, setCatalogsState, scrollHeight: sizes.scrollableTableHeight })}
      </div>
    );
  }

  return (
    <TabView className="h-full catalog_tab_view" activeIndex={activeIndex} onTabChange={onSubTabChange}>
      {currentCategory?.tabs?.map((i, index) => {
        return (
          <TabPanel className="h-full" header={i.label} key={index}>
            <div id={`${catalogsState.activeTab?.name}_mobileCatalogContent`} className="h-full">
              <div className="flex justify-content-between align-items-center" style={{ minHeight: sizes.invoicesCatalogsChips }}>
                <div className="flex mt-2 w-full" style={{ overflowX: 'auto' }}>
                  <Chips />
                </div>
              </div>

              <div className="breadcrumbMobile">
                <BreadCrumb
                  id={`${catalogsState.activeTab?.name}_catalogBreadcrumb`}
                  model={catalogsState.trail}
                  home={{ icon: 'pi pi-home', command: () => onHome() }}
                />
              </div>

              <div className="mobileCatalogsContentRoot">
                <div
                  id={`${catalogsState.activeTab?.name}_catalogCategories`}
                  className={catalogsState.mobileContent === 'menu' ? 'showMobileSlideMenu' : 'hideMobileSlidemenu'}
                >
                  <Categories menuWidth={sizes.menuWidth} menuHeight={sizes.menuHeight} />
                </div>

                <div className={catalogsState.mobileContent === 'table' ? 'showMobileDataScroller' : 'hideMobileDataScroller'}>
                  <div id={`${catalogsState.activeTab?.name}_catalogTable`} className="w-full">
                    <CatalogsDataScroller scrollableTableHeight={sizes.scrollableTableHeight} />
                  </div>

                  <div
                    className={catalogsState.mobileContent === 'table' ? 'showDataScrollertableFooter' : 'hideDataScrollertableFooter'}
                    style={{ height: sizes.mobileFooter }}
                    onClick={onDataScrollerBackButtonClick}
                  >
                    <span className="flex align-items-center ml-5">
                      <i className="pi pi-angle-left dataScrollerFooterBackIcon" />
                      <span id={elementIDs.slideMenuBackButton}>{t('Back')}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        );
      })}
    </TabView>
  );
};

const MobileCatalogs = () => {
  const { onCloseMobileCatalogs } = useActions();
  const { catalogsState, setCatalogsState, showCatalogs, sideBarRef } = useCatalogsContext();
  const sizes = useCatalogContentSizeMobile();
  const activeTab = catalogsState.activeTab;
  const showQuickPick = activeTab.tabLayout;

  const catalogTabsLayout = () => {
    if (showQuickPick) {
      const scrollHeight = activeTab?.showSearchPanelForCustomTabLayout
        ? sizes.tabLayoutWithSearchPanelHeight
        : sizes.tabLayoutWithoutSearchPanelHeight;
      return (
        <div className="inline" id={`${activeTab?.name}_catalogContent`}>
          {activeTab?.tabLayout && activeTab?.tabLayout({ catalogsState, setCatalogsState, scrollHeight })}
        </div>
      );
    }

    return <CodeCatalogs />;
  };

  return (
    <Sidebar
      ref={sideBarRef}
      className={cx('catalogsSidebarMobile', showQuickPick ? 'quickPickCatalogSidebarMobile' : 'catalogsSidebar')}
      position="right"
      blockScroll
      showCloseIcon={false}
      visible={showCatalogs.mobile}
      style={{ width: '100%' }}
      icons={<SidebarHeader />}
      baseZIndex={999999}
      onHide={onCloseMobileCatalogs}
    >
      {catalogTabsLayout()}
    </Sidebar>
  );
};

export default MobileCatalogs;
