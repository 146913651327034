import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { inputs } from '../../helpers/inputs';
import { isRebillStatus } from '../../../../../utils/getStatus';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { invoiceTypes } from '../../helpers/defaultValues';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';

const CardTitle = (props) => {
  const { control, isNew, watch } = useFormContext();
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const recordNumber = watch(inputs.recordNumber.name);
  const sequenceNumber = watch(inputs.sequenceNumber.name);
  const status = watch(inputs.status.name);
  const recordNum = sequenceNumber ? `#${trimLeftZeros(sequenceNumber)} (s)` : `#${trimLeftZeros(recordNumber)} (o)`;
  const editText = isRebillStatus(status) ? t('Rebill') : t('Edit');

  const type =
    invoiceType === invoiceTypes.wsbc ? t('Invoices.3').toLowerCase() : !isNew ? `${t('Claim').toLowerCase()}` : `${t('Claim_s').toLowerCase()}`;

  const title = () => {
    if (!isNew) {
      return `${editText} ${invoiceType || invoiceTypes.msp} ${type} ${recordNum}`;
    }

    return `${t('Create')} ${invoiceType || invoiceTypes.msp} ${type}`;
  };

  // Dynamically include `text-2xl` only if no font size is provided in props.className
  const className = cx(
    'flex align-items-center font-bold', // Default classes
    { 'text-2xl': !/\btext-\w+\b/.test(props.className || '') }, // Add text-2xl if no text-* class is present
    props.className // Merge user-provided classes
  );

  return (
    <div {...props} id={elementIDs.teleplanInvoiceTitle} className={className}>
      {title()}
    </div>
  );
};

export default CardTitle;
