import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Context from '../../../../Context';
import { AutoComplete } from 'primereact/autocomplete';
import { getPatients } from '../../../../service/Lookup';
import { routes } from '../../../../routes/routes';
import { getPatient, setShowNewPatientSuccessPage } from '../../../patients/actions/patients.action.creators';
import {
  getClaimInfo,
  getInvoicesCatalogMostRecentCodes,
  setPatientPickerQueryValue,
  shouldResetForm,
  updatePrivateRecord
} from '../../actions/claims.action.creators';
import { onBillToUpdate } from './claimDetailsHelpers/onClaimUpdate';
import { addNewItem } from '../../../common/components/inputInfoMessages';
import { commonInputs } from '../../../config/commonInputsConfig';
import { formatPatientInfoForClaims } from '../../../patients/helpers/formatPatientInfoForClaims';
import { updatePractitionerValues } from './claimDetailsHelpers/updatePractitionerValues';
import { updateLocationValues } from './claimDetailsHelpers/updateLocationValues';
import { formatServices } from './claimDetailsHelpers/formatServices';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { inputs } from './claimDetailsHelpers/inputs';
import { isEmpty, unionBy } from 'lodash';
import { getPreferences, setCurrentPractitionerPrefs } from '../../../preferences/actions/preferences.action.creators';
import { patientFullNameWithNicknameAndAge } from '../../../utils/patientFullName';
import { defaultGuid } from '../../../config/defaultValuesConfig';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const PatientSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentPractitionerPrefs } = useSelector((state) => state.preferences);
  const { privateRecord, errors, setValidationErrors, user, users, clinic, locations, currentUserDefaultPrefs, isMobile } = useContext(Context);

  const [suggestions, setSuggestions] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);
  const [value, setValue] = useState(null);

  // Initial value
  useEffect(() => {
    const patient = privateRecord[commonInputs.patients.descriptionName];
    if (Array.isArray(patient) && patient?.length) {
      setValue(patient[0]);
      setInvalid(false);
    } else {
      setValue(patient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.Patients]);

  const fetchPatients = (query) => {
    dispatch(setPatientPickerQueryValue(query)); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen
    getPatients({ query }).then((patients) => {
      const list = patients.patientList.map((x) => formatPatientInfoForClaims(x));
      list.length > 0 ? setIsSuggestionsFound(true) : setIsSuggestionsFound(false);
      setSuggestions(list);
    });
  };

  const onChange = (e) => {
    const setIsDirty = e.value ? true : false;
    const commonValues = { Patients: e.value };
    const updatedValues = e.value ? commonValues : { ...commonValues, [inputs().billTo.name]: [] };
    dispatch(updatePrivateRecord(updatedValues, false, setIsDirty));
  };

  const updatePatient = (patient) => {
    dispatch(
      updatePrivateRecord({
        [commonInputs.patients.descriptionName]: patient
      })
    );
    onBillToUpdate([patient], dispatch); // Add Bill To value
  };

  const onSelect = async (e) => {
    if (e.value?.PatientGuid !== value?.PatientGuid) {
      // if no private invoice
      if (!e.value?.LastPrivateGuid || e.value?.LastPrivateGuid === defaultGuid) {
        updatePatient(e.value);
      }

      // if private invoice is exist
      if (e.value?.LastPrivateGuid && e.value?.LastPrivateGuid !== defaultGuid) {
        dispatch(
          getClaimInfo(
            e.value?.LastPrivateGuid,
            (responseData) => {
              // Update prefs for new practitioner
              if (privateRecord?.DoctorGuid?.toUpperCase() !== responseData?.DoctorGuid?.toUpperCase()) {
                dispatch(
                  getPreferences(user.details.DCNGuid, responseData?.DoctorGuid, (prefs) => {
                    const defaultPrefs = prefs?.find((i) => i.label === 'default');
                    dispatch(setCurrentPractitionerPrefs(defaultPrefs));
                    updatePrivateRecordState(e.value, responseData, defaultPrefs);
                  })
                );
              }

              if (privateRecord?.DoctorGuid?.toUpperCase() === responseData?.DoctorGuid?.toUpperCase()) {
                updatePrivateRecordState(e.value, responseData);
              }
            },
            'P'
          )
        );
      }

      // Get recent codes for catalogs
      dispatch(getInvoicesCatalogMostRecentCodes({ patientGuid: e.value?.PatientGuid }));
      setInvalid(false);

      delete errors.Patients;
      setValidationErrors(errors);

      // Get full patient info
      if (e.value?.PatientGuid) {
        const patientInfo = await getPatient(e.value?.PatientGuid);
        const updatedPatientInfo = formatPatientInfoForClaims(patientInfo);
        updatePatient(updatedPatientInfo);
      }
    }
  };

  const updatePrivateRecordState = (patient, responseData, newPractitionerPrefs) => {
    const formattedResponceData = {
      ...responseData,
      FullName: `${responseData.DLastName}, ${responseData.DFirstName}`
    };
    const currentLocation = locations.find((i) => i.DCNGuid.toUpperCase() === formattedResponceData.DCNGuid.toUpperCase()) || locations[0];

    const payTo = () => {
      if (!responseData?.PayTo || isEmpty(responseData?.PayTo)) {
        if (responseData?.DoctorGuid === user.details.DCNGuid) return clinic;
        if (responseData?.DoctorGuid !== user.details.DCNGuid) {
          return users?.find((i) => i.DoctorGuid?.toUpperCase() === responseData?.DoctorGuid?.toUpperCase());
        }
      }

      return responseData?.PayTo;
    };

    const serviceGuid = privateRecord?.ServiceGuid?.length ? privateRecord?.ServiceGuid : responseData?.ServicesList?.map((i) => i.value); //CMO-2689 - Private invoice -> Do not overwrite selected service after patient is added
    const serviceList = privateRecord?.ServicesList?.length ? privateRecord?.ServicesList : formatServices(responseData); //CMO-2689 - Private invoice -> Do not overwrite selected service after patient is added

    dispatch(
      updatePrivateRecord({
        ...updatePractitionerValues(formattedResponceData), // Update practitioner
        ...updateLocationValues(currentLocation), // Update location
        Patients: [patient],
        ServiceGuid: serviceGuid,
        ServicesList: serviceList,
        PayTo: payTo(),
        BillTo: responseData?.BillTo?.length ? [formatPatientInfoForClaims(responseData?.BillTo)] : [formatPatientInfoForClaims(patient)],
        CurrentUserPrefs: updatedPrefs(responseData, newPractitionerPrefs)
      })
    );
  };

  const updatedPrefs = (responseData, newPractitionerPrefs) => {
    //updated PayTo prefs
    const payToDoctorGuid = responseData?.PayTo?.DoctorGuid || responseData?.PayTo?.DCNGuid;
    const practitionerPrefs = newPractitionerPrefs || currentPractitionerPrefs;
    const defaultPayToValue = practitionerPrefs?.content?.find((i) => i.label === prefsCodes.defaultPayTo);
    const presetPayToValue = defaultPayToValue?.value?.toUpperCase() === payToDoctorGuid?.toUpperCase() ? '1' : '0';
    const updatedPresetPayTo = { value: presetPayToValue, label: prefsCodes.presetPayTo };
    const updatedDefaultPayTo = { value: payToDoctorGuid, label: prefsCodes.defaultPayTo };

    //updated Practitioner prefs
    const defaultPractitionerValue = currentUserDefaultPrefs?.content?.find((i) => i.label === prefsCodes.defaultPractitioner);
    const presetPractitionerValue = defaultPractitionerValue?.value?.toUpperCase() === responseData?.DoctorGuid?.toUpperCase() ? '1' : '0';
    const updatedPresetPractitioner = { value: presetPractitionerValue, label: prefsCodes.presetPractitioner };
    const updatedDefaultPractitioner = { value: responseData?.DoctorGuid, label: prefsCodes.defaultPractitioner };

    const newPrefs = [updatedDefaultPractitioner, updatedPresetPractitioner, updatedDefaultPayTo, updatedPresetPayTo];
    return {
      ...privateRecord?.CurrentUserPrefs,
      content: unionBy(newPrefs, privateRecord?.CurrentUserPrefs?.content, 'label')
    };
  };

  const onBlur = (e) => {
    setIsSuggestionsFound(true);
    e.target.value ? setInvalid(false) : setInvalid(true);
  };

  const onAddClick = () => {
    dispatch(shouldResetForm(false));
    dispatch(setShowNewPatientSuccessPage({ showSuccessPage: false, from: 'privateClaim' }));
    history.push(`${routes.createPatient.path}/new`);
  };

  const formatItemTemplate = (patient) => {
    return (
      <>
        <div>{patientFullNameWithNicknameAndAge(patient)}</div>
        <div>{`${'PHN'}: ${patient.PHN}`}</div>
      </>
    );
  };

  const isValid = invalid ? 'p-invalid' : errors.Patients && value ? 'p-invalid' : '';
  const showError = invalid && isSuggestionsFound ? true : errors.Patients && isSuggestionsFound && value ? true : false;

  return (
    <>
      <AutoComplete
        id={elementIDs.patientPickerAutocomplete}
        inputId={elementIDs.patientPickerAutocompleteInput}
        className={cx('w-full', isValid)}
        value={value}
        autoFocus={isMobile || privateRecord[commonInputs.patients.descriptionName] ? false : true} //[KS] CMO-1086 - Change default focus when creating new claim for a patient
        suggestions={suggestions}
        placeholder={t('Name_PHN_DOB.2')}
        autoHighlight
        dropdown
        forceSelection
        field="label"
        scrollHeight={420}
        onBlur={onBlur}
        onChange={onChange}
        onSelect={onSelect}
        itemTemplate={formatItemTemplate}
        onFocus={(e) => e.target.select()}
        completeMethod={(e) => fetchPatients(e.query)}
      />

      {addNewItem({ onClick: onAddClick, label: t('Add_new_patient'), showNoResults: !isSuggestionsFound, showRequired: showError })}
    </>
  );
};

export default PatientSearch;
