import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import cx from 'classnames';

const PercentEditor = ({ options, onBlur, inputClassName }) => {
  const { onPercentEditorChange, onPercentEditorBlur } = useUpdateRecords();

  // CMO-1894 - Create/Edit teleplan - disable percent input if units value > 1
  if (!options.rowData.units || options.rowData.units > 1) {
    return <span id={elementIDs.percentEditorHidden}>{options.value}</span>;
  }

  return (
    <div className="relative">
      <InputText
        id={elementIDs.percentEditor}
        className={cx('w-5rem', inputClassName)}
        keyfilter="num"
        autoFocus
        value={options.value}
        onChange={(e) => onPercentEditorChange(e.target.value, options)}
        onBlur={(e) => {
          onPercentEditorBlur(e.target.value, options);
          onBlur?.(e);
        }}
      />
    </div>
  );
};

export default PercentEditor;
