import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { emailValidation } from '../../../../modules/config/validations';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const Email = () => {
  const name = 'Email';
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: emailValidation
      }}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Email')} errorMessage={errors[name]?.message}>
          <InputText
            className={cx({ 'p-invalid': !!errors[name] })}
            placeholder="mail@example.com"
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default Email;
