import { isTimeValid, parseMilitaryTime } from '../../../../utils/formatTime';
import { calculationOfWholeNumberOfUnits } from './calculateUnits';

export const showUnitsCalculationText = (records) => {
  return records.some((rowData) => {
    const startTime = parseMilitaryTime(rowData?.service_start);
    const endTime = parseMilitaryTime(rowData?.service_finish);
    const isStartValid = isTimeValid(startTime);
    const isEndValid = isTimeValid(endTime);

    if (!isStartValid || !isEndValid) return false;

    // VER-376 - Show calculation of whole number of units + leftover time
    const unitsCalculationText = calculationOfWholeNumberOfUnits({ startTime, endTime, rowData });
    return unitsCalculationText;
  });
};
