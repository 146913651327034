// messages.js (or autofillErrorMessages.js)
export const autofillMessages = {
    NO_TEXT_FOUND: 'No text found in the image. Please try a different image.',
    ERROR_EXTRACTING_TEXT: 'Error extracting text from the image. Please try again.',
    DOB_NOT_READABLE: 'Unable to read DOB. Enter it manually and click Eligibility Check, or manually enter the name as well.',
    PHN_NOT_READABLE: 'Unable to read PHN. Enter it manually and click Eligibility Check, or manually enter the name as well.',
    AUTOFILL_SUCCESS: 'Autofill successful!',
    ABORT_MESSAGE: 'Autofill operation aborted.',
    CLOSE_MESSAGE: 'Close this message',
};
