import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { steps } from '../../../../../helpers/steps';

const ArrowsRowReorder = ({ rowIndex }) => {
  const { localState, setLocalState } = useFormContext();
  const isReferralStep = localState.step === steps.groupReferralPicker;
  const isCommentStep = localState.step === steps.groupComments;

  if (!localState.isArrowsRowReorder || isReferralStep || isCommentStep) {
    return null;
  }

  const onRowReorder = (newGroupRecords) => {
    setLocalState((prevState) => ({
      ...prevState,
      groupRecords: newGroupRecords,
      isOrderChanged: prevState.step === steps.groupDxPicker
    }));
  };

  const moveRowUp = (index) => {
    if (index > 0) {
      const newGroupRecords = [...localState.groupRecords];
      [newGroupRecords[index - 1], newGroupRecords[index]] = [newGroupRecords[index], newGroupRecords[index - 1]];
      onRowReorder(newGroupRecords);
    }
  };

  const moveRowDown = (index) => {
    if (index < localState.groupRecords.length - 1) {
      const newGroupRecords = [...localState.groupRecords];
      [newGroupRecords[index], newGroupRecords[index + 1]] = [newGroupRecords[index + 1], newGroupRecords[index]];
      onRowReorder(newGroupRecords);
    }
  };

  return (
    <div className="flex flex-column">
      {rowIndex > 0 && (
        <Button
          icon="pi pi-angle-up"
          className="p-1"
          text
          tooltip="Move row up"
          tooltipOptions={{ position: 'top', showDelay: 1000 }}
          onClick={() => moveRowUp(rowIndex)}
        />
      )}
      {rowIndex < localState.groupRecords.length - 1 && (
        <Button
          icon="pi pi-angle-down"
          className="p-1"
          text
          tooltip="Move row down"
          tooltipOptions={{ position: 'top', showDelay: 1000 }}
          onClick={() => moveRowDown(rowIndex)}
        />
      )}
    </div>
  );
};

export default ArrowsRowReorder;
