import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'primereact/button';
import { forEach } from 'lodash';

import { C02Record } from './C02Record';
import ClaimService from '../../../../service/ClaimService';
import { t } from '../../../../service/localization/i18n';

const C02Viewer = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  
  const user = useSelector((state) => state.user.details);
  const history = useHistory();
  const {DCNGuid} = user; 
  const demo = 'C02D90900002092J0123909889138939022VNAA00001G600000000620008314020240612000 345';
  let offset = 0;
  const tableRows = [];

  useEffect(()=>{
    if (id?.length > 0 && DCNGuid?.length > 0) {
      ClaimService.getClaimRawData(DCNGuid, id).then((results) => {
        if (results.data?.RecordLine) {
          setData(results.data.RecordLine);
        }
      });
    } 
  },[id]);
  const onBack = () => {
    history.goBack(); 
  };
  forEach(C02Record, (record, index) => {
    const value = data ? data.substring(offset, offset + record.field_size) : '';

    tableRows.push(
      <tr key={index}>
        <td style={{ border: '1px solid black', padding: '4px', height: '40px' }}>{record.field_id}</td>
        <td style={{ border: '1px solid black', padding: '4px', height: '40px' }}>{record.field_name}</td>
        <td style={{ border: '1px solid black', padding: '4px', height: '40px' }}>{value}</td>
        <td style={{ border: '1px solid black', padding: '4px', height: '40px' }}>{record.description}</td>
      </tr>
    );

    offset += record.field_size;
  });

  return (
    <div className='containerXL subMenuRoot mt-4 px-3 w-full"'>
      <div style={{ margin: '10px'}}>
      <h1>Submitted C02 Record</h1>
      <p><Button className="p-button-text p-button-outlined" label={t('Back')} onClick={onBack} /></p>
      </div>
      <table className="table" style={{ borderCollapse: 'collapse', margin: '10px', width: 'calc(100% - 20px)' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '4px', height: '40px' }}>Field ID</th>
            <th style={{ border: '1px solid black', padding: '4px', height: '40px' }}>Field Name</th>
            <th style={{ border: '1px solid black', padding: '4px', height: '40px' }}>Value</th>
            <th style={{ border: '1px solid black', padding: '4px', height: '40px' }}>Description</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
};

export default C02Viewer;
