import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CodePicker } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { icd9CodeMaxEntry } from '../../../../../../config/defaultValuesConfig';

const DxCodeEditor = ({ options, onBlur }) => {
  const name = inputs.icd9.name;
  const { handleDxChange } = useUpdateRecords();
  const { watch, localState } = useFormContext();
  const [codes, setCodes] = useState([]);
  const speciality = watch(inputs.speciality.name);
  const ref = useRef(null);
  const records = localState.records;

  useEffect(() => {
    // Autofocus on the input field when the component mounts
    ref.current?.focus();
  }, []);

  useEffect(() => {
    if (!records[options.rowIndex].icd9?.length) return setCodes([]);

    const _codesDescription = records[options.rowIndex].icd9_text?.split('|');
    const _codes = records[options.rowIndex].icd9?.split(',').map((i, index) => ({
      value: i,
      label: _codesDescription[index]
    }));

    setCodes(_codes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  const handleBlurWithDelay = (e) => {
    // hack
    setTimeout(() => {
      onBlur?.(e); // Call onBlur after 500ms
    }, 500);
  };

  return (
    <CodePicker
      className="recordsDxCodeEditor"
      name={name}
      ref={ref}
      value={codes}
      maxEntry={icd9CodeMaxEntry}
      searchParams={{ speciality, codeType: inputs.icd9.codeType, getCodesFor: 'teleplan' }}
      onChange={(e) => handleDxChange(e, options)}
      onBlur={handleBlurWithDelay} // Use delayed onBlur
    />
  );
};

export default DxCodeEditor;
