import axios from 'axios';
import actions from './core.action.types';
import { setAuthHeader } from '../../../config';
import { printTypes } from '../../../components/Layouts/Print/helpers/printTypes';
import { formatWSBCRInitialValues } from '../../claims/components/WSBCReport/helpers/formatWSBCRInitialValues';
import { store } from '../../../store';

export const updateWidnowSizes = (params) => (dispatch) => {
  const newState = {
    type: actions.WINDOW_RESIZE,
    results: params
  };

  return dispatch(newState);
};

export const setTheme = (theme) => (dispatch) => {
  return dispatch({ type: actions.SET_COLORS, results: theme });
};

export const setLoader = (visible) => (dispatch) => {
  return dispatch({ type: actions.SET_LOADER, results: visible });
};

export const setDeepRow = (rowid) => (dispatch) => {
  return dispatch({ type: actions.SET_DEEPROW, results: rowid });
};

export const removeDeepRow = () => (dispatch) => {
  return dispatch({ type: actions.SET_DEEPROW, results: undefined });
};

export const setDirty = () => (dispatch) => {
  return dispatch({ type: actions.SET_DIRTY });
};

export const setClean = () => (dispatch) => {
  return dispatch({ type: actions.SET_CLEAN });
};

export const setAppMode =
  (mode, editable = false, logintype = 'login') =>
  (dispatch) => {
    const newState = {
      type: actions.APP_MODE,
      results: { mode, editable, logintype }
    };
    return dispatch(newState);
  };

export const setToastMessage = ({ type = 'warn', message = '', lifeTime = null, title = null, position = 'bottom-right' }) => {
  // [KS] Pass "success", "error", "warn" or "info" type parameter to display corresponding toast
  // [KS] Message can be a string or jsx
  // [KS] If no need autohide toast message, pass "sticky" to lifeTime

  const currentPosition = store.getState().core.toastMessagePosition;
  const newPosition = currentPosition ? currentPosition : position;

  return {
    type: actions.TOAST_MESSAGE,
    results: { type, message, lifeTime, title, position: newPosition }
  };
};

export const setEligibilityError = (error) => {
  return {
    type: actions.SET_ELIGIBILITY_ERROR,
    results: error
  };
}

export const setLoading = (loading = false) => {
  return {
    type: actions.SET_LOADING,
    results: loading
  };
};

export const setToastPosition = (position) => {
  return {
    type: actions.TOAST_MESSAGE_POSITION,
    results: position
  };
};

export const closeToastMessage = (close) => {
  return {
    type: actions.CLOSE_TOAST_MESSAGE,
    results: close
  };
};

export const setLayoutMode = (mode) => {
  return {
    type: actions.LAYOUT_MODE,
    results: mode
  };
};

export const toggleFilters = (toggle) => {
  return {
    type: actions.TOGGLE_FILTERS,
    results: toggle
  };
};

export const toggleSorting = (toggle) => {
  return {
    type: actions.TOGGLE_SORTING,
    results: toggle
  };
};

export const setPrintData = (printType = '', printData = {}) => {
  return {
    type: actions.SET_PRINT_DATA,
    results: { type: printType, printData }
  };
};

export const onPrint =
  ({ printType, recordId }) =>
  (dispatch, state) => {
    // printType - add new type to the printTypes.js

    setAuthHeader(state);
    const url = printTypes(recordId)[printType].url;

    axios
      .get(url)
      .then((response) => {
        let responseData = response.data;
        if (printType === printTypes().wsbcReport.type) responseData = formatWSBCRInitialValues(response.data);

        dispatch(setPrintData(printType, responseData));
        return window.print();
      })
      .catch((reason) => {
        dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
      });
  };
