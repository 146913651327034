export const phoneValidation = (phone) => {
  if (!phone) return;
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  if (!phoneRegex.test(phone)) return 'Invalid format. Use the format (123) 456-7890';
  return true;
};

export const emailValidation = (email) => {
  if (!email) return;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [localPart, domainPart] = email.split('@');

  if (!email.includes('@') || email.split('@').length !== 2) return 'Invalid email format: missing "@" or too many "@" symbols';
  if (!emailRegex.test(email)) return 'Invalid email format';

  // Local part validation
  if (/^[._-]/.test(localPart) || /[._-]$/.test(localPart)) return 'Local part cannot start or end with period, hyphen, or underscore';
  if (/[^a-zA-Z0-9._-]/.test(localPart)) return 'Local part contains invalid characters';
  if (/([._-])\1/.test(localPart)) return 'Local part contains conssecutive periods, hyphens, or underscores';

  // Domain part validation
  if (/[^a-zA-Z0-9.-]/.test(domainPart)) return 'Domain part contains invalid characters';
  if (!/\./.test(domainPart)) return 'Domain part must contain at least one period';
  if (!/\.[a-zA-Z]{2,}$/.test(domainPart)) return 'Domain must end with a valid top-level domain';
  if (/([.-])\1/.test(domainPart)) return 'Domain part contains consecutive periods or hyphens';

  return true;
};
