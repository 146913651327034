import moment from 'moment';
import { location_codes } from '../common/contants';
import { find } from 'lodash';
import { t } from '../../service/localization/i18n';

export const representativeStatus = 3;

//Teleplan invoice
export const notForDxBonusFeeCodes = ['13334'];
export const bonusFeeCodesOnly = ['98010', '98011', '98012'];
export const bonusFeeCodes = [...bonusFeeCodesOnly, ...notForDxBonusFeeCodes];
export const feecodesMaxEntry = 69; // >_^
export const icd9CodeMaxEntry = 3;
export const noiCodeMaxEntry = 1;
export const aoiCodeMaxEntry = 1;
export const referralCodeMaxEntry = 1;
export const feeCodeMaxEntry = (isUnlimitedEntries) => {
  return isUnlimitedEntries ? feecodesMaxEntry : 1;
};

//Private invoice
export const billToMaxEntry = 1;
export const privetPatientsMaxEntries = 1;

export const phoneNumberLength = 10;
export const PHNMaxLength = 12; //Max length of PHN is 10. The number 12 takes spaces into account when formatting
export const patientPickerPageSize = 100;
export const codePickerPageSize = 200;

export const NA_Speciality = -999;
export const multipleSpeciality = -998;
export const NA_DoctorGuid = '-1';
export const multipleDoctorGuid = '-2';
export const defaultGuid = '00000000-0000-0000-0000-000000000000';

export const naPractitionerOption = {
  DoctorGuid: NA_DoctorGuid,
  UserGuid: NA_DoctorGuid,
  FirstName: t('N_A'),
  value: NA_DoctorGuid,
  label: t('N_A'),
  Speciality: NA_Speciality,
  UserStatus: '0'
};

export const multiplePractitionerOption = {
  DoctorGuid: multipleDoctorGuid,
  UserGuid: multipleDoctorGuid,
  FirstName: t('Multiple_practitioners'),
  value: multipleDoctorGuid,
  label: t('Multiple_practitioners'),
  Speciality: multipleSpeciality,
  UserStatus: '0'
};

export const allPractitionerOption = {
  DoctorGuid: NA_DoctorGuid,
  UserGuid: NA_DoctorGuid,
  FirstName: t('All_practitioners'),
  value: NA_DoctorGuid,
  label: t('All_practitioners'),
  Speciality: NA_Speciality,
  UserStatus: '0'
};

export const rowsPerPageDefaultOptions = [20, 40, 60];
export const initStateForPagination = {
  first: 0,
  page: 1,
  pageSize: 20,
  totalRecords: 0,
  totalPages: 1
};
export const defaultAnatomicCode = 'N';
export const specPHN = '9842 719 596';
export const defaultBMI = 0;
export const maxBMI = 99.9;
export const minBMI = 35;
export const getMinBMI = (feeCodesOnly) => (feeCodesOnly?.includes('13003') ? 35.1 : minBMI);

const nextYear = moment().add(1, 'year').format('YYYY');
export const defaultYearRange = `1900:${nextYear}`;

export const defaultLocationCode = (speciality) => {
  const codeMap = {
    0: 'Q',
    1: 'Q',
    2: 'Q',
    3: 'Q',
    6: 'Q',
    8: 'Q',
    16: 'Q',
    18: 'Q',
    19: 'Q',
    47: 'Q',
    4: 'N',
    5: 'N',
    14: 'N',
    24: 'N',
    44: 'N'
  };

  const code = codeMap[Number(speciality)] || 'T';
  return find(location_codes, (i) => i.value === code);
};

export const serviceDefaultValues = {
  doctorGuid: '',
  treatedBy: '',
  id: '',
  licenceNumber: '',
  duration: '',
  order: 0,
  quantity: 0,
  amount: 0,
  inStoreCredit: 0,
  discount: 0,
  discountUnits: 'percent', // avalible values: "currency" or "percent"
  salesTax: 'exempt',
  note: '',
  label: '',
  text: ''
};

//======== DATA TABLES ========//
export const defaultPageSize = 20;
export const showInCardPageSize = 3;
export const invoicesCatalogsDefaultPageSize = 25;

export const dataTablesParams = {
  practitioners: {
    name: 'practitioners'
  },

  patients: {
    name: 'patients'
  },

  invoices: {
    name: 'invoices'
  },

  letters: {
    name: 'letters'
  }
};

//DEMO
// !!! DO NOT DELETE OR RENAME DEMO CODE. JUST COPY-PASTE IT AND THEN USE YOUR OWN NAMES !!!
export const demosDefaultSortBy = {
  sortField: 'DateUpdated',
  sortOrder: 'DateUpdated_desc'
};
export const demosDefaultFilters = {};

//CLAIMS
export const claimsDefaultSortBy = {
  sortField: 'DateUpdated',
  sortOrder: 'DateUpdated_desc'
};

// export const claimsFiltersWithPeriod = {
//   // CMO-2444 - Default filters on All Invoices table - > last 6 months
//   Period: "5",
//   showArchived: "", // "True" - show archiver, "" - do not show archived
//   PeriodFrom: moment(moment().subtract(6, 'months').toDate()).format('YYYY/MM/DD'),
//   PeriodTo: moment(moment().toDate()).format('YYYY/MM/DD'),
//   ServiceDate: "",
//   PeriodText: "All"
// };
export const claimsFiltersWithPeriod = {
  // CMO-2444 - Default filters on All Invoices table - > last 6 months
  Period: '7',
  showArchived: '', // "True" - show archiver, "" - do not show archived
  PeriodFrom: moment(moment().subtract(6, 'months').toDate()).format('YYYY/MM/DD'),
  // PeriodTo: moment(moment().toDate()).format('YYYY/MM/DD'),
  PeriodTo: '',
  ServiceDate: '',
  PeriodText: 'All'
};

export const claimsDefaultFilters = {
  Period: '-2',
  showArchived: '', // "True" - show archiver, "" - do not show archived
  PeriodFrom: '',
  PeriodTo: '',
  PeriodText: 'All'
};

//PATIENTS
export const patientsDefaultSortBy = {
  sortField: 'DateUpdated',
  sortOrder: 'DateUpdated_desc',
  sortOrderNumber: 1
};
export const patientsDefaultFilters = { LfpPanel: '-1' };

//APPOINTMENTS
export const appointmentsDefaultSortBy = {
  sortField: 'DateUpdated',
  sortOrder: 'DateUpdated_desc'
};
export const appointmentsDefaultFilters = {};

//PRACTITIONERS
export const practitionersDefaultSortBy = {
  sortField: 'LastName',
  sortOrder: 'LastName_asc',
  sortOrderNumber: 1
};
// ============================================ //
