import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { getFromLocalStorage } from '../../../../utils/getFromLocalStorage';
import { steps } from './steps';

export const localStateInitValues = {
  records: [],
  groupRecords: [],
  templatesList: [],
  listOfPatients: [],
  createdRecordsList: [],
  duplicatedPatients: [],
  practitionerPrefs: {},
  focusFieldParams: {},
  claimData: {},
  selectedTemplate: {},
  eligibility: {},
  recordsRequiredInputs: {},
  groupRecordsRequiredInputs: {},
  aditionalInfoForPatient: {},
  batchCatalogsDialog: { showDialog: false, rowOptions: {} },
  durationForPsychiatryCodesDialog: { showDialog: false, options: {} },
  unitsValidationDialog: { showDialog: false },
  batchMatchDurationForFeeCodeDialog: { showDialog: false, options: {} },
  applyToAllDxDialog: { showDialog: false, option: '' },
  applyToAllReferralDialog: { showDialog: false, option: '' },
  deleteGroupRecordConfirm: { showDialog: false, rowOptions: {} },
  billingHistoryDialog: { showDialog: false, options: { initPatient: null } },
  resetTeleplanInvoice: true,
  isLoading: false,
  loadingSpinner: false,
  isLoadingCatalogsData: false,
  isPHNValid: true,
  isOrderChanged: false,
  changeOrderDialog: false,
  sameDurationDialog: false,
  applyToAllDx: false,
  latestDx: true,
  applyToAllReferral: false,
  latestReferral: true,
  applyToAllTime: false,
  gettingRecentCodes: false,
  unsavedChangesForBatchDialog: false,
  unsavedChangesForTeleplanDialog: false,
  unsavedChangesForFirstVisitDialog: false,
  creatingGroupInvoicesTemplate: false,
  delettingGroupInvoicesTemplate: false,
  gettingGroupInvoicesTemplate: false,
  isTimeOverMidnightDialog: false,
  groupTemplateListDialog: false,
  createGroupTemplateDialog: false,
  bmiSurgicalAssistDialog: false,
  iecDialog: false,
  bmiSurgicalAssistForGroupDialog: false,
  editGroupTemplateDialog: false,
  feeCodeForWSBCDialog: false,
  showBatchRecordsWarnForOnePatient: false,
  showBatchRecordsWarnForMultiplePatient: false,
  invalidLocationForMidwifeInductionDialog: false,
  conflictsBetweenGroupAndIndividualTimesDialog: false,
  emergencyInfoDialog: false,
  travelInfoDialog: false,
  ccfppInfoDialog: false,
  submitionCodeDialog: false,
  addNewPractitionerDialog: false,
  addNewPractitionerForGroupDialog: { showDialog: false, rowOptions: {} },
  feeCodeDurationDialog: false,
  recordsTableChangesDialog: false,
  weekendValidationDialog: false,
  anesProceduralDialog: false,
  anatomicCodeDialog: false,
  wsbcConsultationDialog: false,
  duplicatePatientDialog: false,
  groupTabelSettingsDialog: false,
  isArrowsRowReorder: getFromLocalStorage(localStorageKeys.batchArrowsReorder, true),
  isDragDropReorder: getFromLocalStorage(localStorageKeys.batchDragDropReorder, true),
  isEmptyPractitioner: false,
  isStep2Dirty: false,
  isMultipleDates: false,
  initialDataForEdit: null,
  sendNow: false,
  saveNow: false,
  saveAndClose: false,
  groupTableTab: 'dx',
  catalogIndex: 0,
  activeSubTabIndex: 0,
  step: steps.form,
  prevStep: steps.form,
  showCatalogs: {
    desktop: true,
    mobile: false
  }
};
