import { isEmpty } from 'lodash';
import { findDifferenceForEligibility } from '../../commonElements/helpers/findDifferenceForEligibility';

export const checkIfPatientDemographicsAreDifferent = ({ eligibility, formikValues }) => {
  if (eligibility) {
    const differences = findDifferenceForEligibility(eligibility, formikValues);
    return !isEmpty(differences);
  }

  return false;
};
