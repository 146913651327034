/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from 'primereact/divider';
import Demographics from './Demographics';
import Address from './Address';
import Success from './Success';
import CommonCard from '../../../common/components/CommonCard';
import PatientFormCard from '../../../common/components/PatientFormCard';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { integersOnlyCheckForString, validateCanadianZip } from '../../../regex/regex';
import { newPatientBlankRecord } from './helpers/newPatientBlankRecord';
import { setEligibilityError, setToastMessage } from '../../../core/actions/core.action.creators';
import { setFormikFocusToInvalidInput } from '../../../utils/setFormikFocusToInvalidInput';
import { provinces_list } from '../../../common/contants';
import { t } from '../../../../service/localization/i18n';
import classes from './PatientForm.module.scss';
import moment from 'moment';
import cx from 'classnames';
import * as yup from 'yup';
import { isString, isEmpty } from 'lodash';
import {
  createPatient,
  setShowNewPatientSuccessPage,
  setEligibility,
  duplicatePatientCheck,
  setPatientInfo,
  setActiveTabIndexForPatientForm,
  editPatient,
  uploadImage
} from '../../actions/patients.action.creators';
import { routes } from '../../../../routes/routes';
import DuplicatePHNDialog from '../commonElements/DuplicatePHNDialog';
import DuplicatePatientsDialog from '../commonElements/DuplicatePatientsDialog';
import { formatPatientInfoForClaims } from '../../helpers/formatPatientInfoForClaims';
import { commonInputs } from '../../../config/commonInputsConfig';
import {
  getCodesByType,
  setPatientPickerQueryValue,
  updatePrivateRecord,
  updateTeleplanInvoice,
  updateWSBCRecord
} from '../../../claims/actions/claims.action.creators';
import { phoneNumberLength } from '../../../config/defaultValuesConfig';
import { addZerosToPhoneNumber } from '../../../utils/addZerosToPhoneNumber';
import { initialAddressType, initialEmailType, initialPhoneType } from './helpers/initialValuesForPatientForm';
import { onBillToUpdate } from '../../../claims/components/PrivateDetails/claimDetailsHelpers/onClaimUpdate';
import { elementIDs } from '../../../config/elementIDsConfig';
import { inputs as wsbcInputs } from '../../../claims/components/WSBCReport/helpers/inputs';
import { inputs as teleplanInputs } from '../../../claims/components/TeleplanInvoice/helpers/inputs';
import { useURLQuery } from '../../../hooks/useURLQuery';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { Tooltip } from 'primereact/tooltip';
import { handleAutofill } from './AutoFillHandler';
import useAutofill from './useAutofill';
import { Dialog } from 'primereact/dialog';
import exampleImage from './example.png';
import { v4 as randomUUID } from 'uuid';
import { setErrorMessageAutofill } from '../../actions/patients.action.creators';




const PatientForm = ({ showInCard = true, showPrompt = true, isEdit, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useURLQuery();
  const { DCNGuid } = useSelector((state) => state.user.details);
  const { teleplanInvoice, wsbcRecord, patientPickerQueryValue } = useSelector((state) => state.claims);
  const { isMobile, isMobileOnly, iPadInPortrait, isLandscape } = useSelector((state) => state.core.window);
  const {
    patient_details,
    isFetchingPatients,
    eligibility,
    isCreatingPatient,
    isSavingPatientDetails,
    showNewPatientSuccessPage,
    checkingPatientDuplicates,
    patientFormActiveTabIndex,
    isFetchingPHNValidation
  } = useSelector((state) => state.patients);
  const eligbilityFromEdit = useSelector((state) => state.patients?.patient_details?.Eligibility?.Parsed);
  const nameFromLabelAfterEdit = useSelector((state) => state.patients?.patient_details?.FullName);

  const errorAutofillMessage = useSelector((state) => state?.patients?.autofillError?.message);
  const errorAutofillType = useSelector((state) => state?.patients?.autofillError?.severity);
  const originalMSPErrorMessage = useSelector((state) => state?.patients?.autofillError?.originalMSPErrorMessage);
  const errorToastMessage = useSelector((state) => state?.core?.toastMessage?.message);

  const [isImageDialogVisible, setIsImageDialogVisible] = useState(false); // State for image dialog
  const fromSuccessPage = location.state?.fromSuccessPage



  const phnRef = useRef(null);
  const autofillButtonRef = useRef(null); 
  const [step, setStep] = useState(0);
  const [isPHNValid, setIsPHNValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isPHNDuplicate, setIsPHNDuplicate] = useState(false);
  const [showForm, setShowForm] = useState(!location.state?.activateAutofill);
  const [showPatientDuplicateDialog, setShowPatientDuplicateDialogAction] = useState(false);
  const [showPHNDuplicateDialog, setShowPHNDuplicateDialogAction] = useState(false);
  const [displayPrompt, setDisplayPrompt] = useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [isPHNFocused, setIsPHNFocused] = useState(false);
  const [duplicatedPatients, setDuplicatedPatients] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [image, setImage] = useState(null); 
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [removeFocusPhn, setRemoveFocusPhn] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [disableAbortButoon, setDisableAbortButoon] = useState(false);
  const [imageTimer, setImageTimer] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageToAutofill, setImageToAutofill] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [nameFromEligibility, setNameFromEligibility] = useState('');
  const [nameFromPicture, setNameFromPicture] = useState('');
  const [autofillTriggered, setAutofillTriggered] = useState(false); // Track autofill state
  const { isFetchingPatientEligibility } = useSelector((state) => state.patients);
  const isNew = location?.pathname?.indexOf('new') > -1;
  const addNewPatientToNewClaim =
    props.showInDialog ||
    !showNewPatientSuccessPage.showSuccessPage ||
    (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim');
  const disabledPHN = !isNew && patientDetails?.PHNProvince === 'BC' && patientDetails?.TotalRecords > 0;
  // const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  // const qrCodeURL = encodeURI(`${window.location.origin}/#${routes.upload.path}`);



  useEffect(() => {
    if (step === 1) {
      console.log("Navigating to Success Page");
    }
  }, [step]);
  
  useEffect(() => {
    dispatch(setEligibilityError({error: null}));
    dispatch(setErrorMessageAutofill({ type: null, message: null, originalMSPErrorMessage: null }));
  }, []);

  const dataForSubmit = (data) => {
    const mrp = data?.MRP?.length ? data?.MRP[0] : null;
    const result = {
      ...data,
      FirstName: data.FirstName?.trim()?.toUpperCase(),
      LastName: data.LastName?.trim()?.toUpperCase(),
      MidName: data.MidName?.trim()?.toUpperCase(),
      MRPNumber: mrp?.value || '',
      MRPName: mrp?.name || '',
      DateDeceased: data?.DateDeceased ? moment(data.DateDeceased).format('YYYY-MM-DD') : null
    };

    delete result.MRP; // Remove the MRP property

    return result;
  };

  const onSubmit = (formData, checkDuplicate = true) => {
    const data = dataForSubmit(formData);
    // Check if the PHNProvince property of the data object is "Institutional"
    const isInstitutional = data?.PHNProvince === 'Institutional';
    data.AccessWord = data?.AccessWord?.toLowerCase() || ''; // BE requrement convert AccessWord to lowercase

    // If the PHNProvince is not "Institutional"
    if (!isInstitutional) {
      // Remove leading zeros from the PHN property of the data object
      // VER-440
      data.PHN = data.PHN?.replace(/^0+/, '');
    }

    // Create new patient
    if (isNew && !isEdit) {
      if (!checkingPatientDuplicates) {
        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!data?.PHN?.length && checkDuplicate) {
            checkPatientDuplicate({ callback: () => createNewPatient(data) });
          } else {
            createNewPatient(data);
          }
        }
      }
    }

    // Edit patient
    if (!isNew || isEdit) {
      if (!checkingPatientDuplicates) {
        const FullName = data?.MidName
          ? `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()} ${data?.MidName?.toUpperCase()}`
          : `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()}`;

        const newData = {
          ...data,
          FirstName: data.FirstName?.toUpperCase(),
          LastName: data.LastName?.toUpperCase(),
          MidName: data.MidName?.toUpperCase(),
          FullName,
          Province: isString(data?.Province)
            ? data?.Province?.length === 2
              ? provinces_list.find((i) => i.value === data?.Province?.toUpperCase())?.value
              : provinces_list.find((i) => i.label === data?.Province)?.value
            : data?.Province?.value
        };

        // delete newData.EligibilityInfo;

        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!newData?.PHN?.length && checkDuplicate) {
              checkPatientDuplicate({
              callback: () =>
                dispatch(
                  editPatient(newData, () => {
                    formik.resetForm();
                    dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                    if (props.showInDialog) {
                      props.onEdit(newData);
                    } else {
                      navTo();
                    }
                  })
                )
            });
          } else {
            dispatch(
              editPatient(newData, () => {
                dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                formik.resetForm();
                if (props.showInDialog) {
                  props.onEdit(newData);
                } else {
                  if(!fromSuccessPage) {
                    navTo();
                  }
                  else{
                    setNameFromEligibility('');
                    setNameFromPicture('');
                    setPatientDetails(newData); // Update local state
                    formik.resetForm({ values: newData }); // Update form values
                    setStep(1);
                  }
                }
              })
            );
          }
        }
      }
    }
  };

  const createNewPatient = (data) => {
    dispatch(
      createPatient(data, (responseData) => {
        if (props.showInDialog) return props.onCreate(responseData);

        if (
          (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim' && !isEmpty(eligibility)) ||
          (showNewPatientSuccessPage.showSuccessPage && !showNewPatientSuccessPage.from)
        ) {
          //show success page
          dispatch(setPatientInfo(responseData));
          setPatientDetails(responseData);
          setStep(1);
        } else {
          //do not show success page
          setDisplayPrompt(false);
          addNewPatientForNewClaim(responseData); // Set new patient to private claim form
          dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
          dispatch(setToastMessage({ type: 'success', message: t('Patient_created_successfully') }));
          navTo();
        }

        formik.resetForm();
        showPatientDuplicateDialog && setShowPatientDuplicateDialog(false);
      })
    );
  };

  const setShowPHNDuplicateDialog = (showPHNDialog) => {
    setShowPHNDuplicateDialogAction(showPHNDialog);
    setDisplayPrompt(!showPHNDialog);
  };

  const setShowPatientDuplicateDialog = (showDuplicateDialog) => {
    setShowPatientDuplicateDialogAction(showDuplicateDialog);
    setDisplayPrompt(!showDuplicateDialog);
  };

  const handleExistingProfile = (patient) => {
    if (props.showInDialog) return props.onCreate(patient);
    const navToPatientProfile = () => {
      dispatch(setPatientInfo(patient));
      history.replace(`${routes.patientDetails.path}/${patient.PatientGuid}`);
    };

    if (!isNew) return navToPatientProfile(); // redirect to patient profile

    if (isNew) {
      if (!addNewPatientToNewClaim) return navToPatientProfile(); // redirect to patient profile

      // redirect to create new claim form
      if (addNewPatientToNewClaim) {
        const redirectToCreateClaimForm = (responseData) => {
          addNewPatientForNewClaim(responseData);
          navTo();
        };
        // if patient is archived
        if (patient.Status === -1) {
          dispatch(editPatient({ ...patient, Status: 0 }, (responseData) => redirectToCreateClaimForm(responseData)));
        } else {
          // if patient is NOT archived
          redirectToCreateClaimForm(patient);
        }
      }
    }
  };

  const getInitialValuesForEdit = async () => {
    let MRP = [];
    if (patient_details.MRPNumber) {
      const result = await getCodesByType({ codeType: commonInputs.referral.codeType, query: patient_details.MRPNumber });
      MRP = result;
    }

    const PHNInit = patient_details.PHN?.length > 0 && patient_details.PHN !== 'N/A' ? patient_details.PHN : '';
    const phoneInit =
      patient_details?.Phone?.length === phoneNumberLength
        ? patient_details.Phone
        : patient_details?.Phone?.length > 0 && patient_details?.Phone?.length < phoneNumberLength
        ? addZerosToPhoneNumber(patient_details?.Phone)
        : patient_details?.Phone;
    const phoneTypeInit = patient_details.PhoneType ? patient_details.PhoneType : initialPhoneType;
    const emailTypeInit = patient_details.EmailType ? patient_details.EmailType : initialEmailType;
    const addressTypeInit = patient_details.AddressType ? patient_details.AddressType : initialAddressType;

    const initValues = {
      ...patient_details,
      PHN: PHNInit,
      BirthDay: moment(patient_details?.BirthDay).format('MM/DD/YYYY') || '', // [KS] CMO-1287 - Edit patient - demographicsFormLayout - incorrect display of date of birth
      Phone: phoneInit,
      PhoneType: phoneTypeInit,
      EmailType: emailTypeInit,
      AddressType: addressTypeInit,
      Zip: patient_details?.Zip || '',
      MRP,
      DateDeceased: patient_details.DateDeceased ? moment(patient_details.DateDeceased).toDate() : null
    };

    return initValues;
  };

  const formikInitValues = async () => {
    // New patient
    if (isNew) return newPatientBlankRecord(DCNGuid, patientPickerQueryValue);

    // Edit patient
    if (!isNew || isEdit) {
      const initData = await getInitialValuesForEdit();
      setPatientDetails(initData);
      return initData;
    }
  };

  const validationSchema = yup.object().shape({
    BirthDay: yup
      .date()
      .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
      .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
      .required(t('Mandatory_field.1'))
      .nullable(),
    Sex: yup.string().required(t('Mandatory_field.1')),
    LastName: yup.string().required(t('Mandatory_field.1')),
    FirstName: yup.string().required(t('Mandatory_field.1')),
    Email: yup.string().email(t('Invalid_entry')).nullable(),
    Zip: yup.string().matches(validateCanadianZip, t('Invalid_entry')),
    NewbornBD: yup
      .date()
      .nullable()
      .when('IsNewborn', {
        is: true,
        then: yup
          .date()
          .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
          .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.date().nullable()
      }),
    IsNewborn: yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: true,
    initialValues: isNew && newPatientBlankRecord(DCNGuid, patientPickerQueryValue),
    validationSchema,
    onSubmit
  });

  // Formik initial values
  useEffect(() => {
    const fetchData = async () => {
      const initialValues = await formikInitValues();
      formik.resetForm({ values: initialValues });
    };

    fetchData();
  }, []);

  // Set initial PHN or LastName value from patientPickerQueryValue
  useEffect(() => {
    if (patientPickerQueryValue) {
      const isPHNNumbersOnly = integersOnlyCheckForString.test(patientPickerQueryValue);
      if (isPHNNumbersOnly) {
        formik.setFieldValue('PHN', patientPickerQueryValue);
      } else formik.setFieldValue('LastName', patientPickerQueryValue?.toUpperCase());
    }
  }, [patientPickerQueryValue]);

  //initial provinces list for create new patient
  useEffect(() => {
    //Reset eligibility message
    isMobile && window[`scrollTo`]({ top: 0 });
    if (isNew) dispatch(setEligibility({}));
  }, []);

  //scroll to first invalid field
  useEffect(() => {
    setFormikFocusToInvalidInput(formik);
  }, [formik]);

  //component unmount
  useEffect(() => {
    return () => {
      //Reset eligibility message
      dispatch(setEligibility({}));
      dispatch(setActiveTabIndexForPatientForm(0));
      dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
      patientPickerQueryValue && dispatch(setPatientPickerQueryValue('')); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen
    };
  }, []);

  const addNewPatientForNewClaim = (patient) => {
    const formatted = formatPatientInfoForClaims(patient);

    if (!isEmpty(patient)) {
      switch (showNewPatientSuccessPage?.from) {
        case 'teleplanClaim':
          dispatch(
            updateTeleplanInvoice({
              ...teleplanInvoice,
              formState: {
                ...teleplanInvoice.formState,
                [teleplanInputs.patient.name]: [...teleplanInvoice.formState[teleplanInputs.patient.name], formatted]
              }
            })
          );
          break;

        case 'privateClaim':
          dispatch(updatePrivateRecord({ [commonInputs.patients.descriptionName]: [formatted] }));
          onBillToUpdate([formatted], dispatch); // Add Bill To value
          break;

        case 'WSBCReport':
          dispatch(
            updateWSBCRecord({
              ...wsbcRecord,
              resetWSBCR: true, // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
              formState: { ...wsbcRecord.formState, [wsbcInputs.patient.name]: [formatted] }
            })
          );
          break;

        default:
          break;
      }
    }
  };

  const checkPatientDuplicate = ({ onPHNChange = false, callback, patientData }) => {
    let partialPatientDetails = {
      DCNGuid: formik.values.DCNGuid,
      PHN: patientData?.PHN || formik.values.PHN,
      PHNProvince: patientData?.PHNProvince || formik.values.PHNProvince,
      FirstName: formik.values.FirstName,
      MidName: formik.values.MidName,
      LastName: formik.values.LastName,
      FullName: formik.values.FullName,
      Sex: formik.values.Sex,
      DOB: formik.values.BirthDay
    };

    if (!isNew) partialPatientDetails = { ...partialPatientDetails, PatientGuid: formik.values.PatientGuid };

    dispatch(
      duplicatePatientCheck(partialPatientDetails, (responseData) => {
        if (responseData?.length) {
          setDuplicatedPatients(responseData);
          if (onPHNChange) {
            // on PHN update
            setShowPHNDuplicateDialog(true);
            setDisableSaveButton(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
            setIsPHNDuplicate(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          } else {
            // on "Create"/"Save" click
            if(!autofillTriggered) {
              setShowPatientDuplicateDialog(true);
            }
          }
          callback(responseData);
        } else {
          setDisableSaveButton(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          setIsPHNDuplicate(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          if (!onPHNChange) {
            // on "Create"/"Save" click
            // if no duplicate
            callback();
          }
        }
      })
    );
  };

  const onExit = () => {
    if (props.onClose) return props.onClose();
    formik.resetForm();
    navTo();
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty: formik.dirty });
  };

  const onHideDuplicatePatientsDialog = () => {
    setShowPatientDuplicateDialog(false);
    setDuplicatedPatients([]);
  };

  const onHideDuplicatePHNDialog = () => {
    setShowPHNDuplicateDialog(false);
    setIsPHNDuplicate(false);
    if(!removeFocusPhn) {
      phnRef?.current?.focus();
    }
    setRemoveFocusPhn(false);
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small id={`${elementIDs.mandatoryField}_${name}`} className="p-error">
          {formik.errors[name]}
        </small>
      )
    );
  };

  const onClose = () => {
    if(fromSuccessPage) {
      setStep(1);
    } else {
    if (props.onClose) return props.onClose();
    return navTo();
    }
  };

  const navTo = () => {
    query.get('replaceTo') ? history.replace(query.get('replaceTo')) : history.goBack();
  };

  const demographicsFormLayout = (
    <Demographics
      isNew={isNew}
      phnRef={phnRef}
      formik={formik}
      isDateValid={isDateValid}
      isPHNValid={isPHNValid}
      isPHNDuplicate={isPHNDuplicate}
      disabledPHN={disabledPHN}
      setIsPHNDuplicate={setIsPHNDuplicate}
      setShowPHNDuplicateDialog={setShowPHNDuplicateDialog}
      setIsDateValid={setIsDateValid}
      setIsPHNValid={setIsPHNValid}
      setIsPHNFocused={setIsPHNFocused}
      isFormFieldValid={isFormFieldValid}
      getFormErrorMessage={getFormErrorMessage}
      checkPatientDuplicate={checkPatientDuplicate}
      autofillTriggered={autofillTriggered}
    />
  );

  const addressFormLayout = <Address formik={formik} isFormFieldValid={isFormFieldValid} getFormErrorMessage={getFormErrorMessage} />;

  // Action buttons
  const actionButtons = (
    <div
      className={cx('flex col-12 align-items-end px-0 py-3 stickyActionButtons', isMobile ? '' : 'mt-auto mb-0')}
      style={{ marginBottom: isMobile ? '' : '16px' }}
    >
      <div className={cx('field flex col-12 p-0')}>
        {formik.dirty ? (
          <>
            <Button
              form="patient-form"
              id={isNew ? elementIDs.createNewPatient : elementIDs.saveChanges}
              className="mr-2"
              type="submit"
              loading={isCreatingPatient || isSavingPatientDetails}
              disabled={
                isPHNFocused || // VER-617 - [OB] I suggest that we show that Save & Close is disabled as long as the cursor is in PHN
                isFetchingPHNValidation ||
                checkingPatientDuplicates ||
                !isDateValid ||
                (!isNew && disableSaveButton && formik.values.PHN?.length > 0)
              }
              label={isNew ? t('Create_Record') : t('Save_and_Close')}
            />
            <Button
              id={!formik.dirty && (iPadInPortrait || isMobileOnly) ? elementIDs.close : elementIDs.cancel}
              className="p-button-outlined ml-2"
              type="button"
              label={!formik.dirty && (iPadInPortrait || isMobileOnly) ? t('Close') : t('Cancel')}
              onClick={onClose}
            />
          </>
        ) : (
          <Button className='p-button-outlined' id={elementIDs.close} type="button" label={t('Close')} onClick={onClose} />
        )}
      </div>
    </div>
  );

  const headerCloseButton = (
    <div className={classes.headerContentLeft}></div>
    // <Button
    //   className="p-button-text p-button-rounded"
    //   type="button"
    //   icon="pi pi-times"
    //   onClick={() => navTo()}
    // />
  );

  const headerContentLeft = <div className={classes.headerContentLeft}></div>;

  const patientsName = () => {
    return patientFullNameWithAge(patient_details);
  };

//--------------------------------- Autofill ---------------------------------//

  useEffect(() => {
    if (location.state?.activateAutofill) {;
      // autofillButtonRef.current?.click(); 
      setShowForm(false);
      onAutofillClick();
    }
  }, [location.state]);
  
  // const isFetchingPrevious = useRef(isFetchingPatientEligibility);

  // useEffect(() => {
  //   // Check if isFetchingPatientEligibility changed from true to false
  //   if (isFetchingPrevious.current && !isFetchingPatientEligibility && loading) {
  //     setLoading(false);
  //   }
  //   // Update the ref to the current value
  //   isFetchingPrevious.current = isFetchingPatientEligibility;
  // }, [isFetchingPatientEligibility, loading]);

  // const loadingPrevious = useRef(loading);

  // useEffect(() => {
  //   // Detect when loading changes from true to false
  //   if (loadingPrevious.current && !loading) {
  //     setTimeout(() => {
  //       setShowForm(true);
  //     }, 300);
  //   }
  //   // Update ref to the current loading state
  //   loadingPrevious.current = loading;
  // }, [loading]);


  useEffect(() => {
    if (autofillTriggered) {
        setTimeout(() => {
            formik.setFieldTouched('PHN', true);
            formik.setFieldValue('PHN', formik.values.PHN); // Re-set the value explicitly
        }, 50);
    }
}, [autofillTriggered, formik]);

  useEffect(() => {
    if (errorAutofillMessage && (errorAutofillType === 'error' || errorAutofillType === 'warn' || errorAutofillType === 'info')) {
      setErrorMessage(errorAutofillMessage);
      console.log('Error message:', errorAutofillMessage);
    }
    if(errorAutofillMessage === ''){
      setErrorMessage(null);
    }
  }, [errorAutofillMessage]);


  useEffect(() => {
    if(showImage){
      setShowForm(true);
    }
  }, [showImage]);


  let sessionGuid = null;

  // Function to start a new scanning session
  const startNewScanningSession = () => {
      sessionGuid = randomUUID();
      console.log('New SessionGuid created:', sessionGuid);
  };

  // useEffect(() => { //abort autofill after 10 seconds
  //   let timeoutId;
  
  //   if (imageTimer) {
  //     timeoutId = setTimeout(() => {
  //       if (loading) { // Check if still loading
  //         setShowImage(true);
  //         dispatch(setToastMessage({ 
  //           type: 'warn', 
  //           message: 'Consider aborting and cropping if processing is too long.' 
  //         }));
  //       }
  //       setImageTimer(false); // Reset timer state
  //     }, 10000);
  //   }
  
  //   // Clean up the timer on unmount or when `loading` changes
  //   return () => clearTimeout(timeoutId);
  // }, [imageTimer, loading]); // Trigger the effect when these states change
    

  const onAutofillClick = async () => {
    if (loading) {
        // If already loading, you might want to return or show a message
        return;
    }
    
    // Create a new AbortController to manage the abort functionality
    const controller = new AbortController();
    setAbortController(controller);
    console.log('AbortController created:', controller);

        
    try {
        // Call the handleAutofill function with the abort signal
        startNewScanningSession();
        await handleAutofill(
            formik,
            setExtractedText,
            dispatch,
            setImage,
            setShowImage,
            setIsPHNValid,
            croppedImage,
            setLoading,
            setDisableAbortButoon,
            setImageTimer,
            DCNGuid,
            null,
            null,
            sessionGuid,
            // { signal: controller.signal }
        );
        setCroppedImage(null); // Reset the cropped image
        setAutofillTriggered(true);
    } catch (error) {
      setErrorMessage(error);
        if (error.name === 'AbortError') {
            console.log('Autofill aborted');
        } else {
            console.error('Error during autofill:', error);
        }
    } finally {
      console.log('Resetting loading state and controller');
        setImageTimer(false); // Reset the timer state
        setLoading(false); // Reset loading state
        // setShowForm(true); // Show the form again
        setAbortController(null); // Clear the abort controller
        // if(image){
        //   await uploadImage(DCNGuid, image, sessionGuid); // Call the upload function
        // }
    }
};

useAutofill(formik, setLoading, setShowImage ,extractedText, autofillTriggered, setAutofillTriggered, checkPatientDuplicate, setNameFromEligibility, setNameFromPicture, setShowPHNDuplicateDialogAction, setRemoveFocusPhn, phnRef);


useEffect(() => {
  if (!isNew) {
    console.log('Name from Eligibility:', `${eligbilityFromEdit?.LAST_NAME?.trim()}, ${eligbilityFromEdit?.FIRST_NAME?.trim()}`);
    console.log('Name from Label:', nameFromLabelAfterEdit);

    setNameFromEligibility(`${eligbilityFromEdit?.LAST_NAME?.trim()}, ${eligbilityFromEdit?.FIRST_NAME?.trim()}`);
    setNameFromPicture(nameFromLabelAfterEdit);
  }
}, []);


const openImageDialog = () => {
  setIsImageDialogVisible(true); // Show the dialog
};

// Function to close the image dialog
const closeImageDialog = () => {
  setIsImageDialogVisible(false); // Hide the dialog
};

// const toggleQRCodeDialog = () => {
//   setShowQRCodeDialog(!showQRCodeDialog);
//   console.log('qrCodeURL:', qrCodeURL);
// };

const headerActionButton = (
    <div className='flex'>
          <Tooltip 
        target=".info-button" 
        position='top' 
        content="Click to see an example of a good photo."
    />
      {/* <Button
        label="QR"
        icon="pi pi-qrcode"
        className="qr-code-button"
        onClick={toggleQRCodeDialog}
        style={{ marginRight: '1rem' }}
      /> */}
        <Tooltip target=".autofill-button" position='top' content="Ensure good lighting and keep the camera steady while taking a picture." />
        <Button
            ref={autofillButtonRef} 
            label={loading ? 'Loading' : (isMobileOnly ? 'Label' : 'Upload Label')}
            className={`autofill-button`}
            onClick={onAutofillClick}
            type = 'Submit'
            icon={loading ? 'pi pi-spin pi-spinner' : (isMobileOnly ? 'pi pi-camera' : 'pi pi-upload')}
            iconPos="left"
            visible={isNew}
            data-cy="autofill-button"
        />
        {/* {loading && abortController && ( // Ensure abortController is available
            <Button
                label="Abort"
                icon = "pi pi-times"
                className="p-button-danger p-button-sm"
                disabled={disableAbortButoon} 
                onClick={() => abortController.abort()} // Call abort on click
                style={{ marginLeft: '5px' }} // Optional styling
            />
        )} */}
        {showExample && (
            <Dialog 
            header="Good Photo Example" 
            visible={showExample} 
            onHide={() => setShowExample(false)}
            style={{ width: '40vw' }} breakpoints={{'1320px': '50vw','960px': '75vw', '641px': '100vw' }}
        >
        <img
            src={exampleImage}
            alt="Good Photo Example"
            style={{
                maxWidth: '100%', // Ensure responsiveness
                height: 'auto', // Maintain aspect ratio
                marginBottom: '15px', // Space between image and text
                borderRadius: '8px', // Rounded corners for a modern look
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
            }}
        />
            <p style={{ 
                fontSize: '16px', 
                lineHeight: '1.5', 
                color: '#555', 
                margin: '0 10px',
                textAlign: 'justify' 
            }}>
                <strong>Tip:</strong> Make sure the label is flat, 
                well-lit, and fills the frame. Avoid glare by adjusting the light, 
                and keep the camera steady to capture a clear image.
                <br/>
                Only works for labels with a valid BC PHN.
            </p>
        </Dialog>
    )}
    </div>
);
  useEffect(() => {
    if (croppedImage) {
      setImageToAutofill(croppedImage);  // Update the state with the cropped image
    }
  }, [croppedImage]);  // Listen for changes in croppedImage

  // Trigger autofill when imageToAutofill is set
  useEffect(() => {
    if (imageToAutofill) {
      onAutofillClick();
    }
  }, [imageToAutofill]);  // Listen for changes in the image to trigger autofill




  //--------------------------------- Autofill END ---------------------------------//

  const cardTitle = isNew ? t('New_Patient') : isMobileOnly && !isLandscape ? t('Edit_Profile') : `${t('Edit_Profile')}: ${patientsName()}`;

  const dialogLayout = (
    <>
      {!process.env.REACT_APP_CYPRESS && (
        <TabView
          className="createNewPatientFormTabView block md:hidden"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      )}

      <div className="hidden md:flex">
        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Demographics')}</div>
          <div className="flex flex-column justify-content-between h-full">
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </div>
        </div>

        <Divider layout="vertical" />

        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Address')}</div>
          <div className="grid mt-1">{addressFormLayout}</div>
          {actionButtons}
        </div>
      </div>
    </>
  );

  const pageLayout = (
    <>
      {isMobile ? (
        <TabView
          className="createNewPatientFormTabView"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      ) : (
        <div className="flex">
          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Demographics')}</div>
            <div className="flex flex-column justify-content-between h-full">
              <div className="grid mt-1">{demographicsFormLayout}</div>
            </div>
          </div>

          <Divider className={classes.verticalDevider} layout="vertical" />

          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Address')}</div>
            <div className="grid mt-1">{addressFormLayout}</div>
            {actionButtons}
          </div>
        </div>
      )}
    </>
  );

  const formLayout = (
    <div style={{ position: 'relative' }}>
    {/* Overlay to block interaction when loading or fetching */}
    {(loading || isFetchingPatientEligibility) && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent overlay
          zIndex: 1,
        }}
      />
    )}
    <form id="patient-form" onSubmit={formik.handleSubmit} className="p-fluid mt-2">
      <div className="col-12 p-0">
        {isMobileOnly && !isLandscape && !isNew && <div className="flex mb-3 justify-content-center">{patientsName()}</div>}
      </div>

      {props.showInDialog ? dialogLayout : pageLayout}

      {/* Mobile view action buttons */}
      {isMobile && actionButtons}
    </form>
    </div>
  );

  useEffect(() => {
    console.log('Show form:', showForm);
  }, [showForm]);

  const formWrap = () => {
    if (!showInCard) return formLayout;

    return (
      <>
      {showForm && (
      <PatientFormCard
        title={cardTitle}
        titleClassName={`flex align-items-center ${isNew ? 'justify-content-center' : 'justify-content-center'} pb-4`}
        rootClassName={isMobile ? classes.newPatientFormMobile : classes.newPatientFormDesktop}
        // headerActionButton={isNew}
        headerContentLeft={headerActionButton}
        spacer={isNew}
        showForm = {showForm}
        // headerContentLeft={isMobileOnly && !isLandscape && headerContentLeft} // align card title at the center on mobile devices
      >
        {showImage && (
          <div className='pb-2'>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '20px',
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <h3 style={{
                fontSize: '1.2rem',
                marginBottom: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333'
              }}>Uploaded Image</h3>
              <div style={{
                width: "100%",
                height: "100%",
                position: 'relative',
                display: 'flex', // Ensures Flexbox layout
                paddingTop: '2rem',
                paddingBottom: '2rem',
                justifyContent: 'center', // Horizontal centering
                alignItems: 'center', // Vertical centering
                overflow: 'hidden', // Prevents overflow of the image
                cursor: 'pointer', // Change cursor to pointer on hover

              }}
               onClick={() => openImageDialog(image)}>
                <img
                  src={image}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '400px',
                    objectFit: 'contain', // Ensures the image scales without distortion
                    margin: '0', // Remove any default margin on the image

                  }} />
              </div>
            </div>
            {errorMessage && (
            <div className={`flex w-full items-center justify-center px-3 py-3 bg-yellow-100 text-yellow-600`} style={{ height: 'auto', borderRadius: '8px', fontWeight: 'bold', marginTop: '10px' }}>
              <i className={`pi pi-exclamation-triangle mr-2`} style={{ fontSize: '1.5rem' }}></i>
              <div>
                <p>{errorMessage}</p>
                {/* <p className="text-sm mt-1">
                  {errorAutofillType === 'error'
                    ? 'Ensure the label is clearly visible and positioned properly within the frame.'
                    : 'Verify that all data is legible with good lighting, and the label is fully within the frame.'}
                </p> */}
              </div>
            </div>
            )}
          </div>
        )}

        {formLayout}
      </PatientFormCard>
      )}
      {!showForm && (
  <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '80vh',
      textAlign: 'center',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Center content vertically
        flexGrow: 1 // Takes available space in the column layout
      }}>
        <h2 style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          marginBottom: '15px',
          color: '#333'
        }}>New Patient</h2>
        <p style={{
          color: '#666',
          maxWidth: '400px',
          lineHeight: '1.7',
          marginBottom: '30px'
        }}>
          Take a picture of the label or upload it from your Gallery. If you don't see the option to select the Camera or browse your Gallery, change permissions for this browser and allow it to access your Camera and Gallery.
          <br />
          <span style={{ display: 'inline-block', marginTop: '15px' }}>
            Ensure that the image you upload is clear, well-lit, and contains all necessary information.
          </span>
        </p>
        <Button
          label='Upload Label'
          icon={loading ? 'pi pi-spin pi-spinner' : (isMobileOnly ? 'pi pi-camera' : 'pi pi-upload')}
          onClick={onAutofillClick}
          style={{
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: '500',
            borderRadius: '8px',
            marginBottom: '20px'
          }}
          className="p-button-raised p-button-primary"
        />
        <Button
          label="Enter Manually"
          onClick={() => setShowForm(true)}
          style={{
            fontSize: '0.9rem',
          }}
          className="p-button-text"
        />
      </div>

      {/* Close Button Anchored at the Bottom */}
      <div style={{
        alignSelf: 'center', // Center horizontally
        marginTop: 'auto',   // Keeps this div at the bottom of the container
        paddingTop: '20px'   // Optional: add spacing above the Close button
      }}>
        <Button
          className="p-button-outlined"
          width="100%"
          type="button"
          label="Close"
          onClick={() => history.goBack()}
        />
      </div>
    </div>
  </>
)}
      </>

    );
  };

  const whenPrompt = showPrompt && formik.dirty && displayPrompt;

  if (!formik.values) return <CircularProgress />;


  return (
    <div id={isNew ? elementIDs.createPatientRootContainer : elementIDs.editPatientRootContainer}>
      <Prompt when={whenPrompt} message={promptMessage} />


      {/* CircularProgress Overlay */}
      {(loading || isFetchingPatientEligibility) && (
          <CircularProgress />
      )}

      {isFetchingPatients && <CircularProgress />}

      {step === 0 && <div className="flex justify-content-center contentOutsidePaddings">{formWrap()}</div>}

      {step === 1 && (
        <Success
          patientDetails={patientDetails}
          eligibility={isNew? eligibility : eligbilityFromEdit}
          showNewPatientSuccessPage={showNewPatientSuccessPage}
          setStep={setStep}
          addNewPatientForNewClaim={addNewPatientForNewClaim}
          image={image}
          nameFromEligibility={nameFromEligibility}
          nameFromPicture={nameFromPicture}
          setShowForm={setShowForm}
          onAutofillClick={onAutofillClick}
          setShowImage={setShowImage}
          setImage={setImage}
          isNew={isNew}
        />
      )}

      <DuplicatePHNDialog
        visible={showPHNDuplicateDialog}
        duplicatedPatientsData={duplicatedPatients[0]}
        addNewPatientToNewClaim={addNewPatientToNewClaim}
        handleExistingProfile={handleExistingProfile}
        onHide={onHideDuplicatePHNDialog}
        onExit={onExit}
      />

      <DuplicatePatientsDialog
        visible={showPatientDuplicateDialog}
        addNewPatientToNewClaim={addNewPatientToNewClaim}
        duplicatedPatientsList={duplicatedPatients}
        onHide={onHideDuplicatePatientsDialog}
        onSubmit={() => onSubmit(formik.values, false)}
        handleExistingProfile={handleExistingProfile}
      />

    <Dialog
      header="Uploaded Image"
      visible={isImageDialogVisible}
      style={{ width: '70vw', maxWidth: '800px' }} // Default dialog size
      headerStyle={{
        textAlign: 'center', // Center-align the header text
      }}
      onHide={closeImageDialog} // Close the dialog
      breakpoints={{
        '960px': '90vw', // For screens smaller than 960px, set the dialog width to 90% of the viewport width
        '640px': '100vw', // For screens smaller than 640px, set the dialog width to 100% of the viewport width
      }}
    >
        <div className='flex justify-content-center pb-6'>
          <img
            src={image}
            style={{
              maxWidth: '100%',
              maxHeight: '600px',
              objectFit: 'contain', // Ensures the image scales without distortion
              margin: '0', // Remove any default margin on the image

            }} />
        </div>
        <div className='flex justify-content-center'>
          <Button label='Close' onClick={closeImageDialog}/>
        </div>

      </Dialog>


      {/* QR Code Dialog */}
      {/* <Dialog
        header="QR Code for Upload Page"
        visible={showQRCodeDialog}
        onHide={toggleQRCodeDialog}
        style={{ width: '30vw' }}
      >
        <div className="flex justify-content-center">
          <QRCodeCanvas value={qrCodeURL} size={200} />
        </div>
        <p style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
          Scan the QR code to visit the upload page.
        </p>
      </Dialog> */}
    </div>
  );
};

export default PatientForm;
