import React, { useState } from 'react';

const IframeWithLoader = ({ url }) => {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false); // Hide the loader once iframe is loaded
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Loader */}
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: -350,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff', // Optional: cover iframe with white background
            zIndex: 1
          }}
        >
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
          <div className="text-3xl">Loading...</div>
        </div>
      )}
      {/* Iframe */}
      <iframe
        src={url}
        style={{
          width: '100%',
          height: '80vw',
          border: 'none',
          position: 'relative',
          zIndex: 0
        }}
        title="Preview"
        onLoad={handleIframeLoad} // Attach onLoad event
      />
    </div>
  );
};

export default IframeWithLoader;
