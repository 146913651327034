import React from 'react';
import { Button } from 'primereact/button';
import { isWeekendOrHoliday } from '../../../../../../utils/formatDate';
import moment from 'moment';
import cx from 'classnames';

const RowGroupHeaderTemplate = ({ rowData }) => {
  const serviceDate = rowData?.service_date;
  const isWeekend = isWeekendOrHoliday(serviceDate);
  return (
    <div className="flex">
      <div className="hidden lg:flex" style={{ minWidth: '40px', width: '40px' }}></div>
      <Button
        label={moment(serviceDate).format('MMM D, YYYY')}
        rounded
        outlined
        className={cx(isWeekend ? 'p-button-danger' : '', 'cursor-auto')}
        style={{ height: '22px', width: 'max-content' }}
      />
    </div>
  );
};

export default RowGroupHeaderTemplate;
