import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';

import FeeEditor from './templates/FeeEditor';
import TableHeader from './templates/TableHeader';
import UnitsEditor from './templates/UnitsEditor';
import DxCodeEditor from './templates/DxCodeEditor';
import UnitsTemplate from './templates/UnitsTemplate';
import PercentEditor from './templates/PercentEditor';
import EndTimeEditor from './templates/EndTimeEditor';
import DxBodyTemplate from './templates/DxBodyTemplate';
import BilledTemplate from './templates/BilledTemplate';
import PercentTemplate from './templates/PercentTemplate';
import FeeBodyTemplate from './templates/FeeBodyTemplate';
import StartTimeEditor from './templates/StartTimeEditor';
import TimeBodyTemplate from './templates/TimeBodyTemplate';
import RowActionButtons from './templates/RowActionButtons';
import FeeCodeBodyTemplate from './templates/FeeCodeBodyTemplate';
import RowExpansionTemplate from './templates/RowExpansionTemplate';
import DurationBodyTemplate from './templates/DurationBodyTemplate';
import RowNumberBodyTemplate from './templates/RowNumberBodyTemplate';
import RowGroupHeaderTemplate from './templates/RowGroupHeaderTemplate';
import RecordsActionButtons from '../ActionButtons/RecordsActionButtons';
import UnitsCalculationBodyTemplate from './templates/UnitsCalculationBodyTemplate';

import { inputs } from '../../helpers/inputs';
import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { calculateGroupTotal } from '../../helpers/calculateGroupTotal';
import { useUpdateRecords } from '../../hooks/useUpdateRecords';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { validateOffHours } from '../../helpers/validationSchema';
import { showUnitsCalculationText } from '../../helpers/isUnitsCalculationText';
import { t } from '../../../../../../service/localization/i18n';

const RecordsDataTable = () => {
  const dispatch = useDispatch();
  const { watch, isGroup, localState } = useFormContext();
  const { noteRequirementValidation, unitsRequirementValidation } = useUpdateRecords();
  const records = localState.records;
  const isMobile = window.innerWidth < 960;
  const isUnitsCalculationText = showUnitsCalculationText(records);

  const columnStyle = (minWidth) => ({ minWidth, width: minWidth, maxWidth: minWidth, height: '62px' });
  const actionButtonsColumnStule = () => {
    if (isMobile) return {};
    const width = '150px';
    return { width: width, minWidth: width, maxWidth: width, height: '62px' };
  };

  useEffect(() => {
    // [KS] Scroll to the top
    window[`scrollTo`]({ top: 0 });

    // VER-391 - Teleplan->incorrect validation of 00638
    // Set required notes
    records.forEach((record) => noteRequirementValidation({ rowData: record }));

    // Validate if units have 0 value
    unitsRequirementValidation();

    // CheckOffHours warn message
    validateOffHours(watch(inputs.feeCodes.name), watch(inputs.startTime.name), watch(inputs.serviceDate.name));

    // [KS] CMO-1202 - Create new claim - BE return empty claimList for some fee codes
    const recordsFeeCodes = records?.map((i) => i.fee_code);
    const missingFeeCodes = watch(inputs.feeCodes.name)
      .filter((i) => recordsFeeCodes.indexOf(i) === -1)
      ?.join()
      ?.replaceAll(',', ', ');
    if (!!missingFeeCodes) {
      const _message = String.format(t('Code_is_not_eligible_for_this_service'), missingFeeCodes);
      dispatch(setToastMessage({ type: 'warn', message: _message, lifeTime: 10000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footerStyle={{ width: '40px' }} />
        <Column footerStyle={{ width: '75px' }} />
        <Column footerStyle={{ width: '8ß5px' }} />
        <Column footerStyle={{ width: '8ß5px' }} />
        <Column footerStyle={{ width: '85px' }} />
        <Column footerStyle={{ width: '85px' }} />
        {isUnitsCalculationText && <Column footerStyle={{ width: '140px' }} />}
        <Column footerStyle={{ width: '80px' }} />
        <Column footer={`${t('Total')}:`} footerStyle={{ textAlign: 'right', width: '75px' }} />
        <Column
          footerStyle={{ width: '85px' }}
          footer={<div className="flex justify-content-end" style={{ marginRight: '25%' }}>{`${currencyFormat(calculateGroupTotal(records))}`}</div>}
        />
        <Column footerStyle={{ width: '300px' }} />
        <Column footerStyle={{ width: '150px' }} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="flex justify-content-center p-3 w-full">
      <div className="card m-0 page-table-card w-full px-4 pt-4 pb-0 max-w-min">
        <div id={elementIDs.recordsDataTableWrap} style={{ height: 'calc(100vh - 100px)' }}>
          <DataTable
            className="teleplan_records_data_table"
            dataKey="id"
            editMode="cell"
            scrollDirection="both"
            responsiveLayout={isMobile ? 'stack' : 'scroll'}
            scrollable
            scrollHeight="flex"
            // scrollable={!isMobile}
            value={records}
            header={<TableHeader />}
            emptyMessage={t('No_records_found')}
            footerColumnGroup={footerGroup}
            expandedRows={records}
            rowExpansionTemplate={(e) => <RowExpansionTemplate data={e} />}
            rowGroupMode="subheader"
            groupRowsBy="service_date"
            rowGroupHeaderTemplate={(rowData) => <RowGroupHeaderTemplate rowData={rowData} />}
            footer={<RecordsActionButtons />}
          >
            <Column
              frozen
              alignFrozen="left"
              style={columnStyle('40px')}
              header="No."
              body={(rowData, { rowIndex }) => <RowNumberBodyTemplate rowData={rowData} rowIndex={rowIndex} />}
            />

            <Column field="fee_code" header={t('Code')} style={columnStyle('75px')} body={(rowData) => <FeeCodeBodyTemplate rowData={rowData} />} />

            <Column
              field="service_start"
              header={t('From')}
              style={columnStyle('85px')}
              editor={(options) => <StartTimeEditor options={options} />}
              body={(rowData) => <TimeBodyTemplate rowData={rowData} field="service_start" />}
            />

            <Column
              field="service_finish"
              header={t('To')}
              style={columnStyle('85px')}
              editor={(options) => <EndTimeEditor options={options} />}
              body={(rowData) => <TimeBodyTemplate rowData={rowData} field="service_finish" />}
            />

            <Column
              field="duration"
              header={t('Duration')}
              style={columnStyle('85px')}
              editor={(options) => <DurationBodyTemplate rowData={options.rowData} />}
              body={(rowData) => <DurationBodyTemplate rowData={rowData} />}
            />

            <Column
              field="units"
              header={t('Units')}
              style={columnStyle('85px')}
              editor={(options) => <UnitsEditor options={options} />}
              body={(rowData) => <UnitsTemplate rowData={rowData} />}
            />

            {isUnitsCalculationText && (
              <Column field="unitsCalculation" style={columnStyle('165px')} body={(rowData) => <UnitsCalculationBodyTemplate rowData={rowData} />} />
            )}

            <Column
              field="fee"
              header={t('Fee')}
              style={columnStyle('80px')}
              editor={(options) => <FeeEditor options={options} />}
              body={(rowData) => <FeeBodyTemplate rowData={rowData} />}
            />

            <Column
              field="percent"
              header="%"
              style={columnStyle('75px')}
              editor={(options) => <PercentEditor options={options} />}
              body={(rowData) => <PercentTemplate rowData={rowData} />}
            />

            <Column
              field="billed"
              header={t('Billed')}
              style={columnStyle('85px')}
              editor={(options) => <BilledTemplate rowData={options.rowData} />}
              body={(rowData) => <BilledTemplate rowData={rowData} />}
            />

            {!isGroup && (
              <Column
                field="icd9"
                header={t('Dx')}
                style={columnStyle('300px')}
                editor={(options) => <DxCodeEditor options={options} />}
                body={(rowData) => <DxBodyTemplate rowData={rowData} />}
              />
            )}

            <Column
              frozen
              alignFrozen="right"
              body={(rowData, { rowIndex }) => <RowActionButtons rowOptions={{ rowData, rowIndex }} />}
              style={actionButtonsColumnStule()}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default RecordsDataTable;
