import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';

import { localStorageKeys } from '../../../../../../../../config/localStorageKeysConfig';
import { t } from '../../../../../../../../../service/localization/i18n';

const ArrowsReorder = () => {
  const { localState, setLocalState } = useFormContext();

  const onChange = (e) => {
    setLocalState((prevState) => ({ ...prevState, isArrowsRowReorder: e.value }));
    localStorage.setItem(localStorageKeys.batchArrowsReorder, JSON.stringify(e.value));
  };

  return (
    <div className="flex align-items-center justify-content-between">
      <label htmlFor="arrowsReorder">
        {t('Arrows_reorder')}
        <Tooltip target=".arrowsReorderTooltip" position="top">
          {t('Switch_on_to_show_arrows_reorder')}
        </Tooltip>
        <i className="pi pi-question-circle arrowsReorderTooltip ml-3" />
      </label>
      <InputSwitch inputId="arrowsReorder" checked={localState.isArrowsRowReorder} onChange={onChange} />
    </div>
  );
};

export default ArrowsReorder;
