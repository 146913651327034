import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';

import PHN from '../../../../components/Inputs/PHN/PHN';
import CommonInputField from '../../../common/components/CommonInputField';

import { provinces_list } from '../../../common/contants';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { validatePHN } from '../../actions/patients.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import classes from './PHNInput.module.scss';
import cx from 'classnames';

const PHNInput = React.forwardRef(
  (
    {
      formik,
      inputWrapClassName,
      getFormErrorMessage,
      isPHNValid,
      setIsPHNValid,
      isPHNDuplicate,
      setIsPHNDuplicate,
      setShowPHNDuplicateDialog,
      checkPatientDuplicate,
      isFormFieldValid,
      ProvinceabIndex,
      resetDemographics,
      setIsPHNFocused,
      required = false,
      autoFocus = false,
      noGap = false,
      firstVisit = false,
      disabledPHN = false,
      autofillTriggered
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { isMobile } = useSelector((state) => state.core.window);
    const { isFetchingPHNValidation } = useSelector((state) => state.patients);
    const [prevPHNValue, setPrevPHNValue] = useState(formik.values.PHN?.replaceAll(' ', ''));

    // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
    const onPHNProvinceChange = (e) => {
      const value = formik.values.PHN?.length > 0 ? `${e.target.value}${formik.values.PHN}` : '';
      const dataForCheckDuplicate = { PHN: formik.values.PHN, PHNProvince: e.target.value };

      if (value?.length) {
        // [KS] CMO-1281 - Prevent creation of duplicate patient records (PHN check)
        dispatch(
          validatePHN(value, (isValid) => {
            setIsPHNValid(isValid);
            if (
              !firstVisit && // CMO-1736 - First visit->user is not able to change the province from BC
              isValid &&
              checkPatientDuplicate
            ) {
              checkPatientDuplicate({ onPHNChange: true, patientData: dataForCheckDuplicate });
            }
          })
        );
      }

      formik.handleChange(e);
    };

    // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
    const onBlur = (e) => {
      setIsPHNFocused?.(false);
      const value = e.target.value?.length > 0 ? `${formik.values.PHNProvince}${e.target.value}` : '';
      const isNewPHN = e.target.value?.replaceAll(' ', '') !== prevPHNValue;


      // Skip duplicate check if the PHN hasn't changed
      if (isPHNDuplicate && !isNewPHN) {
        setShowPHNDuplicateDialog && setShowPHNDuplicateDialog(true);
        return;
      }

      formik.handleBlur(e);
      const dataForCheckDuplicate = { PHN: e.target.value, PHNProvince: formik.values.PHNProvince };

      isPHNDuplicate && !isNewPHN && setShowPHNDuplicateDialog && setShowPHNDuplicateDialog(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)

      // [KS] CMO-1281 - Prevent creation of duplicate patient records (PHN check)
      formik.dirty &&
        isNewPHN &&
        dispatch(
          validatePHN(value, (isValid) => {
            setIsPHNValid(isValid);

            if (
              (isValid && isNewPHN && value?.length && checkPatientDuplicate) ||
              (firstVisit && isNewPHN && value?.length && checkPatientDuplicate) // CMO-1736 - First visit->user is not able to change the province from BC
            ) {
              checkPatientDuplicate({ onPHNChange: true, patientData: dataForCheckDuplicate });
            }

            if (!isValid && isNewPHN && value?.length && resetDemographics) {
              resetDemographics();
            }
          })
        );

      setPrevPHNValue(e.target.value?.replaceAll(' ', ''));
    };

    const onChange = (e) => {
      const phn = e.target.value;
      // const fullPHN = `${formik.values.PHNProvince}${phn}`;
      // const dataForCheckDuplicate = { PHN: phn, PHNProvince: formik.values.PHNProvince };
      isPHNDuplicate && setIsPHNDuplicate && setIsPHNDuplicate(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
      // setPrevPHNValue(e.target.value?.replaceAll(" ", ""));
      formik.handleChange(e);
      !phn?.length && !isPHNValid && setIsPHNValid(true);
      //  phn?.replaceAll(" ", "")?.length > 0 && debouncedRequest(fullPHN, setIsPHNValid, dataForCheckDuplicate);
    };

    // CMO-1637 - Why the last digit of PHN gets lost when I copy and paste it to MSP Eligibility on the home page?
    const onPaste = (e) => {
      formik.handleChange(e);
      formik.setFieldValue('PHN', e.clipboardData.getData('Text')?.trim());
    };

    // const debouncedRequest = useCallback(debounce((fullPHN, setIsPHNValid, dataForCheckDuplicate) => {
    //   // [KS] CMO-1281 - Prevent creation of duplicate patient records (PHN check)
    //   dispatch(validatePHN(fullPHN, (isValid => {
    //     setIsPHNValid(isValid);
    //     isValid && checkPatientDuplicate && checkPatientDuplicate({onPHNChange: true, patientData: dataForCheckDuplicate});
    //   })));
    // }, 1000), []);

    const onFocus = (e) => {
      formik.values.PHN === 'N/A' && e.target.select();
    };

    const listOfProvinces = () => {
      // CMO-1737 - Create claim & First Visit -> Remove QC from the list of provinces in PHN
      if (firstVisit) {
        const firstVisitList = provinces_list.filter((i) => i.value !== 'QC');
        return firstVisitList;
      }

      return provinces_list;
    };

    return (
      <>
        <CommonInputField label={t('PHN')} required={required} className={inputWrapClassName ? inputWrapClassName : ''}>
          <div className="flex">
            <div className={noGap ? '' : 'mr-2'}>
              <Dropdown
                className={noGap ? classes.provinceInput : ''}
                id="PHNProvince"
                name="PHNProvince"
                optionLabel="value"
                style={{ maxWidth: '77px' }}
                tabIndex={ProvinceabIndex && ProvinceabIndex}
                disabled={disabledPHN}
                options={listOfProvinces()}
                value={formik?.values?.PHNProvince || 'BC'}
                scrollHeight={dropdownScrollHeight()}
                onChange={(e) => onPHNProvinceChange(e)}
              />
            </div>
            <div key={`${autofillTriggered}`} style={{ width: '100%' }}> {/*added key to force re-render on PHN change*/}
              <span className="p-input-icon-right">
                {isFetchingPHNValidation && <i className="pi pi-spin pi-spinner" />}

                <PHN
                  ref={ref}
                  className={cx(
                    { 'p-invalid': isFormFieldValid('PHN') || (!isPHNValid && formik?.values?.PHN?.length > 0) },
                    noGap ? classes.PHNInput : ''
                  )}
                  name="PHN"
                  disabled={disabledPHN}
                  autoFocus={!isMobile && autoFocus}
                  value={formik?.values?.PHN}
                  province={formik?.values?.PHNProvince || 'BC'}
                  onChange={onChange}
                  onBlur={(e) => onBlur(e)}
                  onFocus={() => setIsPHNFocused?.(true)}
                  autofillTriggered={autofillTriggered}
                />
              </span>
              {!isPHNValid && formik?.values?.PHN?.length > 0 && (
                <small id={elementIDs.invalidPHN} className="p-error">
                  {t('Invalid_PHN')}
                </small>
              )}
              {getFormErrorMessage('PHN')}
            </div>
          </div>
        </CommonInputField>
      </>
    );
  }
);

export default PHNInput;
