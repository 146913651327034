import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { decryptData, encryptData, encryptKey } from '../../../../utils/crypto';

// Generic function to set form state and local state to local storage
export const setFormToLocalStorage = (key, formState, localState) => {
  const data = { formState, localState };
  const encryptedKey = encryptKey(key);
  const encryptedData = encryptData(data);
  localStorage.setItem(encryptedKey, encryptedData);
};

// Generic function to get form state from local storage
export const getFormFromLocalStorage = (key) => {
  const encryptedKey = encryptKey(key);
  const encryptedData = localStorage.getItem(encryptedKey);

  if (encryptedData) {
    try {
      const data = decryptData(encryptedData);
      return data;
    } catch (error) {
      console.error('Decryption failed:', error);
      // Optionally remove the corrupted data
      localStorage.removeItem(encryptedKey);
    }
  }
  return null;
};

// Generic function to reset form state from local storage
export const resetFormFromLocalStorage = (key) => {
  const encryptedKey = encryptKey(key);
  localStorage.removeItem(encryptedKey);
};

// Specific functions for BATCH form
export const setBatchFormToLocalStorage = (formState, localState) => {
  setFormToLocalStorage(localStorageKeys.batchFormState, formState, localState);
};

export const getBatchFormFromLocalStorage = () => {
  return getFormFromLocalStorage(localStorageKeys.batchFormState);
};

export const resetBatchFormFromLocalStorage = () => {
  resetFormFromLocalStorage(localStorageKeys.batchFormState);
};

// Specific functions for TELEPLAN form
export const setTeleplanFormToLocalStorage = (formState, localState) => {
  setFormToLocalStorage(localStorageKeys.teleplanFormState, formState, localState);
};

export const getTeleplanFormFromLocalStorage = () => {
  return getFormFromLocalStorage(localStorageKeys.teleplanFormState);
};

export const resetTeleplanFormFromLocalStorage = () => {
  resetFormFromLocalStorage(localStorageKeys.teleplanFormState);
};

// Specific functions for FIRST VISIT form
export const setFirstVisitFormToLocalStorage = (formState, localState) => {
  setFormToLocalStorage(localStorageKeys.firstVisitFormState, formState, localState);
};

export const getFirstVisitFormFromLocalStorage = () => {
  return getFormFromLocalStorage(localStorageKeys.firstVisitFormState);
};

export const resetFirstVisitFormFromLocalStorage = () => {
  resetFormFromLocalStorage(localStorageKeys.firstVisitFormState);
};

export const resetLocalStorageTeleplanForm = ({ isGroup, firstVisit }) => {
  if (!firstVisit && !isGroup) {
    resetTeleplanFormFromLocalStorage();
  }

  if (firstVisit) {
    resetFirstVisitFormFromLocalStorage();
  }

  if (isGroup) {
    resetBatchFormFromLocalStorage();
  }
};
