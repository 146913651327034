import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { OverlayPanel } from 'primereact/overlaypanel';
import cx from 'classnames';

const NumberRowLayout = ({ rowOptions }) => {
  const op = useRef(null);
  const { localState, setLocalState } = useFormContext();
  const recordsList = localState.groupRecords;

  // Move the current row to the selected index
  const moveRowToPosition = (newIndex) => {
    const currentIndex = rowOptions.rowIndex;
    const updatedRecords = [...recordsList];

    // Remove the item at the current index and insert it at the new index
    const [movedItem] = updatedRecords.splice(currentIndex, 1);
    updatedRecords.splice(newIndex, 0, movedItem);

    // Update the groupRecords in the state
    setLocalState((prevState) => ({
      ...prevState,
      groupRecords: updatedRecords,
      isOrderChanged: true
    }));

    op.current.hide();
  };

  return (
    <div className={cx('text-center', recordsList?.length > 1 && 'cursor-pointer')} onClick={(e) => recordsList?.length > 1 && op.current.toggle(e)}>
      <span className="font-bold">{rowOptions.rowIndex + 1}</span>

      <OverlayPanel ref={op}>
        <div className="flex flex-column gap-2">
          {recordsList.map((_, index) => {
            // Show all numbers except the current row index
            if (index !== rowOptions.rowIndex) {
              return (
                <span key={index} className="p-2 text-lg cursor-pointer hover:bg-gray-200" onClick={() => moveRowToPosition(index)}>
                  {index + 1}
                </span>
              );
            }
            return null;
          })}
        </div>
      </OverlayPanel>
    </div>
  );
};

export default NumberRowLayout;
