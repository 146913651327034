import { store } from '../../../../../store';
import { getClaimInfo, getCodesByType } from '../../../actions/claims.action.creators';
import { formatInitialValues } from './formatInitialValues';
import { getDefaultValues } from './defaultValues';
import { validateOffHours } from './validationSchema';
import { getRecentCodes, getPatientClaims, getPatientInfo, getPrefs } from '../../../../../service/Lookup';
import { inputs } from './inputs';
import { compact } from 'lodash';
import { getPromiseAll } from '../../../../utils/getPromiseAll';
import { addMissingCodesToRecentList } from './updateRecentCodesList';
import moment from 'moment';

export const getInitialValuesForEdit = ({ invoiceGuid, setLocalState, reset }) => {
  setLocalState((prevState) => ({ ...prevState, isLoading: true }));

  store.dispatch(getClaimInfo(invoiceGuid, (teleplanInvoice) => getClaimInfoCallback({ teleplanInvoice, setLocalState, reset })));
};

const getClaimInfoCallback = async ({ teleplanInvoice, setLocalState, reset }) => {
  const isGetInitForEdit = true;
  const patientGuid = teleplanInvoice?.PatientGuid;
  const DCNGuid = teleplanInvoice?.DCNGuid;
  const practitionerId = teleplanInvoice?.DoctorGuid;
  const speciality = teleplanInvoice?.Specialty;
  const feeCodes = teleplanInvoice?.FeeCode;
  const dxCode = teleplanInvoice?.ICD9Code;
  const dxCode2 = teleplanInvoice?.DiagnosticCode2;
  const dxCode3 = teleplanInvoice?.DiagnosticCode3;
  const aoiCode = teleplanInvoice?.AreaOfInjury;
  const noiCode = teleplanInvoice?.NatureOfInjury;
  const referralCode = teleplanInvoice?.RefNumber;

  const referralRequest = referralCode
    ? { referralCode: getCodesByType({ codeType: inputs.referral.codeType, query: referralCode, speciality, isGetInitForEdit }) }
    : {};
  const aoiRequest = aoiCode ? { aoiCode: getCodesByType({ codeType: inputs.aoi.codeType, query: aoiCode, speciality, isGetInitForEdit }) } : {};
  const noiRequest = noiCode ? { noiCode: getCodesByType({ codeType: inputs.noi.codeType, query: noiCode, speciality, isGetInitForEdit }) } : {};
  const dxCode2Request = dxCode2 ? { dxCode2: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode2, speciality, isGetInitForEdit }) } : {};
  const dxCode3Request = dxCode3 ? { dxCode3: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode3, speciality, isGetInitForEdit }) } : {};

  const requests = {
    ...referralRequest,
    ...aoiRequest,
    ...noiRequest,
    ...dxCode2Request,
    ...dxCode3Request,
    feeCodes: getCodesByType({ codeType: inputs.feeCodes.codeType, query: feeCodes, speciality, isGetInitForEdit }),
    dxCode: getCodesByType({ codeType: inputs.icd9.codeType, query: dxCode, speciality, isGetInitForEdit }),
    prefs: getPrefs(DCNGuid, practitionerId),
    patientClaims: getPatientClaims(patientGuid),
    patientInfo: getPatientInfo(patientGuid),
    mostRecentCodes: getRecentCodes(patientGuid)
  };

  const results = await getPromiseAll(requests);

  const practitionerPrefs = results?.prefs?.prefs?.find((i) => i.label === 'default');
  const patientInfo = results?.patientInfo;
  const patientClaims = results?.patientClaims;
  const feeCodesList = results?.feeCodes;
  const dxCodesList = getDxCodesList(teleplanInvoice, results);
  const aoiCodesList = aoiCode ? results?.aoiCode : [];
  const noiCodesList = noiCode ? results?.noiCode : [];
  const referralCodesList = referralCode ? results?.referralCode : [];
  const mostRecentCodes = results?.mostRecentCodes;

  const initValues = formatInitialValues({
    ...teleplanInvoice,
    [inputs.patient.name]: [patientInfo],
    [inputs.patientClaims.name]: patientClaims,
    [inputs.feeCodes.codeType]: feeCodesList,
    [inputs.icd9.name]: dxCodesList?.map((i) => i.value)?.join(','),
    [inputs.icd9.codeType]: dxCodesList,
    [inputs.aoi.codeType]: aoiCodesList,
    [inputs.noi.codeType]: noiCodesList,
    [inputs.referral.codeType]: referralCodesList
  });

  const formattedData = getDefaultValues({
    isNew: false,
    firstVisit: false,
    initValues
  });

  reset({
    ...formattedData,

    // WIN-460 - Application crashes when attempting to edit an ICBC claim with draft status in the invoices table
    // Initialize service date with a value from teleplanInvoice; set it as a single date, not an array
    [inputs.serviceDate.name]: moment(teleplanInvoice?.[inputs.serviceDate.name]).toDate()
  });

  setLocalState((prevState) => ({
    ...prevState,
    practitionerPrefs,
    initialDataForEdit: formattedData,
    isLoading: false
  }));

  // Update quick pick list
  addMissingCodesToRecentList({ formState: formattedData, recentCodesList: mostRecentCodes });

  // CheckOffHours warn message
  validateOffHours(initValues[inputs.feeCodes.name], initValues[inputs.startTime.name], initValues[inputs.serviceDate.name]);
};

export const getDxCodesList = (teleplanInvoice, results) => {
  const dxCode = teleplanInvoice?.ICD9Code;
  const dxCode2 = teleplanInvoice?.DiagnosticCode2;
  const dxCode3 = teleplanInvoice?.DiagnosticCode3;

  const icd9Code =
    dxCode && Array.isArray(results.dxCode) && results.dxCode
      ? results.dxCode?.find((i) => i.value?.replaceAll('.', '') === dxCode?.replaceAll('.', ''))
      : null;
  const icd9Code2 =
    dxCode2 && Array.isArray(results.dxCode2) && results.dxCode2
      ? results.dxCode2?.find((i) => i.value?.replaceAll('.', '') === dxCode2?.replaceAll('.', ''))
      : null;
  const icd9Code3 =
    dxCode3 && Array.isArray(results.dxCode3) && results.dxCode3
      ? results.dxCode3?.find((i) => i.value?.replaceAll('.', '') === dxCode3?.replaceAll('.', ''))
      : null;

  // Filter out empty objects
  const filteredArray = [icd9Code, icd9Code2, icd9Code3].filter((i) => i !== null);

  return compact(filteredArray);
};
