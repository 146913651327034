import { useFormContext } from 'react-hook-form';
import { useValidateGoupPicker } from './useValidateGroupPicker';
import { steps } from '../../../helpers/steps';
import { setBatchFormToLocalStorage } from '../../../helpers/formToLocalStorage';

export const useGroupActionButtons = () => {
  const {
    isStartTimeValid,
    isEndTimeValid,
    isUnitsValid,
    checkIsAgeValid,
    showInvalidUnitsDialog,
    isFeeValid,
    isTimeOverMidnight,
    getNotMatchDurationForPsychiatryCodesList
  } = useValidateGoupPicker();
  const { localState, setLocalState, getValues } = useFormContext();

  const handleNextForServicesPicker = ({ ignoreDurationValidatonForPsychiatry } = {}) => {
    const isStartValid = isStartTimeValid();
    const isEndValid = isEndTimeValid();
    const isValidUnits = isUnitsValid();
    const isValidFeeCodes = isFeeValid();
    const isValidAge = checkIsAgeValid();
    const notMatchDurationForPsychiatryCodes = getNotMatchDurationForPsychiatryCodesList();
    const showUnitsDialog = showInvalidUnitsDialog();

    if (showUnitsDialog) return setLocalState((prevState) => ({ ...prevState, unitsValidationDialog: { showDialog: true } }));

    if (isValidFeeCodes && isStartValid && isEndValid && isValidUnits && isValidAge) {
      if (!ignoreDurationValidatonForPsychiatry && notMatchDurationForPsychiatryCodes?.length) {
        setLocalState((prevState) => ({
          ...prevState,
          batchMatchDurationForFeeCodeDialog: {
            showDialog: true,
            options: { notMatchDurationForPsychiatryCodes }
          }
        }));
        return;
      }

      if (localState.groupRecords?.some((i) => isTimeOverMidnight(i))) {
        return setLocalState((prevState) => ({ ...prevState, isTimeOverMidnightDialog: true }));
      }

      // Show changed order dialog
      if (localState.isOrderChanged) {
        return setLocalState((prevState) => ({ ...prevState, changeOrderDialog: true }));
      }

      // Go to dx group step
      setLocalState((prevState) => {
        const updatedLocalState = { ...prevState, step: steps.groupDxPicker };
        // Save the updated form state to the local storage
        // setBatchFormToLocalStorage(getValues(), updatedLocalState);
        return updatedLocalState;
      });
    }
  };

  return { handleNextForServicesPicker };
};
