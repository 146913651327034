import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { editPatientProfile } from '../../../modules/patients/components/Patients/helpers/rowActionsMenuItems';
import { addNewWSBCR } from '../../../modules/claims/helpers/addNewInvoice';
import { setShowMissingPatientDataDialog } from '../../../modules/claims/actions/claims.action.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';

const MissingPatientData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patient_details } = useSelector((state) => state.patients);
  const { showMissingPatientDataDialog } = useSelector((state) => state.claims);

  const onEditProfile = () => {
    editPatientProfile(patient_details, history);
    onClose();
  };
  const ignoreForNow = () => addNewWSBCR({ history, patient_details });
  const onClose = () => dispatch(setShowMissingPatientDataDialog(false));

  const actionButtons = (
    <div className="flex flex-column sm:flex-row justify-content-end gap-2">
      <Button id={elementIDs.editPatientProfile} label={t('Edit_patients_profile')} onClick={onEditProfile} />
      <Button id={elementIDs.ignore} className="p-button-outlined" label={t('Ignore_for_now')} onClick={ignoreForNow} />
      <Button id={elementIDs.dialogCancel} className="p-button-outlined" label={t('Cancel')} onClick={onClose} />
    </div>
  );

  return (
    <Dialog header={t('Warning')} visible={showMissingPatientDataDialog} style={{ maxWidth: '500px' }} footer={actionButtons} onHide={onClose}>
      <p className="text-lg">
        {`${t('Please_provide_next_information_first')}: `}
        {[
          (!patient_details.PHN?.trim() || patient_details.PHN === 'N/A') && 'PHN',
          !patient_details.AddressLine?.trim() && 'Address',
          !patient_details.City?.trim() && 'City'
        ]
          .filter(Boolean)
          .join(', ')}
      </p>
    </Dialog>
  );
};

export default MissingPatientData;
