import React from 'react';
import { Paginator } from 'primereact/paginator';
import { InputSwitch } from 'primereact/inputswitch';

/**
 * Renders a table paginator component with different layouts based on screen size.
 * @param props - The props for the Paginator component.
 * @returns The rendered TablePaginator component.
 */
export default function TablePaginator(props) {
  return (
    <div className="flex justify-content-between align-items-center">
      <div></div>

      {/* Paginator for small screens */}
      <Paginator
        {...props}
        className="border-none p-0 flex sm:flex md:hidden lg:hidden xl:hidden"
        pageLinkSize={2}
        template={{ layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink' }}
      />

      {/* Paginator for medium screens */}
      <Paginator
        {...props}
        className="border-none p-0 hidden sm:hidden md:flex lg:hidden xl:hidden"
        pageLinkSize={3}
        currentPageReportTemplate={`{first} to {last} of ${props.totalRecords?.toLocaleString()}`}
        template={{ layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown' }}
      />

      {/* Paginator for large screens */}
      <Paginator
        {...props}
        className="border-none p-0 hidden sm:hidden md:hidden lg:flex xl:flex"
        pageLinkSize={5}
        currentPageReportTemplate={`{first} to {last} of ${props.totalRecords?.toLocaleString()}`}
        template={{ layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown' }}
      />

      {props.onPrivacyChange ? (
        <div className="flex align-items-center gap-3">
          <label htmlFor="privacy">Privacy</label>
          <InputSwitch inputId="privacy" checked={props.privacyChecked} onChange={(e) => props.onPrivacyChange(e.value)} />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
