const C02Record = [
  {
    field_id: 'P00',
    field_name: 'REC-CODE-IN',
    field_size: 3,
    field_offset: 0,
    discription: ''
  },
  {
    field_id: 'P02',
    field_name: 'DATA-CENTRE-NUM',
    field_size: 5,
    field_offset: 1,
    discription: ' '
  },
  {
    field_id: 'P04',
    field_name: 'DATA-CENTRE-SEQNUM',
    field_size: 7,
    field_offset: 2,
    discription: '0'
  },
  {
    field_id: 'P06',
    field_name: 'PAYEE-NUM',
    field_size: 5,
    field_offset: 3,
    discription: ' '
  },
  {
    field_id: 'P08',
    field_name: 'PRACTITIONER-NUM',
    field_size: 5,
    field_offset: 4,
    discription: ' '
  },
  {
    field_id: 'P14',
    field_name: 'MSP-REGISTRATION: MSP PHN-Correctional ID',
    field_size: 10,
    field_offset: 5,
    discription: '0'
  },
  {
    field_id: 'P16',
    field_name: 'NAME-VERIFY',
    field_size: 4,
    field_offset: 6,
    discription: ' '
  },
  {
    field_id: 'P18',
    field_name: 'DEPENDENT-NUM',
    field_size: 2,
    field_offset: 7,
    discription: '0'
  },
  {
    field_id: 'P20',
    field_name: 'BILLED-SRV-UNITS',
    field_size: 3,
    field_offset: 8,
    discription: '0'
  },
  {
    field_id: 'P22',
    field_name: 'SERVICE CLARIFICATION CODE(SCC)',
    field_size: 2,
    field_offset: 9,
    discription: '0'
  },
  {
    field_id: 'P23',
    field_name: 'MSP SERVICE ANATOMICAL AREA',
    field_size: 2,
    field_offset: 10,
    discription: '0'
  },
  {
    field_id: 'P24',
    field_name: 'AFTER HOURS SERVICE INDICATOR',
    field_size: 1,
    field_offset: 11,
    discription: '0'
  },
  {
    field_id: 'P25',
    field_name: 'NEW PROGRAM INDICATOR',
    field_size: 2,
    field_offset: 12,
    discription: '0'
  },
  {
    field_id: 'P26',
    field_name: 'BILLED-FEE-ITEM',
    field_size: 5,
    field_offset: 13,
    discription: ' '
  },
  {
    field_id: 'P27',
    field_name: 'BILLED-AMOUNT',
    field_size: 7,
    field_offset: 14,
    discription: '0'
  },
  {
    field_id: 'P28',
    field_name: 'PAYMENT-MODE',
    field_size: 1,
    field_offset: 15,
    discription: '0'
  },
  {
    field_id: 'P30',
    field_name: 'SERVICE-DATE',
    field_size: 8,
    field_offset: 16,
    discription: '0'
  },
  {
    field_id: 'P32',
    field_name: 'SERVICE-TO-DAY',
    field_size: 2,
    field_offset: 17,
    discription: '0'
  },
  {
    field_id: 'P34',
    field_name: 'SUBMISSION-CODE',
    field_size: 1,
    field_offset: 18,
    discription: '0'
  },
  {
    field_id: 'P35',
    field_name: 'EXTENDED SUBMISSION CODE',
    field_size: 1,
    field_offset: 19,
    discription: ' '
  },
  {
    field_id: 'P36',
    field_name: 'DIAGNOSIS CODE 1',
    field_size: 5,
    field_offset: 20,
    discription: ' '
  },
  {
    field_id: 'P37',
    field_name: 'DIAGNOSIS CODE 2',
    field_size: 5,
    field_offset: 21,
    discription: ' '
  },
  {
    field_id: 'P38',
    field_name: 'DIAGNOSIS CODE 3',
    field_size: 5,
    field_offset: 22,
    discription: ' '
  },
  {
    field_id: 'P39',
    field_name: 'DIAGNOSTIC EXPANSION',
    field_size: 15,
    field_offset: 23,
    discription: ' '
  },
  {
    field_id: 'P40',
    field_name: 'SERVICE-LOCATION-CD',
    field_size: 1,
    field_offset: 24,
    discription: ' '
  },
  {
    field_id: 'P41',
    field_name: 'REF-PRACT-1-CD',
    field_size: 1,
    field_offset: 25,
    discription: '0'
  },
  {
    field_id: 'P42',
    field_name: 'REF-PRACT-1',
    field_size: 5,
    field_offset: 26,
    discription: '0'
  },
  {
    field_id: 'P44',
    field_name: 'REF-PRACT-2-CD',
    field_size: 1,
    field_offset: 27,
    discription: '0'
  },
  {
    field_id: 'P46',
    field_name: 'REF-PRACT-2',
    field_size: 5,
    field_offset: 28,
    discription: '0'
  },
  {
    field_id: 'P47',
    field_name: 'TIME-CALL-RECVD-SRV',
    field_size: 4,
    field_offset: 29,
    discription: '0'
  },
  {
    field_id: 'P48',
    field_name: 'SERVICE-TIME-START',
    field_size: 4,
    field_offset: 30,
    discription: '0'
  },
  {
    field_id: 'P50',
    field_name: 'SERVICE-TIME-FINISH',
    field_size: 4,
    field_offset: 31,
    discription: '0'
  },
  {
    field_id: 'P52',
    field_name: 'BIRTH-DATE',
    field_size: 8,
    field_offset: 32,
    discription: '0'
  },
  {
    field_id: 'P54',
    field_name: 'OFFICE-FOLIO-NUMBER',
    field_size: 7,
    field_offset: 33,
    discription: '0'
  },
  {
    field_id: 'P56',
    field_name: 'CORRESPONDENCE-CODE',
    field_size: 1,
    field_offset: 34,
    discription: '0'
  },
  {
    field_id: 'P58',
    field_name: 'CLAIM-SHORT-COMMENT',
    field_size: 20,
    field_offset: 35,
    discription: ' '
  },
  {
    field_id: 'P60',
    field_name: 'MVA-CLAIM-CODE',
    field_size: 1,
    field_offset: 36,
    discription: ' '
  },
  {
    field_id: 'P62',
    field_name: 'ICBC-CLAIM-NUM',
    field_size: 8,
    field_offset: 37,
    discription: '0'
  },
  {
    field_id: 'P64',
    field_name: 'ORIGINAL-MSP-FILE-NUM (DCN/DCS/DRM)',
    field_size: 20,
    field_offset: 38,
    discription: '0'
  },
  {
    field_id: 'P70',
    field_name: 'FACILITY-NUM',
    field_size: 5,
    field_offset: 39,
    discription: '0'
  },
  {
    field_id: 'P72',
    field_name: 'FACILITY-SUB-NUM',
    field_size: 5,
    field_offset: 40,
    discription: '0'
  },
  {
    field_id: 'P80',
    field_name: 'FILLER-CLAIM-C02-RCD',
    field_size: 58,
    field_offset: 41,
    discription: ' '
  },
  {
    field_id: 'P100',
    field_name: 'OIN-INSURER-CODE',
    field_size: 2,
    field_offset: 42,
    discription: ' '
  },
  {
    field_id: 'P102',
    field_name: 'OIN-REGISTRATION-NUM',
    field_size: 12,
    field_offset: 43,
    discription: '0'
  },
  {
    field_id: 'P104',
    field_name: 'OIN-BIRTHDATE',
    field_size: 8,
    field_offset: 44,
    discription: '0'
  },
  {
    field_id: 'P106',
    field_name: 'OIN-FIRST-NAME',
    field_size: 12,
    field_offset: 45,
    discription: ' '
  },
  {
    field_id: 'P108',
    field_name: 'OIN-SECOND-NAME-INITIAL',
    field_size: 1,
    field_offset: 46,
    discription: ' '
  },
  {
    field_id: 'P110',
    field_name: 'OIN-SURNAME',
    field_size: 18,
    field_offset: 47,
    discription: ' '
  },
  {
    field_id: 'P112',
    field_name: 'OIN-SEX-CODE',
    field_size: 1,
    field_offset: 48,
    discription: ' '
  },
  {
    field_id: 'P114',
    field_name: 'OIN-ADDRESS-1 or WSBC Date of Injury (8)',
    field_size: 25,
    field_offset: 49,
    discription: ' '
  },
  {
    field_id: 'P116',
    field_name: 'OIN-ADDRESS-2 or WSBC Area of Injury (5) & Anatomical-Position (2)',
    field_size: 25,
    field_offset: 50,
    discription: ' '
  },
  {
    field_id: 'P118',
    field_name: 'OIN-ADDRESS-3 or WSBC Nature of Injury (5)',
    field_size: 25,
    field_offset: 51,
    discription: ' '
  },
  {
    field_id: 'P120',
    field_name: 'OIN-ADDRESS-4 or WSBC Claim Number (8)',
    field_size: 25,
    field_offset: 52,
    discription: ' '
  },
  {
    field_id: 'P122',
    field_name: 'OIN-POSTAL-CODE',
    field_size: 6,
    field_offset: 53,
    discription: ' '
  }
];

export { C02Record };
