import React from 'react';
import { useFormContext } from 'react-hook-form';
import TableHeader from './templates/TableHeader';
import RecordView from './mobileTemplates/RecordView';
import RecordsActionButtons from '../ActionButtons/RecordsActionButtons';
import TotalView from './mobileTemplates/TotalView';

const MobileRecordsView = () => {
  const { localState } = useFormContext();
  const records = localState.records;

  return (
    <div className="flex flex-column align-items-center gap-3 p-3">
      <div className="w-full">
        <div className="pb-3 px-4">
          <TableHeader cardTitileProps={{ className: 'text-base' }} className="flex flex-column md:flex-row gap-1 md:gap-3" />
        </div>

        <div
          style={{
            display: ' grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: '1rem',
            justifyContent: 'center'
          }}
        >
          {records.map((record, index) => (
            <RecordView rowData={record} rowIndex={index} />
          ))}
        </div>
        <TotalView />

        <div className="w-full px-2 pt-5 pb-4 bg-white">
          <RecordsActionButtons />
        </div>
      </div>
    </div>
  );
};

export default MobileRecordsView;
