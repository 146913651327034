import React from 'react';
import { SplitButton } from 'primereact/splitbutton';
import {
    handleBillSameTeleplanPatient,
    handleBillSameTeleplanPatientPOS,
    handleBillSameTeleplanPatientGroup,
    handleBillSamePrivatePatient,
    handleBillSameEClaim,
    handleWSBCReportWithSamePatient,
} from './billSamePatientHelpers';

const BillSamePatientSplitButton = ({ history, patientsList, user, showEClaimButton }) => {
    const menuItems = [
        {
            label: 'Picker',
            command: handleBillSameTeleplanPatientPOS(history, patientsList),
        },
        {
            label: 'Teleplan',
            command: handleBillSameTeleplanPatient(history, patientsList),
        },
        {
            label: 'Batch',
            command: handleBillSameTeleplanPatientGroup(history, patientsList),
        },
        {
            label: 'Private Invoice',
            command: handleBillSamePrivatePatient(history, patientsList),
        },
        user?.canBillWCB && {
            label: 'WSBC Report',
            command: handleWSBCReportWithSamePatient(history, patientsList),
        },
        showEClaimButton && {
            label: 'eClaims',
            command: handleBillSameEClaim(history, patientsList),
        },
    ].filter(Boolean);

    return (
        <SplitButton
            label="New Claim"
            // className='custom-outlined-button'
            // outlined
            icon='pi pi-plus'
            model={menuItems}
            onClick={handleBillSameTeleplanPatientPOS(history, patientsList)} // Default action
            tooltip={'Bill Same Patient'} // Tooltip text
            tooltipOptions={{ position: 'top' }} // Tooltip position

        />
    );
};

export default BillSamePatientSplitButton;
