import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { colors } from '../../../../../../config/stylesConfig';
import { get } from 'lodash';
import cx from 'classnames';

const RowActionButtons = ({ rowOptions }) => {
  const { rowData, rowIndex } = rowOptions;
  const { localState } = useFormContext();
  const { handleDeleteRecord, handleDuplicateRecord } = useUpdateRecords();
  const id = rowData.id?.replaceAll('-', '');

  const isDuplicate = get(localState.recordsRequiredInputs, rowData.id, {})?.isDuplicate;

  return (
    <div className="flex justify-content-end align-items-center" style={{ columnGap: '0.5rem' }}>
      {isDuplicate && (
        <>
          <Tooltip target={`.duplicate_record_warn_tooltip_${id}`} position="top" style={{ maxWidth: '250px' }}>
            <div id={`duplicate_record_warn_tooltip`}>{t('To_demonstrate_that_the_second_claim_with_the_same_fee_code_is_eligible')}</div>
          </Tooltip>
          <div id={`duplicate_record_warn`} className={cx('p-1 mt-1', `duplicate_record_warn_tooltip_${id}`)}>
            <i className="pi pi-exclamation-triangle text-lg" style={{ paddingTop: '3px', color: colors.warning }} />
          </div>
        </>
      )}

      {rowData.alert && (
        <>
          <Tooltip target={`.record_alert_tooltip_${id}`} position="top" style={{ maxWidth: '250px' }} />
          <div className={cx('p-1 mt-1', `record_alert_tooltip_${id}`)} data-pr-tooltip={rowData.alert}>
            <i className="pi pi-exclamation-triangle text-lg" style={{ paddingTop: '3px', color: colors.danger }} />
          </div>
        </>
      )}

      {rowData.warning && (
        <>
          <Tooltip target={`.record_warn_tooltip_${id}`} position="top" style={{ maxWidth: '250px' }} />
          <div className={cx('p-1 mt-1', `record_warn_tooltip_${id}`)} data-pr-tooltip={rowData.warning}>
            <i className="pi pi-exclamation-triangle text-lg" style={{ paddingTop: '3px', color: colors.warning }} />
          </div>
        </>
      )}

      {!isDuplicate && (
        <Button
          id={elementIDs.duplicate}
          className="p-button-rounded p-button-text"
          icon="pi pi-copy"
          tooltip="Duplicate Record"
          tooltipOptions={{ position: 'top', showDelay: 750 }}
          onClick={() => handleDuplicateRecord(rowData, rowIndex)}
        />
      )}

      <Button
        id={elementIDs.delete}
        className="p-button-rounded p-button-text"
        icon="pi pi-trash"
        tooltip="Delete Record"
        tooltipOptions={{ position: 'top', showDelay: 750 }}
        onClick={() => handleDeleteRecord(rowData)}
      />
    </div>
  );
};

export default RowActionButtons;
