import React from 'react';
import { Tooltip } from 'primereact/tooltip';

const NumbersColumnHeader = () => {
  return (
    <div className="flex gap-1">
      <span>No.</span>
      <i className="pi pi-question-circle numbersColumnQuestionIcon" />
      <Tooltip className="w-15rem" target=".numbersColumnQuestionIcon" position="top">
        Click on a row number to view a dropdown of available positions. Select a number to move the row to the chosen position.
      </Tooltip>
    </div>
  );
};

export default NumbersColumnHeader;
