import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../Inputs';
import { InputText } from 'primereact/inputtext';

import { validateCanadianZip } from '../../../../modules/regex/regex';
import { formatZipCode } from '../../../../modules/utils/formatZipCode';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const ZipCode = () => {
  const name = 'Zip';
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ pattern: { value: validateCanadianZip, message: t('Invalid_entry') } }}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Zip')} errorMessage={errors[name]?.message}>
          <InputText
            className={cx({ 'p-invalid': errors[name]?.message })}
            value={field.value}
            onChange={(e) => {
              const formattedValue = formatZipCode(e.target.value);
              setValue(name, formattedValue);
            }}
          />
        </InputWrapper>
      )}
    />
  );
};

export default ZipCode;
