import React, { useEffect, useRef } from 'react';
import { InputMask } from 'primereact/inputmask';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import cx from 'classnames';

const EndTimeEditor = ({ options, onBlur, inputClassName }) => {
  const { onEndTimeEditorChange, handleTimeBlur } = useUpdateRecords();
  const inputRef = useRef(null);

  useEffect(() => {
    // Autofocus on the input field when the component mounts
    inputRef.current?.focus();
  }, []);

  return (
    <div className="relative">
      <InputMask
        ref={inputRef}
        className={cx('w-5rem', inputClassName)}
        mask="99:99"
        placeholder="HH:MM"
        value={options.value}
        onChange={(e) => onEndTimeEditorChange(e.target.value, options)}
        onBlur={(e) => {
          handleTimeBlur(e.target.value, options);
          onBlur?.();
        }}
      />
    </div>
  );
};

export default EndTimeEditor;
