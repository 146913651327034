import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';

import { localStorageKeys } from '../../../../../../../../config/localStorageKeysConfig';
import { t } from '../../../../../../../../../service/localization/i18n';

const DragDropReorder = () => {
  const { localState, setLocalState } = useFormContext();

  const onChange = (e) => {
    setLocalState((prevState) => ({ ...prevState, isDragDropReorder: e.value }));
    localStorage.setItem(localStorageKeys.batchDragDropReorder, JSON.stringify(e.value));
  };

  return (
    <div className="flex align-items-center justify-content-between">
      <label htmlFor="dragDropReorder">
        {t('Drag_drop_reorder')}
        <Tooltip target=".dragDropReorderTooltip" position="top">
          {t('Switch_on_to_show_drag_drop_reorder')}
        </Tooltip>
        <i className="pi pi-question-circle dragDropReorderTooltip ml-3" />
      </label>
      <InputSwitch inputId="dragDropReorder" checked={localState.isDragDropReorder} onChange={onChange} />
    </div>
  );
};

export default DragDropReorder;
