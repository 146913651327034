import React from 'react';
import { useFormContext } from 'react-hook-form';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { calculateGroupTotal } from '../../../helpers/calculateGroupTotal';
import { t } from '../../../../../../../service/localization/i18n';

const TotalView = () => {
  const { localState } = useFormContext();
  const records = localState.records;

  return (
    <div className="text-right md:text-left text-xl font-bold w-full pt-2 pr-3">{`${t('Total')}: ${currencyFormat(
      calculateGroupTotal(records)
    )}`}</div>
  );
};

export default TotalView;
