import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { toTitleCase } from '../../../../modules/utils/toTitleCase';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const City = () => {
  const name = 'City';
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('City')} errorMessage={errors[name]?.message}>
          <InputText
            value={field.value}
            className={cx({ 'p-invalid': errors[name]?.message })}
            onChange={(e) => {
              setValue(name, toTitleCase(e.target.value), { shouldDirty: true });
            }}
          />
        </InputWrapper>
      )}
    />
  );
};

export default City;
